<template>
    <h2>League List View</h2>
    <div v-if="this.leagueList">
        <div v-for="league in leagueList" :key="league">
            <router-link :to="'/football/league/' + league.slug">
                <p>{{ league.name }}</p>
            </router-link>
            <p>{{ league.country }}</p>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            leagueList: [],
        };
    },
    mounted() {
        this.getLeagues();
    },
    computed: {
        ...mapGetters('football', {
            getterLeagueData: 'getLeagueData',
        }),
    },
    methods: {
        ...mapActions('football', {
            actionGetLeaguesApi: 'getLeagues',
        }),
        async getLeagues() {
            try {
                const response = await this.actionGetLeaguesApi();
                this.leagueList = this.getterLeagueData;
                console.log(this.leagueList);
            } catch (error) {
                console.log(error);
            }
        }
    },
}
</script>
```
