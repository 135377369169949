import DashboardView from './views/DashboardView.vue';

const routes = [
    {
        path: '/wallet/dashboard',
        component: DashboardView,
        name: 'Dashboard',
        meta: {
            requiresAuth: true,
        },
    },
];

export default routes;