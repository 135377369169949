<template>
    <div v-if="auctionMessage" class="text-center alert alert-dismissible fade show alert-info" role="alert">
        {{ auctionMessage }}
        <button type="button" @click="closeMessage" class="close-btn" data-bs-dismiss="alert" aria-label="Close">
            <i class="fas fa-times"></i>
        </button>
    </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useAuctionStore } from '@/stores/auction';

export default {
    setup() {
        const auctionStore = useAuctionStore();
        const auctionMessage = computed(() => auctionStore.getAuctionMessage)

        const closeMessage = () => {
            auctionStore.clearAuctionMessage();
        };
        return {
            auctionStore,
            closeMessage,
            auctionMessage,
        };
    }
}
</script>