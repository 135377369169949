<template>
    <transition name="fade" mode="out-in">
        <div v-if="loading" class="spinner-container">
            <img class="logo-spin logo-pulse" src="../assets/djolowin/djolo-win-website-favicon-color.png"
                alt="Company Logo" />
        </div>
    </transition>
</template>

<style scoped>
.spinner-container {
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 500;
    opacity: 1;
    transition: opacity 0.3s ease;
}

/* Define the fade transition classes */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0.2;
}

/* Define the spinning and pulsing animation for the company logo */
.logo-pulse {
    animation: spinPulse 2s infinite;
    height: 100px;
    width: 100px;
}

@keyframes spinPulse {
    0% {
        transform: scale(0.8) rotate(0deg);
    }

    50% {
        transform: scale(1) rotate(180deg);
    }

    100% {
        transform: scale(0.8) rotate(360deg);
    }
}
</style>


<script>
export default {
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
    },
};
</script>