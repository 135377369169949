<template>
  <div class="modal" id="saleCardDetailModal" tabindex="-1"
    aria-labelledby="saleCardDetailModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <FullPageSpinner v-if="loading" :loading="loading" />
        <div v-else>
          <div class="card-modal-bg" v-if="saleCard">
            <div class="modal-header">
              <h5 class="modal-title" id="saleCardDetailModalLabel">
                <div class="card-badge">
                  <span class="card-season">
                    {{ saleCard.card.season }}
                  </span>
                  <span :class="badgeClass" class="r-badge">
                    {{ saleCard.rarity_name }}
                  </span>
                  <span class="card-number">
                    {{ saleCard.card.index }} / {{ saleCard.card.total_index }}
                  </span>
                </div>
                <router-link class="text-decoration-none" @click="closeAndSwitch" type="button"
                  :to="'/football/player/' + saleCard.card.player_slug">
                  <div class="card-name">
                    {{ saleCard.player_name }}
                  </div>
                </router-link>
                <div class="position-name">
                  {{ saleCard.card.position_name }}
                </div>
              </h5>
              <button @click="clearSaleData" type="button" class="close-btn" data-bs-dismiss="modal" aria-label="Close">
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body">
              <FlashMessage />
              <div class="sale-details-container">
                <div class="card-detail-el mx-auto">
                  <SaleCardImage :saleCard="saleCard" />
                  <p v-if="saleCard.seller_name" class="seller-name">
                    Sold by: {{ saleCard.seller_name }}
                  </p>
                  <p v-else class="seller-djolowin">
                    Sold by: DjoloWin
                  </p>
                </div>
                <!-- Collapsible Buy Button -->
                <div v-if="!belongsToUser" class="confirm-ctn">
                  <div class="confirm-message">
                    <div class="sale-price">
                      {{ formatNumber(saleCard.price) }} <i class="fa fa-ils"></i>
                    </div>
                  </div>
                  <div class="d-flex">
                    <span class="warning-msg">
                      (This action cannot be undone. Please make sure this is the card you want to buy!)
                    </span>
                  </div>
                  <div class="d-flex justify-content-center mt-2">
                    <Transition name="collapse-transition">
                      <button class="confirm-btn" @click="purchaseCard">
                        Purchase Card
                      </button>
                    </Transition>
                  </div>
                </div>
                <div v-else>
                  <div class="confirm-message">
                    <div class="sale-price">
                      {{ formatNumber(saleCard.price) }} <i class="fa fa-ils"></i>
                    </div>
                  </div>
                </div>
              </div>
              <section class="detail-section">
                <ul class="list-group d-flex justify-content-center mb-1">
                  <li class="list-group-item card-detail-bg">
                    <p class="regular small text-white-50 m-0">Owner
                      <span class="text-white small regular float-right">{{ saleCard.seller_name
                        ?
                        saleCard.seller_name
                        : "DjoloWin"
                      }}</span>
                    </p>
                  </li>
                  <li class="list-group-item card-detail-bg">
                    <p class="regular small text-white-50 m-0">Team
                      <span class="text-white small regular float-right">{{ saleCard.card.team_name
                      }}</span>
                    </p>
                  </li>
                  <li class="list-group-item card-detail-bg">
                    <p class="regular small text-white-50 m-0">Position
                      <span class="text-white small regular float-right">{{
                        saleCard.card.position_name
                      }}</span>
                    </p>
                  </li>
                  <li class="list-group-item card-detail-bg">
                    <p class="regular small text-white-50 m-0">Nationality
                      <span class="text-white small regular float-right">{{ saleCard.card.nationality
                      }}</span>
                    </p>
                  </li>
                  <li class="list-group-item card-detail-bg">
                    <p class="regular small text-white-50 m-0">Status
                      <span v-if="saleCard.card.for_sale" class="text-warning small regular float-right">Currently on
                        Sale</span>
                      <span v-else-if="saleCard.card.active_auction_id" class="text-warning small regular float-right">In
                        Auction</span>
                      <span v-else-if="saleCard.card.is_locked"
                        class="text-danger small regular float-right">Locked</span>
                      <span v-else class="text-white small regular float-right">Not for
                        Sale</span>
                    </p>
                  </li>
                </ul>
              </section>
            </div>
            <div class="modal-footer">
              <button type="button" class="close-btn-lg" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import '../../../assets/css/card.css';

.card-detail-bg {
  background-color: #131313;
  border: none;
}

.modal-footer {
  margin: 0;
  padding: 0 10px;
  border: none;
}

@media screen and (max-width: 768px) {
  .card-el {
    width: 70%;
  }
}

.detail-section {
  margin-top: 1rem;
}

.seller-name {
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
}

.seller-djolowin {
  font-size: 1rem;
  font-weight: bold;
  color: #29c78a;
}

.sale-details-container {
  display: flex;
  flex-direction: column;
}

.modal-header {
  border-bottom: none;
  padding: 0 10px;
  margin: 0
}


.confirm-message {
  margin-bottom: 0.2rem;
  text-align: center;
}
</style>


<script>
import SaleCardImage from './SaleCardImage.vue';
import FullPageSpinner from '@/components/FullPageSpinner.vue';
import FlashMessage from '@/components/FlashMessage.vue';

import formatNumber from '@/utils/numberFormatter';

import { useCardStore } from '@/stores/card';
import { useFootballStore } from '@/stores/football';
import { useAccountStore } from '@/stores/account';
import { ref, defineComponent, computed, watch } from 'vue';

export default defineComponent({
  name: 'CardSaleDetail',
  props: {
    saleCard: {
      type: Object,
      required: false,
    },
    origin: {
      type: String,
      required: false,
    },
  },
  emits: ['loading-updated'],
  components: {
    FullPageSpinner,
    FlashMessage,
    SaleCardImage
  },
  computed: {
    badgeClass() {
      return {
        'normal-badge': this.saleCard.rarity_name === 'Normal',
        'rare-badge': this.saleCard.rarity_name === 'Rare',
        'limited-badge': this.saleCard.rarity_name === 'Limited',
        'super-rare-badge': this.saleCard.rarity_name === 'Super Rare',
        'unique-badge': this.saleCard.rarity_name === 'Unique',
      };
    },
  },
  setup(props, { emit }) {
    // Stores
    const accountStore = useAccountStore();
    const cardStore = useCardStore();
    const footballStore = useFootballStore();
    // Reactive variables
    const loading = ref(false);

    // Computed properties
    const userId = computed(() => {
      return accountStore.authData.userId;
    });
    const belongsToUser = computed(() => {
      return userId.value === props.saleCard.seller;
    });
    const saleCard = computed(() => {
      return props.saleCard;
    });
    const handleBodyClick = () => {
      if (isPurchaseCollapseOpen) {
        isPurchaseCollapseOpen.value = false;
      }
    };

    watch(loading, (newValue) => {
      emit('loading-updated', newValue);
    });

    // Methods
    async function purchaseCard() {
      const saleId = saleCard.value.id;
      try {
        loading.value = true;
        const myModal = document.getElementById('saleCardDetailModal');
        myModal.setAttribute('aria-hidden', 'true');
        const closeButton = myModal.querySelector('.close-btn');
        if (closeButton) {
          closeButton.click();
        }
        const response = await cardStore.purchaseCard(saleId);
        if (response.status === 200) {
          console.log(response.data.message);
          accountStore.recordSuccessMessages(response.data.message);
          if (props.origin === 'player') {
            footballStore.removeSaleFromList(saleId);
          }
          loading.value = false;
        }
      }
      catch (error) {
        console.log(error);
        accountStore.recordErrorMessages(error.data.message);
        loading.value = false;
      }
    }
    function stopPropagation(event) {
      event.stopPropagation();
    }

    function clearSaleData() {
      cardStore.clearSingleSaleData();
    }

    function closeAndSwitch() {
      clearSaleData();
      const myModal = document.getElementById('saleCardDetailModal');
      myModal.setAttribute('aria-hidden', 'true');
      const closeButton = myModal.querySelector('.close-btn');
      if (closeButton) {
        closeButton.click();
      }
    }


    return {
      belongsToUser,
      clearSaleData,
      closeAndSwitch,
      purchaseCard,
      stopPropagation,
      formatNumber,
      handleBodyClick,
      loading,
      saleCard,
    };
  },
});
</script>