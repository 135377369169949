<template>
    <HelpBox />
    <div class="small-title">
        <h4>
            Ventes aux enchères
            <span class="sm-span" v-if="totalAuctions">({{ totalAuctions }} au total)</span>
        </h4>
    </div>
    <input class="curved-input-dark" v-model="nameFilter" placeholder="Filtrer par joueur" />
    <transition name="fade">
        <FiltersLine />
    </transition>
    <FlashMessage />
    <AuctionListContainer :auctionList="auctionListData" :loading="loading" :hiddenLoading="hiddenLoading" />
    <GoToTop />
</template>




<script setup>
import { onMounted, onBeforeUnmount, ref, computed, watch } from 'vue';
import { useAuctionStore } from '@/stores/auction';
import { useFilterStore } from '@/stores/filters';
import { useWebSocketAuctions } from '@/services/allAuctionsWebsockets';

import FiltersLine from '@/modules/filters/components/FiltersLine.vue';
import FlashMessage from '../../../components/FlashMessage.vue';
import AuctionListContainer from '../components/AuctionListContainer.vue';
import GoToTop from '@/components/GoToTop.vue';
import HelpBox from '@/components/HelpBox.vue';

import axios from 'axios';



const auctionStore = useAuctionStore();
const filterStore = useFilterStore();
const allAuctionsWebsocket = useWebSocketAuctions();

const nameFilter = ref('');
const loading = ref(false);
const hiddenLoading = ref(false);

const selectedPlayerName = computed(() => {
    return filterStore.getSelectedName;
});
const selectedPositions = computed(() => {
    return filterStore.getPositions;
});
const rarities = computed(() => {
    return filterStore.getRarities;
});

const debounceGetAuctions = _.debounce(getAuctions, 1200);

const auctionListData = computed(() => {
    return auctionStore.getAuctionList;
});
const totalAuctions = computed(() => {
    return auctionStore.getTotalCount;
});
const nextPageLink = computed(() => {
    return auctionStore.getNextPage;
});



async function getAuctions() {
    loading.value = true;
    console.log(selectedPlayerName.value);
    try {
        let response;

        if (selectedPlayerName.value !== null && rarities.value.length > 0 && selectedPositions.value.length > 0) {
            response = await auctionStore.getAllAuctions({
                rarity: rarities.value, player_name: selectedPlayerName.value, position: selectedPositions.value
            });
        }
        else if (selectedPlayerName.value !== null && rarities.value.length > 0) {
            response = await auctionStore.getAllAuctions({
                rarity: rarities.value, player_name: selectedPlayerName.value
            });
        }
        else if (selectedPlayerName.value !== null && selectedPositions.value.length > 0) {
            response = await auctionStore.getAllAuctions({
                player_name: selectedPlayerName.value, position: selectedPositions.value
            });
        }
        else if (rarities.value.length > 0 && selectedPositions.value.length > 0) {
            response = await auctionStore.getAllAuctions({
                rarity: rarities.value, position: selectedPositions.value
            });
        }
        else if (selectedPlayerName.value !== null) {
            response = await auctionStore.getAllAuctions({ player_name: selectedPlayerName.value });
        }
        else if (rarities.value.length > 0) {
            response = await auctionStore.getAllAuctions({ rarity: rarities.value });
        }
        else if (selectedPositions.value.length > 0) {
            response = await auctionStore.getAllAuctions({ position: selectedPositions.value });
        }
        else {
            response = await auctionStore.getAllAuctions();
        }
    } catch (error) {
        console.log(error);
    } finally {
        loading.value = false;
    }
}

async function loadMoreAuctions() {
    if (!hiddenLoading.value && auctionStore.nextPage) {
        try {
            hiddenLoading.value = true;
            const response = await axios.get(auctionStore.nextPage);
            auctionStore.addAuctionsToList(response.data.results);
            if (response.data.next === null) {
                auctionStore.clearNextPage();
            }
            auctionStore.setNextPage(response.data.next);
        }
        catch (error) {
            console.log(error);
        } finally {
            hiddenLoading.value = false;
        }
    }
}

function handleScroll() {
    const container = document.documentElement; // Use document.documentElement to reference the entire page

    const scrollPosition = window.scrollY + window.innerHeight;
    const totalHeight = container.scrollHeight;

    // Check if the user has reached the bottom (adjust the threshold as needed)
    if (scrollPosition >= totalHeight * 0.9) {
        loadMoreAuctions();
    }
}



watch([selectedPlayerName, rarities, selectedPositions], () => {
    debounceGetAuctions();
});

watch(nameFilter, () => {
    filterStore.setSelectedName(nameFilter.value);
    if (nameFilter.value === '') {
        filterStore.setSelectedName(null);
    }
});
watch(selectedPlayerName, () => {
    if (selectedPlayerName.value === null) {
        nameFilter.value = '';
    }
});

onBeforeUnmount(() => {
    allAuctionsWebsocket.closeWebSocketConnection();
    window.removeEventListener('scroll', handleScroll);
});

onMounted(() => {
    filterStore.clearFilters();
    window.addEventListener('scroll', handleScroll);
});
</script>

