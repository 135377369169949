import { computed } from 'vue';

function formatRelativeTime (timestamp) {
  const currentDate = new Date ();
  const notificationDate = new Date (timestamp);

  const timeDifferenceInSeconds = Math.floor (
    (currentDate - notificationDate) / 1000
  );

  if (timeDifferenceInSeconds < 60) {
    return `Il y a ${timeDifferenceInSeconds} secondes`;
  } else if (timeDifferenceInSeconds < 3600) {
    const minutes = Math.floor (timeDifferenceInSeconds / 60);
    return `Il y a ${minutes} minutes`;
  } else if (timeDifferenceInSeconds < 86400) {
    const hours = Math.floor (timeDifferenceInSeconds / 3600);
    return `Il y a ${hours} heures`;
  } else {
    const days = Math.floor (timeDifferenceInSeconds / 86400);
    return `Il y a ${days} jours`;
  }
}

function parseDatetimestamp (timestamp) {
  if (!timestamp) {
    return '';
  }
  const date = new Date (timestamp);
  return date.toLocaleDateString ('fr-FR', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
}

function updateCountdown (timestamp) {
  const now = new Date().getTime();
    const distance = new Date(timestamp).getTime() - now;
    const countdown = computed(() => {
      if (distance < 0) {
        return 'EXPIRED';
      }
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (distance % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    });
    return countdown;
}


export {formatRelativeTime, parseDatetimestamp, updateCountdown};
