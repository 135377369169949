<template>
    <FullPageSpinnerVue v-if="loading" :loading="loading" />
    <div class="modal fade" id="signupModal" tabindex="-1" aria-labelledby="signupModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <section class=" gradient-custom">
                    <div class="row d-flex">
                        <div class="login-card text-white" style="border-radius: 1rem">
                            <button type="button" class="close-btn m-3" data-bs-dismiss="modal" aria-label="Close">
                                <i class="fas fa-times"></i>
                            </button>
                            <FlashMessage />
                            <div class="card-body px-3 text-center">
                                <div class="mb-md-5 pb-2">
                                    <img src="../../../assets/djolowin/djolo-win-high-resolution-logo-white-on-black-background-removebg.png"
                                        alt="logo" class="img-fluid mb-1">
                                    <h2 class="fw my-2 mb-2">{{ $t('Sign Up Title') }}</h2>
                                    <div class="form-outline form-white mb-2">
                                        <form @submit.prevent="signup">
                                            <div class="form-outline form-white mb-4">
                                                <input type="text" class="control form-control-lg"
                                                    v-model="formData.username" placeholder="Enter your username"
                                                    required />
                                            </div>
                                            <div class="form-outline form-white mb-4">
                                                <input type="text" class="control form-control-lg" v-model="formData.email"
                                                    placeholder="Enter your email" required />
                                            </div>
                                            <div class="form-outline form-white mb-4">
                                                <input type="password" class="control form-control-lg"
                                                    v-model="formData.password1" placeholder="Enter your password"
                                                    required />
                                            </div>
                                            <div
                                                :class="{ 'validator': true, 'valid': isPasswordValidLength, 'invalid': !isPasswordValidLength }">
                                                {{ $t('Password length validation') }}
                                            </div>
                                            <div
                                                :class="{ 'validator': true, 'valid': isPasswordContainingSpecialCharacter, 'invalid': !isPasswordContainingSpecialCharacter }">
                                                {{ $t('PasswordSpecialCharacterValidation') }}
                                            </div>
                                            <div class="mb-3"
                                                :class="{ 'validator': true, 'valid': isPasswordContainingBothCases, 'invalid': !isPasswordContainingBothCases }">
                                                {{ $t('Password case validation') }}
                                            </div>
                                            <div class="form-outline form-white mb-4">
                                                <input :type="showPassword ? 'text' : 'password'"
                                                    class="control form-control-lg" v-model="formData.password2"
                                                    placeholder="Confirm your password" required />
                                                <span @click="togglePasswordVisibility" class="toggle-password-icon">
                                                    <!-- You can replace 'eye' and 'eye-slash' with your preferred icons -->
                                                    <i v-if="showPassword" class="fas fa-eye-slash"></i>
                                                    <i v-else class="fas fa-eye"></i>
                                                </span>
                                                <div
                                                    :class="{ 'validator': true, 'valid': verifyPasswordMatch, 'invalid': !verifyPasswordMatch }">
                                                    {{ $t('Password match validation') }}
                                                </div>
                                            </div>
                                            <button class="btn btn-go px-5" type="submit"
                                                :disabled="!areAllConditionsMet">
                                                {{ $t('Sign Up') }}
                                            </button>
                                        </form>
                                        <p class="mb-0 mt-3 text-white-50">{{ $t('Account?') }} <a role="button"
                                                @click="switchToLogin" class="text-white fw-bold">{{ $t('Sign In') }}</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'; // Import ref and onMounted
import { useAccountStore } from '@/stores/account';
import { useRouter } from 'vue-router'
import FullPageSpinnerVue from '@/components/FullPageSpinner.vue';
import FlashMessage from '@/components/FlashMessage.vue';


const accountStore = useAccountStore();
const router = useRouter()
const loading = ref(false);
const showPassword = ref(false);

const formData = ref({
    email: '',
    username: '',
    password1: '',
    password2: '',
});
onMounted(async () => {
    accountStore.clearErrorMessages();
});

function switchToLogin() {
    const myModal = document.getElementById('signupModal');
    myModal.setAttribute('aria-hidden', 'true');
    const closeButton = myModal.querySelector('.close-btn');
    if (closeButton) {
        closeButton.click();
    }
    const myModal2 = document.getElementById('signlog-btn');
    const closeButton2 = myModal2.querySelector('.login-btn-md');
    if (closeButton2) {
        closeButton2.click();
    }
}

function togglePasswordVisibility() {
    showPassword.value = !showPassword.value;
}

async function signup() {
    accountStore.clearErrorMessages();
    const payload = {
        email: formData.value.email,
        username: formData.value.username,
        password: formData.value.password2,
    };
    loading.value = true;
    try {
        const response = await accountStore.signup(payload);
        if (accountStore.errorMessages === null) {
            router.push({ name: 'VerifyEmailSent' });
        }
    } catch (error) {
        console.log(error);
    } finally {
        loading.value = false;
    }
};
const isPasswordValidLength = computed(() => {
    return formData.value.password1.length >= 8;
});

const isPasswordContainingBothCases = computed(() => {
    return /[a-z]/.test(formData.value.password1) && /[A-Z]/.test(formData.value.password1);
});

const isPasswordContainingSpecialCharacter = computed(() => {
    return /[\W_]/.test(formData.value.password1);
});

const verifyPasswordMatch = computed(() => {
    return formData.value.password1 === formData.value.password2;
});

const areAllConditionsMet = computed(() => {
    return (
        isPasswordValidLength.value &&
        isPasswordContainingBothCases.value &&
        isPasswordContainingSpecialCharacter.value &&
        verifyPasswordMatch.value
    );
});
</script>

<style scoped>
.validator {
    margin-top: 4px;
    font-size: 0.8em;
}

.valid {
    color: green;
    display: none;
}

.invalid {
    color: #ff8181;
}

.img-fluid {
    width: 160px;
    height: auto;
}

.form-control-lg {
    height: 1.5rem;
    padding: 1.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 5rem;
    background-color: #040404;
    border: 2px solid #303030;
    color: #fff;
}

.form-control-lg:focus {
    color: #fff;
    background-color: #080808;
    border-color: #fff;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%);
}


.btn-go {
    background-color: #9afff0c4;
    border-radius: 5rem;
    height: 3rem;
}


@media (max-width: 767px) {

    .btn-go {
        scale: 0.9;
    }

    .text-uppercase {
        font-size: 1.25rem;
    }

    .form-control-lg {
        height: 1rem;
        padding: 0.75rem 0.5rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 5rem;
    }
}
</style>