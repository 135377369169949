<template>
    <HelpBox />
    <div class="leaderboard">
        <h2 class="text-center text-white">
            Classement Global
        </h2>
        <div class="table-container">
            <div class="table-body">
                <div
                    v-for="(rank, index) in allCollectionsRanking"
                    :key="index"
                    class="table-row flex-container"
                    :class="getRowColorClass(index)"
                >
                    <div class="flex-1">{{ rank.rank }}</div>
                    <div v-if="rank.username === userName" class="flex-3 text-success">{{ rank.username }}  (Me)</div>
                    <div v-else class="flex-3">{{ rank.username }}</div>
                    <div class="flex-2 text-right">{{ rank.points }} <i class="fa-solid fa-certificate"></i></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useAccountStore } from '@/stores/account';
import { useRankingStore } from '@/stores/ranking';
import HelpBox from '@/components/HelpBox.vue';

const accountStore = useAccountStore();
const rankingStore = useRankingStore();

const loading = ref(false);
const allCollectionsRanking = computed(() => {
    return rankingStore.getAllCollectionsRanking;
});
const userName = computed(() => {
    return accountStore.authData.userName;
});
function getRowColorClass(index) {
    return index % 2 === 0 ? 'table-row-even' : 'table-row-odd';
}

onMounted(() => {
    rankingStore.pullAllCollectionsRanking();
});

</script>

<style scoped>
/* Custom styling */
.leaderboard {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.table-container {
    width: 80%; /* Adjust width as needed */
    background-color: #181818;
    border-radius: 5px;
    overflow: hidden;
}

.table-header {
    display: flex;
    background-color: #00818a;
    color: #fff;
    font-weight: bold;
}

.header-item {
    padding: 10px;
    text-align: center;
}

.table-body {
    display: flex;
    flex-direction: column;
}

.table-row {
    display: flex;
}

.flex-container {
    flex: 1;
}

.flex-1 {
    flex: 1;
    padding: 10px;
    color:azure
}

.flex-2 {
    flex: 2;
    padding: 10px;
    color:azure
}

.flex-3 {
    flex: 3;
    padding: 10px;
    color:azure

}

.text-right {
    text-align: right;
}
</style>