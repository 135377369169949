<template>
    <div v-if="teamData">
        <h1>{{ teamData.name }} ({{ teamData.year }})</h1>
        <h5>{{ teamData.country }}</h5>
        <table class="table">
            <thead>
                <tr>
                    <th>
                        <button @click="sortPlayers('jersey_number')">Jersey Number</button>
                    </th>
                    <th>
                        <button @click="sortPlayers('name')">Name</button>
                    </th>
                    <th>
                        <button @click="sortPlayers('position')">Position</button>
                    </th>
                    <th>
                        <button @click="sortPlayers('age')">Age</button>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="player in sortedPlayers" :key="player">
                    <td>{{ player.jersey_number }}</td>
                    <router-link :to="'/football/player/' + player.slug">
                        <td>{{ player.name }}</td>
                    </router-link>
                    <td>{{ player.position }}</td>
                    <td>{{ player.age }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    data() {
        return {
            teamData: null,
            sortBy: "position",
            reverseSort: true,
        };

    },
    mounted() {
        this.getPlayersListByTeam();
    },
    computed: {
        ...mapGetters('football', {
            getterTeamData: 'getTeamData',
        }),
        sortedPlayers() {
            if (this.sortBy === 'position') {
                return this.sortPlayersByPosition();
            } else {
                return this.sortPlayersByColumn(this.sortBy);
            }
        },
    },
    methods: {
        ...mapActions('football', {
            actionGetTeamDetail: 'getTeamDetail',
        }),
        async getPlayersListByTeam() {
            try {
                await this.actionGetTeamDetail(this.$route.params.slug);
                this.teamData = this.getterTeamData;
            } catch (error) {
                console.log(error);
            }
        },
        sortPlayersByPosition() {
            const order = this.reverseSort ? ['GK', 'DEF', 'MID', 'FW'] : ['FW', 'MID', 'DEF', 'GK'];
            return this.teamData.players.slice().sort((a, b) =>
                order.indexOf(a.position) - order.indexOf(b.position)
            );
        },
        sortPlayersByColumn(key) {
            return this.teamData.players.slice().sort((a, b) =>
                a[key] > b[key] ? 1 : -1
            );
        },
        toggleSortOrder() {
            this.reverseSort = !this.reverseSort;
            this.teamData.players = this.sortedPlayers;
        },
        sortPlayers(key) {
            if (this.sortBy === key) {
                this.reverseSort = !this.reverseSort; // Toggle the sorting order
                this.teamData.players = this.sortedPlayers;
            } else {
                this.sortBy = key;
                this.reverseSort = false; // Reset sorting order
                this.teamData.players = this.sortedPlayers;
            }
        },
    },
};
</script>
