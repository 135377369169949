<template>
    <FullPageSpinner v-if="loading" :loading="loading" />
    <GoBack />
    <div class="submit-ctn" v-if="latestWeeklyFeaturedTeam">
        <div class="featured-title">
            <h2 class="feat-txt bold">
                Submit Your Cards for Week {{ latestWeeklyFeaturedTeam.week }}
            </h2>
            <h3 class="feat-txt subrayada">{{ formattedCountdown }}</h3>
        </div>
        <div class="desc-ctn">
            <p>
                <span class="desc-txt">You can submit matching cards you own for the weekly featured team by clicking on the
                </span>
                <span class="desc-txt bold">"
                    <i class="fas fa-plus"></i>
                    "</span>
                <span class="desc-txt"> sign below each card. You can only submit one card per player.</span>
            </p>
            <p>
                <span class="desc-txt"> You will obtain a reward if you complete the weekly featured team.</span>
            </p>
        </div>
        <div v-if="featuredCards" class="feat-ctn">
            <div class="card-el" v-for="card in featuredCards">
                <MasterCardImage :key="card.id" :mastercard="card" parentComponent="featured" />
                <div v-if="!userSubmission.featured_team" class="user-submission" type="button" data-bs-toggle="modal"
                    data-bs-target="#userFeaturedModal" @click="openModal(card)">
                    <div class="user-submission-text">
                        <i class="fas fa-arrow-down"></i>
                    </div>
                    <div class="user-submission-img">
                        <transition name="fade" mode="out-in">
                            <div :key="matchingCard(card).length > 0 ? 'card' : 'placeholder'" class="transition-container">
                                <PlayerCardinRows class="selected" v-if="matchingCard(card).length > 0"
                                    :playercard="matchingCard(card)[0]" />
                                <div v-else class="custom-placeholder">
                                    <div class="placeholder-content">
                                        <i class="fas fa-plus"></i>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
                <div v-else class="user-submission">
                    <div class="user-submission-img">
                        <transition name="fade" mode="out-in">
                            <div :key="matchingCard(card).length > 0 ? 'card' : 'placeholder'" class="transition-container">
                                <PlayerCardinRows class="selected" v-if="matchingCard(card).length > 0"
                                    :playercard="matchingCard(card)[0]" />
                            </div>
                        </transition>
                    </div>
                </div>
                <UserFeaturedCardModal :matchingUserCards="matchingUserCards" />
            </div>
        </div>
        <div v-if="userSubmission.featured_team" class="congrats-ctn">
            <h4 class="feat-txt success bold">This is the team that you submitted for this week. Excellent work!</h4>
        </div>
        <div v-else>
            <div v-if="allCardsSelected" class="submit-btn">
                <button class="save-btn btn-block bold" type="button" @click="submitFeaturedCards">
                    Submit
                </button>
            </div>
            <div v-else class="submit-btn">
                <button class="save-btn btn-block bold disabled" type="button" disabled>
                    Submit
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped>
.card-el {
    flex: 0 0 auto;
    /* Maintain original size */
    /* Add margin between cards */
    margin: 0 10px;
    justify-content: center;
}


.user-submission-text {
    color: #fff;
    font-size: 1.5em;
    text-align: center;
}


.desc-ctn {
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #1b1b1b;
    padding: 10px;
    border-radius: 15px;
}

.desc-txt {
    font-size: 1rem;
    margin: 0;
    color: #fff;
    font-family: 'Montserrat R', monospace;
}

.submit-ctn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

.submit-btn {
    margin-top: 20px;
    width: 60%;
    text-align: center;
}

.save-btn {
    /* Green */
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
    border-radius: 20px;
}

.save-btn.disabled {
    background-color: #92929295;
    color: #ffffff5e;
}

.selected {
    filter: drop-shadow(0 0 0.5rem #40dde0);
}

.custom-placeholder {
    background-color: #696969;
    /* Dark background color */
    border-radius: 5px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-placeholder:hover {
    background-color: #2d2d2d;
    /* Dark background color */
}

.placeholder-content {
    color: #fff;
    /* Text color */
    font-size: 2em;
    /* Adjust the font size as needed */
}

@media (max-width: 991px) {
    .card-el {
        margin: 0 5px;
        flex: auto;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .feat-txt {
        font-size: 20px;
    }

    .desc-txt {
        font-size: 14px;
    }

    .feat-ctn {
        align-items: flex-start;
    }

    .submit-btn {
        width: 80%;
    }
}
</style>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useCardStore } from '@/stores/card';
import { useAccountStore } from '@/stores/account';
import { useRouter } from 'vue-router'

import GoBack from '@/components/GoBack.vue';
import FullPageSpinner from '@/components/FullPageSpinner.vue';
import UserFeaturedCardModal from '../components/UserFeaturedCardModal.vue';
import MasterCardImage from '../components/MasterCardImage.vue';
import PlayerCardinRows from '../components/PlayerCardinRows.vue';


const accountStore = useAccountStore();
const cardStore = useCardStore();
const countdownInSeconds = ref(0);
const matchingUserCards = ref([]);
const loading = ref(false);
const router = useRouter()



const userSubmission = computed(() => cardStore.getRecentUserSubmission);
const userFeaturedCards = computed(() => {
    if (userSubmission.value.submitted_cards) {
        return userSubmission.value.submitted_cards;
    } else {
        return cardStore.getUserFeaturedCards;
    }
});
const featuredCards = computed(() => cardStore.getFeaturedCards);
const latestWeeklyFeaturedTeam = computed(() => cardStore.getLatestWeeklyFeaturedTeam);

const allCardsSelected = computed(() => {
    return featuredCards.value.every((featuredCard) => {
        return userFeaturedCards.value.some((userCard) => {
            return userCard.player === featuredCard.player && userCard.rarity_name === featuredCard.rarity_name &&
                userCard.position_name === featuredCard.position_name;
        });
    });
});


const matchingCard = (featuredCard) => {
    return userFeaturedCards.value.filter((userCard) => {
        return userCard.player === featuredCard.player;
    });
};


const openModal = (featuredCard) => {
    // Implement logic to fetch matching user cards based on the criteria of the featured card
    if (!cardStore.getRecentUserSubmission) {
        return;
    }
    cardStore.setSelectedUserFeaturedCard(featuredCard);
    const userCards = cardStore.getOwnedCardsData;
    matchingUserCards.value = userCards.filter((userCard) => {
        // Add your matching criteria here based on your card model
        return (
            userCard.player === featuredCard.player &&
            userCard.rarity === featuredCard.rarity &&
            userCard.position === featuredCard.position
            // Add more criteria if needed
        );
    });

};


const updateCountdown = () => {
    const now = new Date().getTime();
    if (!latestWeeklyFeaturedTeam.value) {
        return;
    }
    const distance = new Date(latestWeeklyFeaturedTeam.value.end_date).getTime() - now;
    countdownInSeconds.value = Math.floor(distance / 1000);
    if (distance < 0) {
        countdownInSeconds.value = 0;
    }
};
const formattedCountdown = computed(() => {
    const days = Math.floor(countdownInSeconds.value / 86400);
    const hours = Math.floor((countdownInSeconds.value % 86400) / 3600);
    const minutes = Math.floor((countdownInSeconds.value % 3600) / 60);
    const seconds = countdownInSeconds.value % 60;

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
});

async function getLatestWeeklyFeaturedTeam() {
    await cardStore.pullLatestWeeklyFeaturedTeam();
}

async function submitFeaturedCards() {
    if (userFeaturedCards.value.length === 0) {
        return;
    }
    loading.value = true;
    try {
        const data = {
            featured_team_id: latestWeeklyFeaturedTeam.value.id,
            card_ids: userFeaturedCards.value.map((card) => card.id),
        }
        const response = await cardStore.submitLatestWeeklyFeaturedTeam(data);
        if (response && response.status === 201) {
            accountStore.recordSuccessMessages(response.data.message);
            await cardStore.pullLatestWeeklyFeaturedTeam();
        }
    } catch (error) {
        accountStore.recordErrorMessages(error.data.message);
        console.log(error);
    } finally {
        loading.value = false;
    }
}

onMounted(() => {
    getLatestWeeklyFeaturedTeam();
    cardStore.pullOwnedCards();
    setInterval(updateCountdown, 1000);
});
</script>