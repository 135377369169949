<template>
    <div>
        <FlashMessage />
        <!-- Input field for combined rarity and team search -->
        <HelpBox />
        <input class="curved-input-dark" v-model="combinedFilter" placeholder="Filtrer par nom d'équipe ou rareté" />
        <h6 class="text-white regular px-2" v-if="totalCollections">{{ totalCollections }} collections</h6>
        <div v-if="loading">
            <LoadingSpinner :loading="loading" />
        </div>
        <div v-else>
            <div class="collection-flex" v-if="filteredTeamCollections">
                <!-- Use v-for on the filtered array -->
                <TransitionGroup name="cardlist">
                    <div class="col-el mb-4" v-for="collection in filteredTeamCollections" :key="collection.id">
                        <TeamCollectionCard :teamcollection="collection" />
                    </div>
                </TransitionGroup>
            </div>
        </div>
    </div>
</template>

<style scoped>
.collection-flex {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
}
</style>


<script setup>
import LoadingSpinner from '../../../components/LoadingSpinner.vue';
import FlashMessage from '../../../components/FlashMessage.vue';
import TeamCollectionCard from '../components/TeamCollectionCard.vue';
import HelpBox from '@/components/HelpBox.vue';

import { useCardStore } from '@/stores/card';
import { ref, defineComponent, onMounted, computed, watch } from 'vue';


// Stores
const cardStore = useCardStore();
const loading = ref(false);

// Reactive variables
const TeamCollectionList = computed(() => {
    return cardStore.getAllTeamCollectionsData;
});
const nextPageLink = computed(() => {
    return cardStore.getNextPage;
});
const totalCollections = computed(() => {
    if (filteredTeamCollections.value) {
        return filteredTeamCollections.value.length;
    } else {
        return cardStore.getTotalCollections;
    }
});

// Add a reactive property for the combined search
const combinedFilter = ref('');

async function getCollections() {
    try {
        let response;
        response = await cardStore.pullAllTeamCollections();
    } catch (error) {
        console.log(error);
    } finally {
        loading.value = false;
    }
}

// Create a computed property to filter the TeamCollectionList based on the combined search
const filteredTeamCollections = computed(() => {
    const filterText = combinedFilter.value.toLowerCase();

    return TeamCollectionList.value.filter((collection) =>
        collection.rarity_name.toLowerCase().includes(filterText) ||
        collection.name.toLowerCase().includes(filterText)
    );
});

onMounted(() => {
    loading.value = true;
    getCollections();
});

</script>
  