import {defineStore} from 'pinia';
import auctionApi from '@/modules/auction/api';

export const useAuctionStore = defineStore ('auction', {
  state: () => ({
    bid: null,
    bidList: [],
    auctionList: [],
    connectedUsers: [],
    auctionMessage: null,
    ownedAuctionList: [],
    singleAuctionData: null,
    dataLoading: false,
    nextPage: null,
    totalCount: null,
  }),

  actions: {
    async getAllAuctions(filters) {
      try {
        const response = await auctionApi.pullAllAuctions (filters);
        if (response && response.data && response.status === 200) {
					this.setAllAuctions(response.data.results);
					this.setTotalCount(response.data.count);
          if (response.data.next) {
						this.setNextPage(response.data.next);
          } else {
            this.setNextPage(null);
          }
        }
      } catch (error) {
        console.error ('Error in getAllAuctions action:', error);
        throw error;
      }
    },
    async getAllBids (id) {
      try {
        const response = await auctionApi.getAllBids (id);
        if (response && response.data && response.status === 200) {
					this.setBids(response.data);
					this.setTotalBids(response.data.length);
        }
      } catch (error) {
        console.error ('Error in getAllBids action:', error);
        throw error;
      }
    },
    async getOwnedAuctions() {
      try {
        const response = await auctionApi.pullOwnedAuctions ();
        if (response && response.data && response.status === 200) {
					this.setOwnedAuctions(response.data.results);
					this.setTotalCount(response.data.count);
          if (response.data.next) {
						this.setNextPage(response.data.next);
          }
        }
      } catch (error) {
        console.error ('Error in getOwnedAuctions action:', error);
      }
    },
    async getSingleAuction (id) {
      try {
        const response = await auctionApi.getSingleAuction (id);
        if (response && response.data && response.status === 200) {
					this.setSingleAuctionData(response.data);
        }
        return response;
      } catch (error) {
        console.error ('Error in getSingleAuction action:', error);
        throw error;
      }
    },
    async createAuction (data) {
      try {
        const response = await auctionApi.createAuction (data);
        if (response && response.data && response.status === 201) {
          return response;
        }
      } catch (error) {
        console.error ('Error in createAuction action:', error);
        throw error;
      }
    },
    async placeBidonAuction (data) {
      try {
        const response = await auctionApi.placeBid (data);
        if (response && response.data && response.status === 201) {
          return response;
        }
      } catch (error) {
        console.error ('Error in placeBidonAuction action:', error);
        throw error;
      }
    },
    
    setConnectedUsers (data) {
      this.connectedUsers = data;
    },
    addAuctionsToList (data) {
      this.auctionList = [...this.auctionList, ...data];
    },
    removeAuctionFromList (id) {
      this.auctionList = this.auctionList.filter (auction => auction.id !== id);
    },
    clearAuctionMessage() {
      this.auctionMessage = null;
    },
    setBid (data) {
      this.bid = data;
    },
    setBids (data) {
      this.bidList = data;
    },
    setAllAuctions (data) {
      this.auctionList = data;
    },
    clearNextPage () {
      this.nextPage = null;
    },
    setOwnedAuctions (data) {
      this.ownedAuctionList = data;
    },
    setSingleAuctionData (data) {
      this.singleAuctionData = data;
    },
    updateAuctionBid (data) {
      this.singleAuctionData.current_bid = data;
    },
    updateAuctionOnBid (data) {
      this.singleAuctionData.highest_bidder_name = data.highest_bidder_name;
      this.singleAuctionData.highest_bidder = data.highest_bidder;
      this.singleAuctionData.current_bid = data.amount;
      this.singleAuctionData.number_of_bids += 1;
    },
    updateAuctionListOnBid (data) {
      this.auctionList = this.auctionList.map(auction => {
        if (auction.id === data.auction) {
          auction.highest_bidder_name = data.highest_bidder_name;
          auction.highest_bidder = data.highest_bidder;
          auction.current_bid = data.amount;
          auction.number_of_bids += 1;
        }
        return auction;
      })
    },
    setAuctionMessage (data) {
      this.auctionMessage = data;
    },
    setNextPage (data) {
      this.nextPage = data;
    },
    setTotalBids (data) {
      this.totalBids = data;
    },
    setTotalCount (data) {
      this.totalCount = data;
    },
  },
  getters: {
		getAuctionList() {
			return this.auctionList;
		},
    getAuctionMessage() {
      return this.auctionMessage;
    },
		getBidList() {
			return this.bidList;
		},
    getConnectedUsers() {
      return this.connectedUsers;
    },
		getOwnedAuctionList() {
			return this.ownedAuctionList;
		},
		getSingleAuctionData() {
			return this.singleAuctionData;
		},
		getNextPage() {
			return this.nextPage;
		},
		getTotalCount() {
			return this.totalCount;
		},
		getTotalBids() {
			return this.totalBids;
		}
  },
});
