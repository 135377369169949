<template>
    <section class=" gradient-custom">
        <div class="container">
            <div class="row d-flex justify-content-center align-items-center min-vh-100">
                <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                    <!-- <FlashMessage /> -->

                    <div class="login-card text-white" style="border-radius: 1rem">
                        <div v-if="!successUpdate" class="card-body p-5 text-center">
                            <div class="mb-md-5 mt-md-4 pb-5">
                                <h2 class="fw my-2 text-uppercase">Enter your new password</h2>
                                <div class="form-outline form-white mb-4">
                                    <form @submit.prevent="PasswordReset">
                                        <div class="form-outline form-white mb-4">
                                            <input type="password" class="form-control-lg" v-model="formData.password1"
                                                placeholder="Enter your password" required />
                                        </div>
                                        <div
                                            :class="{ 'validator': true, 'valid': isPasswordValidLength, 'invalid': !isPasswordValidLength }">
                                            Password should be at least 8 characters
                                        </div>
                                        <div
                                            :class="{ 'validator': true, 'valid': isPasswordContainingSpecialCharacter, 'invalid': !isPasswordContainingSpecialCharacter }">
                                            Password must contain a special character(!@#$%^&*()_+)
                                        </div>
                                        <div
                                            :class="{ 'validator': true, 'valid': isPasswordContainingBothCases, 'invalid': !isPasswordContainingBothCases }">
                                            Password contains both uppercase and lowercase letters
                                        </div>
                                        <div class="form-outline form-white mb-4">
                                            <input type="password" class="form-control-lg" v-model="formData.password2"
                                                placeholder="Confirm your password" required />
                                        </div>
                                        <div
                                            :class="{ 'validator': true, 'valid': verifyPasswordMatch, 'invalid': !verifyPasswordMatch }">
                                            Password do not match
                                        </div>
                                        <button class="btn btn-light btn-lg px-5" type="submit"
                                            :disabled="!areAllConditionsMet">
                                            Reset my Password
                                        </button>
                                    </form>

                                </div>
                            </div>
                        </div>
                        <div v-if="successUpdate" class="card-body p-5 text-center">
                            <p>Your password was successfully updated. You may now login</p>
                            <router-link to="login" class="text-white-50 fw-bold">Login Now</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style>
.validator {
    margin-top: 4px;
    font-size: 0.8em;
}

.valid {
    color: green;
    display: none;
}

.invalid {
    color: #ff8181;
}
</style>

<script>
import { ref, onMounted, computed } from 'vue'; // Import ref and onMounted
import { useAccountStore } from '@/stores/account';
import { useRouter, useRoute } from 'vue-router'

export default ({
    setup() {
        const accountStore = useAccountStore();
        const router = useRouter()
        const route = useRoute()

        const errorMessage = ref('');
        const formData = ref({
            password1: '',
            password2: '',
        });
        let successUpdate = false;

        const isPasswordValidLength = computed(() => {
            return formData.value.password1.length >= 8;
        });

        const isPasswordContainingBothCases = computed(() => {
            return /[a-z]/.test(formData.value.password1) && /[A-Z]/.test(formData.value.password1);
        });

        const isPasswordContainingSpecialCharacter = computed(() => {
            return /[\W_]/.test(formData.value.password1);
        });

        const verifyPasswordMatch = computed(() => {
            return formData.value.password1 === formData.value.password2;
        });

        const areAllConditionsMet = computed(() => {
            return (
                isPasswordValidLength.value &&
                isPasswordContainingBothCases.value &&
                isPasswordContainingSpecialCharacter.value &&
                verifyPasswordMatch.value
            );
        });
        
        onMounted(() => {
            accountStore.clearErrorMessages();
        });

        async function PasswordReset() {
            try {
                const token = route.params.token;
                const response = await accountStore.passwordReset({ token: token, payload: formData.value });
                successUpdate = true;
                router.push({ name: 'Welcome' });
            } catch (error) {
                console.log(error);
            }
        };
        return {
            errorMessage,
            formData,
            PasswordReset,
            isPasswordValidLength,
            isPasswordContainingBothCases,
            isPasswordContainingSpecialCharacter,
            verifyPasswordMatch,
            areAllConditionsMet,
            successUpdate,
        }
    },
})
</script>