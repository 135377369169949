import {defineStore} from 'pinia';
import notificationApi from '@/modules/notification/api';

export const useNotificationStore = defineStore ('notification', {
    state: () => ({
        inbox: [],
        archive: [],
    }),

    actions: {
        setInboxData (data) {
            this.inbox = data;
        },
        setArchiveData (data) {
            this.archive = data;
        },
        editNotification (data) {
            const index = this.inbox.findIndex (item => item.uuid === data.uuid);
            this.inbox[index] = data;
        },
        addNotification (data) {
            this.inbox.unshift(data);
        },
        async pullInboxData() {
            try {
                const response = await notificationApi.inbox ();
                if (response && response.data && response.status === 200) {
                    this.setInboxData(response.data.results);
                }
            } catch (error) {
                console.error ('Error in getInboxData action:', error);
            }
        },
        async pullArchiveData() {
            try {
                const response = await notificationApi.archive ();
                if (response && response.data && response.status === 200) {
                    this.setArchiveData(response.data.results);
                }
            } catch (error) {
                console.error ('Error in getArchiveData action:', error);
            }
        },
        async markAsRead(uuid) {
            try {
                const response = await notificationApi.markAsRead(uuid);
                if (response && response.data && response.status === 200) {
                    this.editNotification(response.data);
                }
            } catch (error) {
                console.error ('Error in markAsRead action:', error);
            }
        },
        async markAllAsRead() {
            try {
                const response = await notificationApi.markAllAsRead();
                if (response && response.data && response.status === 200) {
                    this.pullInboxData();
                }
            } catch (error) {
                console.error ('Error in markAllAsRead action:', error);
            }
        }
    },
    getters: {
        getInboxData() {
            return this.inbox;
        },
        getArchiveData() {
            return this.archive;
        },
    },
});
