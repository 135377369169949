import {useRoute} from 'vue-router';
import {useAuctionStore} from '@/stores/auction';
import { decryptToken } from '@/shared/jwtHelper';

export function useWebSocketAuctions () {
  // WebSocket connection URL (replace with your server's WebSocket URL)
  const route = useRoute ();
  const auctionStore = useAuctionStore ();
  // Initialize WebSocket connection
  const encryptedToken = localStorage.getItem ('acxToken');
  const jwtToken = decryptToken (encryptedToken);

  const socket = new WebSocket (
    `wss://api.djolowindemo.com/ws/auction/all/?token=${jwtToken}`
  );

  // Define callback functions for handling WebSocket events
  socket.onopen = () => {
    socket.send (
      JSON.stringify ({
        type: 'authenticate',
        message: {
          token: jwtToken,
        },
      })
    );
    console.log ('WebSocket connection established.');
  };

  socket.onmessage = event => {
    const data = JSON.parse (event.data);
    console.log ('Received WebSocket message:', data);
    if (data.type === 'new_bid_message') {
      console.log ('Received WebSocket auction bid:', data);
      auctionStore.updateAuctionListOnBid (data.message);
    }
  };

  socket.onclose = event => {
    if (event.wasClean) {
      console.log (
        `WebSocket connection closed cleanly, code=${event.code}, reason=${event.reason}`
      );
    } else {
      console.error ('WebSocket connection abruptly closed');
    }
  };

  socket.onerror = error => {
    console.error ('WebSocket error:', error.message);
  };

  const closeWebSocketConnection = () => {
    socket.close ();
  };
  return {closeWebSocketConnection};
}
