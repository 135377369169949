
import instance from "@/baseAPI";

const notificationApi = {
    inbox: () => instance.get("notification/inbox/"),
    archive: () => instance.get("notification/archive/"),
    markAsRead: (uuid) => instance.post(`notification/${uuid}/mark-as-read/`),
    markAllAsRead: () => instance.post("notification/mark-all-as-read/"),
};

export default notificationApi;