import {defineStore} from 'pinia';
import footballApi from '@/modules/sports/football/api';

export const useFootballStore = defineStore ('football', {
  state: () => ({
    footballData: [],
    leagueData: [],
    leagueDetail: null,
    teamData: [],
    playerListData: [],
    singlePlayerData: null,
    singlePlayerSales: [],
    singlePlayerAuctions: [],
    dataLoading: false,
    nextPage: null,
  }),

  actions: {
    async pullLeagues () {
      try {
        const response = await footballApi.getLeagues ();
        if (response && response.data && response.status === 200) {
          this.setLeagueData (response.data);
        }
      } catch (error) {
        console.error ('Error in getLeagues action:', error);
      }
    },
    async pullSingleLeague (slug) {
      try {
        const response = await footballApi.getSingleLeague (slug);
        if (response && response.data && response.status === 200) {
          this.setLeagueDetail (response.data);
        }
      } catch (error) {
        console.error ('Error in getSingleLeague action:', error);
      }
    },
    async pullTeamDetail (slug) {
      try {
        const response = await footballApi.getSingleTeam (slug);
        if (response && response.data && response.status === 200) {
          this.setTeamData (response.data);
        }
      } catch (error) {
        console.error ('Error in getTeamDetail action:', error);
      }
    },
    async pullPlayers () {
      try {
        const response = await footballApi.getPlayers ();
        if (response && response.data && response.status === 200) {
          this.setPlayerListData (response.data);
        }
      } catch (error) {
        console.error ('Error in getPlayers action:', error);
      }
    },
    async pullPlayerDetails (slug, filters) {
      try {
        const response = await footballApi.getSinglePlayer (slug, filters);
        if (response && response.data && response.status === 200) {
          this.setSinglePlayerData (response.data.results.player);
          this.setSinglePlayerSales (response.data.results.player_sales);
          this.setSinglePlayerAuctions (response.data.results.player_auctions);
          if (response.data.next && response.data.next !== null) {
            this.setNextPage (response.data.next);
          } else {
						this.clearNextPage ();
					}
        }
      } catch (error) {
        console.error ('Error in getPlayerDetails action:', error);
      }
    },
    setFootballData (data) {
      this.footballData = data;
    },
    setLeagueData (data) {
      this.leagueData = data;
    },
    setLeagueDetail (data) {
      this.leagueDetail = data;
    },
    setTeamData (data) {
      this.teamData = data;
    },
    setNextPage (data) {
      this.nextPage = data;
    },
    setPlayerListData (data) {
      this.playerListData = data;
    },
    setSinglePlayerData (data) {
      this.singlePlayerData = data;
    },
    setSinglePlayerSales (data) {
      this.singlePlayerSales = data;
    },
    removeSaleFromList (saleId) {
      this.singlePlayerSales = this.singlePlayerSales.filter (
        sale => sale.id !== saleId
      );
    },
    setSinglePlayerAuctions (data) {
      this.singlePlayerAuctions = data;
    },
    clearSinglePlayerAuctions () {
      this.singlePlayerAuctions = [];
    },
    removeAuctionFromList (auctionId) {
      this.singlePlayerAuctions = this.singlePlayerAuctions.filter (
        auction => auction.id !== auctionId
      );
    },
    clearSinglePlayerSales () {
      this.singlePlayerSales = [];
    },
    clearSinglePlayerData () {
      this.singlePlayerSales = [];
      this.singlePlayerData = null;
    },
    clearNextPage () {
      this.nextPage = null;
    },
    clearAllData () {
      this.footballData = [];
      this.leagueData = [];
      this.teamData = [];
      this.playerData = [];
    },
    addSales (newSales) {
      this.singlePlayerSales = [...this.singlePlayerSales, ...newSales];
    },
  },

  getters: {
    getLeagueData () {
      return this.leagueData;
    },
    getLeagueDetail () {
      return this.leagueDetail;
    },
    getTeamData () {
      return this.teamData;
    },
    getPlayerListData () {
      return this.playerData;
    },
    getSinglePlayerData () {
      return this.singlePlayerData;
    },
    getSinglePlayerSales () {
      return this.singlePlayerSales;
    },
    getSinglePlayerAuctions () {
      return this.singlePlayerAuctions;
    },
    getNextPage () {
      return this.nextPage;
    },
  },
});
