<template>
    <h2 class="text-white mb-3 mt-4">Notifications</h2>
    <button v-if="containUnread" type="button" class="btn btn-danger" @click="markAllAsRead()">
        Mark All as Read
    </button>
    <div v-if="notifications" class="notification-ui_dd-content">
        <div v-for="notification in notifications" class="notification-list notification-list--unread"
            :key="notification.id">
            <div class="notification-list_content">
                <div v-if="notification.date_read === null" class="unread-dot"></div>
                <div class="notification-list_detail">
                    <div class="text-white notif-subject mb-2">{{ notification.subject }}</div>
                    <p class="text-white-50">{{ notification.body }}</p>
                    <p class="text-white-50"><small>({{ formatRelativeTime(notification.date_sent) }})</small></p>
                    <span class="badge bg-secondary">#{{ notification.category }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
@import '../../../assets/css/notification.css';
</style>

<script setup>
import { onMounted, ref, computed, defineComponent } from 'vue';
import { useNotificationStore } from '@/stores/notification';
import { formatRelativeTime } from '@/utils/dateFormatter';


const notificationStore = useNotificationStore();
const loading = ref(false);

onMounted(() => {
    getInboxNotifications();
});
const notifications = computed(() => {
    return notificationStore.getInboxData;
});
const containUnread = computed(() => {
    return notificationStore.getInboxData.some((notification) => {
        return notification.date_read === null;
    });
});

async function getInboxNotifications() {
    try {
        loading.value = true;
        await notificationStore.pullInboxData();
    } catch (error) {
        console.log(error);
    } finally {
        loading.value = false;
    }
}
async function markAllAsRead() {
    try {
        await notificationStore.markAllAsRead();
    } catch (error) {
        console.log(error);
    }
}

</script>