import LeagueDetailView from './views/LeagueDetailView.vue';
import LeaguesListView from './views/LeaguesListView.vue';
import TeamDetailView from './views/TeamDetailView.vue';
import TeamsListView from './views/TeamsListView.vue';
import PlayerDetailView from './views/PlayerDetailView.vue';

const routes = [
      {
    path: '/football/leagues',
    component: LeaguesListView,
    name: 'LeaguesList',
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/football/league/:slug',
    component: LeagueDetailView,
    name: 'LeagueDetail',
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/football/teams',
    component: TeamsListView,
    name: 'TeamsList',
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/football/team/:slug',
    component: TeamDetailView,
    name: 'TeamDetail',
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/football/player/:slug',
    component: PlayerDetailView,
    name: 'PlayerDetail',
    meta: {
      requiresAuth: true,
    }
  },
];

export default routes;