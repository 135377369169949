<template>
    <transition name="fade" mode="out-in">
        <div v-if="loading" class="spinner-container ">
            <div class="loading-dots">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </transition>
</template>

<style scoped>
.spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000ab;
    z-index: 9999;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.loading-dots {
    display: flex;
}

.loading-dots div {
    width: 10px;
    height: 10px;
    background-color: #81ffd9;
    margin: 0 5px;
    border-radius: 50%;
    animation: bounce 0.5s infinite alternate;
}

/* Define the fade transition classes */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0.2;
}

/* Define the pulsing animation for the company logo */
.logo-pulse {
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.8);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.8);
    }
}

@keyframes bounce {
    to {
        transform: translateY(-5px);
    }
}
</style>
  
<script>
export default {
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
    },
};
</script>
  