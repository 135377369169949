<template>
    <h1 class="text-white mb-5">All PACKAGES</h1>
    <div v-if="packagesList">
        <div v-for="currencyPackage in packagesList" :key="currencyPackage.id">
            <h3 class="text-white">{{ currencyPackage.in_app_currency }} <i class="fa fa-ils"></i></h3>
            <h5 class="text-white">{{ currencyPackage.current_price }} F CFA</h5>
            <button class="btn btn-primary" @click="purchasePackage(currencyPackage.id)" :disabled="loading">
                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-else>Buy</span>
            </button>
            <hr class="text-white-50">
        </div>

    </div>
</template>

<script>
import { onMounted, ref, computed, defineComponent } from 'vue';
import { useAppCurrencyStore } from '@/stores/app-currency';
import { StripeCheckout } from '@vue-stripe/vue-stripe';

export default defineComponent ({
    components: {
        StripeCheckout
    },
    setup() {
        const currencyStore = useAppCurrencyStore();

        const loading = ref(false);

        onMounted(() => {
            currencyStore.pullCurrencyList();
        });
        const packagesList = computed(() => {
            return currencyStore.getPackagesList;
        });

        async function purchasePackage(id) {
            loading.value = true;
            const response = await currencyStore.purchaseCurrencyPackage(id);
            if (response) {
                const url = response.data.url;
                // Open the URL in a new tab
                window.open(url, '_blank');
            }
            loading.value = false;
        }

        return {
            purchasePackage,
            packagesList,
            loading,
            currencyStore
        }
    }
})
</script>