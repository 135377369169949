import leaderboardView from './views/leaderboardView.vue'

const routes = [
    {
        path: '/leaderboard',
        component: leaderboardView,
        name: 'leaderboard',
        meta: {
            requiresAuth: true,
        }
    }
];

export default routes;