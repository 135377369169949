<template>
    <GoBack />
    <FlashMessage />
    <div class="player-info-ctn">
        <div class="player-info" v-if="player">
            <div class="player-img-ctn">
                <img class="player-info__img" :src="getPlayerDetailImageUrl(player)" alt="Player Image" />
            </div>
            <div class="player-info__details">
                <h1 class="player-info__name">{{ player.full_name }}</h1>
                <div class="player-info__metadata">
                    <p class="player-info__club">{{ player.team_name }}</p>
                    <p class="player-info__jersey">#{{ player.jersey_number }}</p>
                    <p class="player-info__position">{{ player.position }}</p>
                    <p class="player-info__country">{{ player.nationality }}
                        <img :src=getImageUrl(player) width="16" height="12" alt="country-flag">
                    </p>
                    <p class="player-info__jersey">{{ player.date_of_birth }}</p>
                </div>
            </div>
        </div>
        <ul class="custom-list" id="pills-tab" role="tablist">
            <div class="nav-sales-auction">
                <li class="nav-item mx-auto" role="presentation">
                    <button class="auction-sm-btn active" id="player-auctions-tab" data-bs-toggle="tab"
                        data-bs-target="#player-auctions" type="button" role="tab" aria-controls="player-auctions"
                        aria-selected="true">
                        ENCHERES
                    </button>
                </li>
                <li class="nav-item mx-auto" role="presentation">
                    <button class="mx-auto sale-sm-btn" id="player-sales-tab" data-bs-toggle="tab"
                        data-bs-target="#player-sales" type="button" role="tab" aria-controls="player-sales"
                        aria-selected="true">
                        VENTES DIRECTES
                    </button>
                </li>
            </div>
        </ul>
        <hr class="text-white-50" />
    </div>
    <FiltersLine />
    <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane show active" id="player-auctions" role="tabpanel" aria-labelledby="player-auctions-tab">
            <AuctionListContainer :auctionList="auctionList" :loading="loading" />
        </div>
        <div class="tab-pane" id="player-sales" role="tabpanel" aria-labelledby="player-sales-tab">
            <div class="d-flex flex-column flex-lg-row ">
                <div class="card-filters-container mb-4">
                    <CardFilterByPlayer />
                </div>
                <div v-if="salesList.length > 0" id="infinite-list" class="flexdirect mb-4">
                    <TransitionGroup name="cardlist">
                        <div class="card-el" v-for="sale in salesList" :key="sale.id">
                            <SaleCardImage :saleCard="sale" />
                            <div class="card-template-footer">
                                <div role="button" data-bs-toggle="modal" data-bs-target="#saleCardDetailModal"
                                    @click="setPropsCard(sale)">
                                    <SalesCardFooter :saleCard="sale" @saleEnded="handleSaleEnded(sale.id)" />
                                </div>
                            </div>
                            <CardSaleDetail :origin="origin" :saleCard="PropsSaleCard"
                                @loading-updated="handleLoadingUpdate" />
                        </div>
                    </TransitionGroup>
                </div>
                <div v-else>
                    <h1 class="text-white">
                        Aucune carte trouvée
                    </h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref, computed, watch } from 'vue';
import { useFootballStore } from '@/stores/football';
import { useCardStore } from '@/stores/card';
import { useFilterStore } from '@/stores/filters';
import { useRoute, useRouter } from 'vue-router';
import { debounce } from 'lodash';
import { getPlayerDetailImageUrl } from '@/utils/imagesGetter';

import axios from 'axios';

import AuctionListContainer from '@/modules/auction/components/AuctionListContainer.vue';
import CardFilterByPlayer from '@/modules/filters/components/CardFilterByPlayer.vue';
import CardSaleDetail from '@/modules/card/components/CardSaleDetail.vue';
import FiltersLine from '@/modules/filters/components/FiltersLine.vue';
import FlashMessage from '@/components/FlashMessage.vue';
import FullPageSpinner from '@/components/FullPageSpinner.vue';
import GoBack from '@/components/GoBack.vue';
import SaleCardImage from '@/modules/card/components/SaleCardImage.vue';
import SalesCardFooter from '@/modules/card/components/SalesCardFooter.vue';


const footballStore = useFootballStore();
const cardStore = useCardStore();
const filterStore = useFilterStore();
const route = useRoute();
const loading = ref(false);
const origin = ref('player');

const rarities = computed(() => {
    return filterStore.getRarities;
});
const player = computed(() => {
    return footballStore.getSinglePlayerData;
});
const salesList = computed(() => {
    return footballStore.getSinglePlayerSales;
});
const auctionList = computed(() => {
    return footballStore.getSinglePlayerAuctions;
});
const nextPageLink = computed(() => {
    return footballStore.getNextPage;
});
const PropsSaleCard = computed(() => {
    return cardStore.getSingleSaleData;
});
const totalCards = computed(() => {
    if (salesList.value) {
        return salesList.value.length;
    }
});
const debouncedGetSales = debounce(fetchPlayer, 1000);

// Methods ------------------------------------------------------------
function setPropsCard(card) {
    cardStore.setSingleSaleData(card);
}
function handleSaleEnded(saleId) {
    cardStore.removeSaleFromList(saleId);
    debouncedGetSales();
}
async function fetchPlayer() {
    loading.value = true;
    try {
        let response;
        if (rarities.value.length > 0) {
            console.log(rarities.value);
            response = await footballStore.pullPlayerDetails(route.params.slug, { rarity: rarities.value });
        } else {
            response = await footballStore.pullPlayerDetails(route.params.slug);
        }
    } catch (error) {
        console.log(error);
    } finally {
        loading.value = false;
    }
}

async function loadMoreCards() {
    if (!loading.value && nextPageLink.value !== null) {
        loading.value = true;
        try {
            const response = await axios.get(nextPageLink.value);
            footballStore.addSales(response.data.results.player_sales);
            console.log(response.data.results.player_sales);
            if (response.data.next === null) {
                footballStore.clearNextPage();
            } else {
                footballStore.setNextPage(response.data.next);
            }
        } catch (error) {
            console.log(error);
        } finally {
            loading.value = false;
        }
    }
}

function handleLoadingUpdate(value) {
    loading.value = value;
    setTimeout(() => {
        loading.value = false;
    }, 1000);
}

function getImageUrl(player) {
    const url = `https://flagcdn.com/16x12/${player.nationality.toLowerCase().replace(' ', '-')}.png`;
    return url;
}

// Watchers ------------------------------------------------------------
watch(rarities, () => {
    debouncedGetSales();
});

// Lifecycle Hooks ------------------------------------------------------------
onBeforeUnmount(() => {
    footballStore.clearSinglePlayerData();
    footballStore.clearSinglePlayerSales();
});

onMounted(() => {
    fetchPlayer();
    window.addEventListener('scroll', () => {
        if (window.scrollY + window.innerHeight >= document.body.scrollHeight - 200 && nextPageLink.value !== null) {
            loadMoreCards();
        }
    });
});
</script>

<style scoped>
.custom-list {
    list-style-type: none;
}

.tab-pane {
    padding: 10px;
    margin-bottom: 30px;
}

.player-img-ctn {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-sales-auction {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.player-img-ctn::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
    display: block;
    background: linear-gradient(transparent, #040404);
    translate: translateY(50%);
}

.player-info {
    display: flex;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.player-info__img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 20px;
}


.player-info__details {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.player-info__name {
    font-size: 34px;
    font-family: 'Bevan R', sans-serif;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #fff;
}



.player-info__metadata {
    display: flex;
    flex-direction: column;
}

.sale-sm-btn {
    color: #fff;
    background-color: #00000000;
    border: 0;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0 10px;
    text-align: center;
    font-size: 20px;
    font-family: 'Montserrat SemiBold', sans-serif;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
}

.sale-sm-btn:focus {
    box-shadow: 0 0 20px rgba(0, 220, 191, 0.402);
}

.sale-sm-btn.active {
    box-shadow: 0 0 20px rgba(0, 220, 191, 0.402);

}

.auction-sm-btn {
    color: #fff;
    background-color: #00000000;
    border: 0;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0 10px;
    text-align: center;
    font-size: 20px;
    font-family: 'Montserrat SemiBold', sans-serif;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
}

.auction-sm-btn:focus {
    box-shadow: 0 0 20px rgba(0, 220, 191, 0.402);
}

.auction-sm-btn.active {
    box-shadow: 0 0 20px rgba(0, 220, 191, 0.402);

}

.player-info__metadata p {
    margin-right: 15px;
    margin-bottom: 5px;
    font-family: 'Montserrat R', sans-serif;
    font-size: 20px;
    color: #555;
}
@media (max-width: 991px) {
    .player-info__name {
        font-size: 24px;
    }

    .auction-sm-btn {
        font-size: 16px;
        margin: 0 10px;
        border-radius: 25px;
    }

    .custom-list {
        justify-content: space-between;
        padding: 0;
    }

    .sale-sm-btn {
        font-size: 16px;
        margin: 0 10px;
        border-radius: 25px;
    }
}
</style>
