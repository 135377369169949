<template>
    <div class="d-flex flex-column flex-lg-row mb-10">
        <!-- Card List Section -->
        <div class="card-filters-container">
            <AuctionFilters />
        </div>
        <FullPageSpinner v-if="loading" :loading="loading" />
            <div v-if="auctionList.length > 0" class="flexdirect">
                <TransitionGroup name="cardlist">
                    <div class="card-el" v-for="auction in auctionList" :key="auction.id">
                        <AuctionInRows :auction="auction" @AuctionEnded="handleAuctionEnded(auction.id)" />
                    </div>
                    <LoadingSpinner v-if="hiddenLoading" :loading="hiddenLoading" />
                </TransitionGroup>
            </div>
            <div v-else>
                <h3>Aucune enchère trouvée</h3>
            </div>
        </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useAuctionStore } from '@/stores/auction';
import { useFilterStore } from '@/stores/filters';

import AuctionFilters from '@/modules/filters/components/AuctionFilters.vue';
import AuctionInRows from '../components/AuctionInRows.vue';
import FullPageSpinner from '@/components/FullPageSpinner.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import axios from 'axios';

const props = defineProps({
    auctionList: {
        type: Array,
        required: true
    },
    loading: {
        type: Boolean,
        required: true
    },
    hiddenLoading: {
        type: Boolean,
        required: false,
        default: false
    }
});


const auctionStore = useAuctionStore();
const filterStore = useFilterStore();



function handleAuctionEnded(auctionId) {
    setTimeout(() => {
        auctionStore.removeAuctionFromList(auctionId);
    }, (10 * 60 * 1000)); // 10 minutes
}



onMounted(() => {
    filterStore.clearFilters();
});
</script>
