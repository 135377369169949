import { defineStore } from "pinia";

export const useFilterStore = defineStore("filters", {
    state: () => ({
        selectedRarities: [],
        selectedPositions: [],
        selectedStatus: [],
        selectedName: null,
    }),

    actions: {
        setPositions (data) {
            this.selectedPositions = data;
        },
        removePosition (data) {
            this.selectedPositions = this.selectedPositions.filter (position => position !== data);
        },
        clearPositions () {
            this.selectedPositions = [];
        },
        setRarities (data) {
            this.selectedRarities = data;
        },
        removeRarity (data) {
            this.selectedRarities = this.selectedRarities.filter (rarity => rarity !== data);
        },
        clearRarities () {
            this.selectedRarities = [];
        },
        clearFilters () {
            this.selectedRarities = [];
            this.selectedName = null;
            this.selectedPositions = [];
            this.selectedStatus = [];
        },      
        setSelectedName (data) {
            this.selectedName = data;
        },
        clearSelectedName () {
            this.selectedName = null;
        },
        setStatus (data) {
            this.selectedStatus = data;
        },
        removeStatus (data) {
            this.selectedStatus = this.selectedStatus.filter (status => status !== data);
        },
        clearStatus () {
            this.selectedStatus = [];
        },      
    },

    getters: {
        getPositions () {
            return this.selectedPositions;
        },
        getRarities () {
            return this.selectedRarities;
        },
        getSelectedName () {
            return this.selectedName;
        },
        getStatus () {
            return this.selectedStatus;
        },
    }
});