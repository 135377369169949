import {ref, computed} from 'vue';

export function usePagination (
  cards,
  totalCards,
  itemsPerPage,
  initialPage = 1
) {
  const currentPage = ref (initialPage);

  const pageCount = computed (() => {
    return Math.ceil (totalCards.value / itemsPerPage.value);
  });

  const pages = computed (() => {
    const pages = [];
    for (let i = 1; i <= pageCount.value; i++) {
      pages.push (i);
    }
    return pages;
  });

  const startItem = computed (() => {
    return (currentPage.value - 1) * itemsPerPage.value;
  });

  const endItem = computed (() => {
    return startItem.value + itemsPerPage.value;
  });

  const displayedItems = computed (() => {
    return cards.value.slice (startItem.value, endItem.value);
  });

  function goToPage (page) {
    currentPage.value = page;
  }

  return {
    currentPage,
    pageCount,
    pages,
    displayedItems,
    goToPage,
  };
}
