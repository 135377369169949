<template>
    <div class="filter-title subrayada" role="button" data-bs-toggle="collapse" :data-bs-target="'#rarity-section'"
        aria-expanded="false" @click="toggleIconRotation()">
        {{ $t('Rarity') }}
        <i class="fa-solid fa-caret-up arrow" :class="{ 'fa-rotate-180': rotationValues }"></i>
    </div>

    <div class="collapse rarity-collapse show" id="rarity-section">

        <label class="normal-label checkbox-label">
            {{ $t('Normal') }}
            <input class="form-check-input bg-dark" type="checkbox" value="Normal" v-model="selectedRarities">
        </label>
        <label class="limited-label checkbox-label">
            {{ $t('Limited') }}
            <input class="form-check-input bg-dark" type="checkbox" value="Limited" v-model="selectedRarities">
        </label>
        <label class="rare-label checkbox-label">
            {{ $t('Rare') }}
            <input class="form-check-input bg-dark" type="checkbox" value="Rare" v-model="selectedRarities">
        </label>
        <label class="super-rare-label checkbox-label">
            {{ $t('Super Rare') }}
            <input class="form-check-input bg-dark" type="checkbox" value="Super Rare" v-model="selectedRarities">
        </label>
        <label class="unique-label checkbox-label">
            {{ $t('Unique')  }}
            <input class="form-check-input bg-dark" type="checkbox" value="Unique" v-model="selectedRarities">
        </label>
    </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useFilterStore } from '@/stores/filters';

// Stores --------------------------------------------------------------------
const filterStore = useFilterStore();

const rotationValues = ref(false);
const selectedRarities = ref([]);

const stateRarities = computed(() => {
    return filterStore.getRarities;
});

// Watch for changes in selectedPlayerName or selectedRarities

watch([stateRarities], () => {
    selectedRarities.value = stateRarities.value;
});

watch([selectedRarities], () => {
    filterStore.setRarities(selectedRarities.value);
});

function toggleIconRotation() {
    rotationValues.value = !rotationValues.value;
}

onMounted(() => {
    selectedRarities.value = stateRarities.value;
});


</script>