import {defineStore} from 'pinia';
import walletApi from '@/modules/wallet/api';

export const useWalletStore = defineStore ('wallet', {
    state: () => ({
        walletData: {
            balance: 0,
            reservedBalance: 0,
        },
        walletTransactions: [],
    }),

    actions: {
        setWalletData (data) {
            this.walletData.balance = data.balance;
            this.walletData.reservedBalance = data.reserved_balance;
        },
        setWalletTransactions (data) {
            this.walletTransactions = data;
        },
        async pullWalletData() {
            try {
                const response = await walletApi.dashboard ();
                if (response && response.data && response.status === 200) {
                    this.setWalletData(response.data);
                    console.log('Wallet data', this.walletData);
                }
            } catch (error) {
                console.error ('Error in getWalletData action:', error);
            }
        },
        async pullWalletTransactions() {
            try {
                const response = await walletApi.transactions ();
                if (response && response.data && response.status === 200) {
                    this.setWalletTransactions(response.data.results);
                }
            } catch (error) {
                console.error ('Error in getWalletData action:', error);
            }
        }
    },
    getters: {
        getWalletData() {
            return this.walletData;
        },
        getWalletBalance() {
            return this.walletData.balance;
        },
        getWalletTransactions() {
            return this.walletTransactions;
        }
    },
});