<template>
    <Transition name="fade">
        <div v-if="shouldShowFilterLine" class="filter-line">
            <div name="fade" tag="div" class="d-flex">
                <div class="row mx-2" v-for="rarity in selectedRarities">
                    <button class="filter-pill" @click="filterStore.removeRarity(rarity)">
                        {{ rarity }}<i class="fa-solid fa-x"></i>
                    </button>
                </div>
                <div v-if="selectedPlayerName !== null" class="row mx-2">
                    <button class="filter-pill" @click="filterStore.clearSelectedName">
                        {{ selectedPlayerName }}<i class="fa-solid fa-x"></i>
                    </button>
                </div>
                <div class="row mx-2" v-for="position in selectedPositions">
                    <button class="filter-pill" @click="filterStore.removePosition(position)">
                        {{ position }}<i class="fa-solid fa-x"></i>
                    </button>
                </div>
                <div class="row mx-2" v-for="status in selectedStatus">
                    <button class="filter-pill" @click="filterStore.removeStatus(status)">
                        {{ status }}<i class="fa-solid fa-x"></i>
                    </button>
                </div>
                <div v-if="selectedRarities.length > 0 || selectedPlayerName !== null
                    || selectedPositions.length > 0 || selectedStatus.length > 0" class="row mx-2">
                    <button class="filter-pill" @click="filterStore.clearFilters">
                        Effacer tout<i class="fa-solid fa-x"></i>
                    </button>
                </div>
            </div>
        </div>
    </Transition>
</template>

<style scoped>
.fa-x {
    margin-left: 0.5rem;
    font-size: 1.25vh;
}

.filter-pill {
    background-color: #2a2a2a;
    border: 1px solid #1e1e1e;
    border-radius: 20px;
    color: #fff;
    font-size: 2vh;
    padding: 0.2rem 0.5rem;
    margin: 0.2rem;
    cursor: pointer;
}

.filter-line {
    height: 6vh;
    overflow-x: scroll;
    /* Make it a horizontal scrollbar */
    white-space: nowrap;
    /* Prevent line breaks for horizontal scrolling */

    /* Customize the horizontal scrollbar for Webkit-based browsers (Chrome, Safari) */
    scrollbar-width: thin;
    /* "auto" to show only when scrolling */
    scrollbar-color: #3a3a3a #2a2a2a;
    /* thumb and track colors */

    /* Customize the horizontal scrollbar for Firefox */
    scrollbar-width: thin;
    /* "auto" to show only when scrolling */
    scrollbar-color: #3a3a3a #2a2a2a;
    /* thumb and track colors */

    /* Customize the horizontal scrollbar for Internet Explorer */
    -ms-overflow-style: none;
    /* Hide scrollbar */
}

/* Customize the horizontal thumb and track of the scrollbar for Webkit-based browsers */
.filter-line::-webkit-scrollbar {
    height: 10px;
}

.filter-line::-webkit-scrollbar-track {
    background: #0f0f0fc0;
}

.filter-line::-webkit-scrollbar-thumb {
    background: #cfcfcf96;
    border-radius: 8px;
}

.filter-line::-webkit-scrollbar-thumb:hover {
    background: #aeaeae;
}

/* Customize the horizontal thumb and track of the scrollbar for Firefox */
.filter-line::-webkit-scrollbar {
    height: 10px;
}

.filter-line::-webkit-scrollbar-track {
    background: #0f0f0fc0;
}

.filter-line::-webkit-scrollbar-thumb {
    background: #2d2d2d;
    border-radius: 2px;
}

/* Customize the horizontal thumb and track of the scrollbar for Internet Explorer */
.filter-line::-ms-scrollbar {
    height: 10px;
}

.filter-line::-ms-scrollbar-thumb {
    background: #3a3a3a;
}
</style>

<script setup>
import { computed } from 'vue';
import { useFilterStore } from '@/stores/filters';

const filterStore = useFilterStore();

const selectedRarities = computed(() => {
    return filterStore.getRarities;
});
const selectedPlayerName = computed(() => {
    return filterStore.getSelectedName;
});
const selectedPositions = computed(() => {
    return filterStore.getPositions;
});
const selectedStatus = computed(() => {
    return filterStore.getStatus;
});
const shouldShowFilterLine = computed(() => {
    return selectedRarities.value.length > 0 || selectedPlayerName.value !== null
        || selectedPositions.value.length > 0 || selectedStatus.value.length > 0;
});
</script>