<template>
	<div class="card-template"
		:class="saleCard && saleCard.rarity_name ? saleCard.rarity_name.toLowerCase().replace(' ', '-') + '-bg' : ''">
		<div class="card-img-ctn"
			:class="saleCard && saleCard.rarity_name ? saleCard.rarity_name.toLowerCase().replace(' ', '-') + '-imgbg' : ''">
			<img class="card-img-top" :src="getPlayerImageUrl(saleCard.card)" alt="Card image cap">
		</div>
		<!-- <div class="overlay"></div> -->
		<div class="card-body">
			<h5 class="p-name">{{ saleCard.card.pname }}</h5>
			<div class="card-text text-center text-dark">
				<span class="p-index">
					{{ saleCard.card.index }} / {{ saleCard.card.total_index }}
				</span>
				<span class="p-rarity">
					{{ saleCard.card.rarity_name }}
				</span>
				<span class="p-season">
					{{ saleCard.card.season }}
				</span>
				<span class="p-position">
					{{ saleCard.card.position_name }}
				</span>
				<span class="p-age">
					AGE {{ saleCard.card.player_age }}
				</span>
				<span class="p-jersey">
					#{{ saleCard.card.jersey_number }}
				</span>
				<span class="flag-img">
					<img class="p-flag" :src="getFlagImageUrl(saleCard.card)" alt="">
				</span>
			</div>
		</div>
	</div>
</template>

<style scoped>
@import '../../../assets/css/card.css';

</style>

<script setup>
import { computed } from 'vue';
import { getPlayerImageUrl, getFlagImageUrl } from '@/utils/imagesGetter';



const props = defineProps({
	saleCard: Object,
});
const saleCard = computed(() => {
	return props.saleCard;
});
</script>