<template>
    <h1 class="text-white">Success!</h1>
    <p class="text-white">Your purchase was successful. Your in-app currency balance has been updated.</p>
    <p class="text-white">Your new balance is {{ wallet.balance }} DJOBA</p>
    <a href="/wallet/dashboard" class="btn btn-outline-success">
        Return to my dashboard
    </a>
</template>

<script>
import { onMounted, ref, computed, defineComponent } from 'vue';
import { useWalletStore } from '@/stores/wallet';

export default ({
    setup() {
        const walletStore = useWalletStore();

        const loading = ref(false);

        onMounted(() => {
            walletStore.pullWalletData();
        });
        const wallet = computed(() => {
            return walletStore.getWalletData;
        });

        return {
            wallet,
            loading,
            walletStore
        }
    }
})
</script>