<template>
    <GoBack />
    <HelpBox />
    <div v-if="loading">
        <LoadingSpinner :loading="loading" />
    </div>
    <div v-else>
        <div v-if="teamCollection">
            <div class="collection-header"
                :class="teamCollection && teamCollection.rarity_name ? teamCollection.rarity_name.toLowerCase().replace(' ', '-') + '-colbg' : ''">
                <div>
                    <h3 class="text-white bold">{{ teamCollection.rarity_name }} {{ teamCollection.year }}</h3>
                </div>
                <div class="d-flex flex-row justify-content-center align-items-center">
                    <img class="team-logo" :src="teamCollection.team_logo_url" alt="Team Logo" />
                </div>
                <h4 class="text-white bold">{{ teamCollection.name }}</h4>
                <div v-if="ownedCards" class="progress my-3">
                    <div class="progress-bar progress-bar-striped " role="progressbar" :style="{ width: progress + '%' }"
                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                        {{ progress }}%</div>
                </div>
                <h6 class="text-white bold">{{ filteredCardsCount }} acquises / {{ teamCollectionCount }} total
                </h6>
            </div>
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="pill-btn active subrayada" id="nav-cards-tab" data-bs-toggle="tab"
                        data-bs-target="#nav-cards" type="button" role="tab" aria-controls="nav-cards" aria-selected="true"
                        @click="handleTabClick('nav-cards-tab')">
                        Cartes
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="pill-btn subrayada" id="nav-leaderboard-tab" data-bs-toggle="tab"
                        data-bs-target="#nav-leaderboard" type="button" role="tab" aria-controls="nav-leaderboard"
                        aria-selected="false" @click="handleTabClick('nav-leaderboard-tab')">
                        Classement
                    </button>
                </li>
            </ul>
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane show active" id="nav-cards" role="tabpanel" aria-labelledby="nav-cards-tab"
                    v-if="ownedCards">

                    <FlashMessage />
                    <div class="card-list-container">
                        <div class="flexdirect">
                            <div class="card-el" v-for="card in teamCollection.cards" v-if="teamCollection.cards">
                                <CollectionCard :key="card.id" :mastercard="card" :customClass="card.is_owned"
                                    :teamId="teamCollection.team" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane " id="nav-leaderboard" role="tabpanel" aria-labelledby="nav-leaderboard-tab">
                    <LeaderBoard />
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import CollectionCard from '../components/CollectionCard.vue';
import LoadingSpinner from '../../../components/LoadingSpinner.vue';
import FlashMessage from '../../../components/FlashMessage.vue';
import LeaderBoard from '../components/LeaderBoard.vue';
import GoBack from '../../../components/GoBack.vue';
import HelpBox from '@/components/HelpBox.vue';


import { useCardStore } from '@/stores/card';
import { ref, onMounted, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';


// Stores
const cardStore = useCardStore();
const router = useRouter();
const route = useRoute();
const loading = ref(false);
const owned = ref('owned');

// Reactive variables
const teamCollection = computed(() => {
    return cardStore.getSingleTeamCollectionData;
});
const ownedCards = computed(() => {
    return cardStore.getOwnedCardinCollection;
});
const filteredCardsCount = computed(() => {
    if (filteredCards) {
        return filteredCards.value.length;
    }
});
const teamCollectionCount = computed(() => {
    if (cardStore.getSingleTeamCollectionData.cards) {
        return cardStore.getSingleTeamCollectionData.cards.length;
    }
});
const progress = computed(() => {
    const progress = cardStore.getProgress;
    const roundedProgress = Math.round(progress);
    return roundedProgress;
});

const goBack = () => {
    router.go(-1); // Go back one step in the browser history
};


// Function to increment quantity_by_user for duplicate entries
function incrementQuantityForDuplicates() {
    const playerIdToCardMap = new Map();
    console.log(ownedCards);
    if (ownedCards.value && teamCollection.value.cards) {
        // First, build a map of playerId to card for ownedCards
        ownedCards.value.forEach((card) => {
            const playerId = card.player;
            if (!playerIdToCardMap.has(playerId)) {
                playerIdToCardMap.set(playerId, card);
            }
        });
        console.log(playerIdToCardMap);

        // Then, update quantity_by_user for corresponding cards in teamCollection
        teamCollection.value.cards.forEach((card) => {
            const playerId = card.player;
            if (playerIdToCardMap.has(playerId)) {
                // Increment quantity_by_user
                card.quantity_by_user = playerIdToCardMap.get(playerId).quantity_owned;
            }
        });
    }
}

function handleTabClick(tabId) {
    // Store the active tab in local storage
    localStorage.setItem('colls-Tab', tabId);
}

async function getTeamCollection() {
    loading.value = true;
    try {
        const response = await cardStore.pullSingleTeamCollection(route.params.slug);
        if (response.status === 200) {
            cardStore.setProgress(response.data.progress);
            console.log(response.data.progress);
        }
        incrementQuantityForDuplicates();
        if (filteredCards.value.length > 0) {
            for (let i = 0; i < filteredCards.value.length; i++) {
                filteredCards.value[i].is_owned = owned.value;
            }
        }
    } catch (error) {
        console.log(error);
    } finally {
        loading.value = false;
    }
}

function isPlayerInTeamCollection(playerId) {
    return ownedCards.value.filter((card) => card.player === playerId).length > 0;
}

const filteredCards = computed(() => {
    if (!teamCollection.value.cards) {
        return [];
    }
    return teamCollection.value.cards.filter((card) => isPlayerInTeamCollection(card.player));
});

onMounted(() => {
    getTeamCollection();
    // Retrieve the active tab from local storage
    const activeTab = localStorage.getItem('colls-Tab');
    console.log(activeTab);

    if (activeTab) {
        // Activate the tab by triggering a click event on the corresponding button
        document.querySelector(`#${activeTab}`).click();
    }
});
</script>

<style scoped>
.card-el {
    flex-direction: column;
    align-items: center;
    border: 1px dotted #6b6b6b45;
    border-radius: 1rem;
}

.normal-colbg {
    background: linear-gradient(to top, #040404 0%, rgba(84, 84, 84, 0.667) 65%);
}

.limited-colbg {
    background: linear-gradient(to top, #040404 0%, rgba(211, 170, 23, 0.869) 65%);
}

.rare-colbg {
    background: linear-gradient(to top, #040404 0%, rgba(156, 26, 20, 0.667) 65%);
}

.super-rare-colbg {
    background: linear-gradient(to top, #040404 0%, rgba(5, 56, 123, 0.81) 65%);
}

.unique-colbg {
    background: linear-gradient(to top, #061b17 0%, rgba(24, 24, 24, 0) 65%);
}

.progress {
    position: relative;
    width: 80%;
    height: 17px;
    background-color: rgba(0, 0, 0, 0.508);
}

.progress-bar {
    text-align: center;
    line-height: 20px;
    background-color: #81ffd9bc;
    /* Progress bar color */
    color: #000000;
    font-weight: bold;
    font-size: 16px;
    /* Text color */
}

.progress-marker {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4px;
    /* Marker width */
    background-color: #ffffff;
    /* Marker color */
    content: "";
    /* Required for pseudo-elements */
}

.progress-label {
    position: absolute;
    top: -2px;
    /* Adjust the distance from the top of the progress bar */
    left: auto;
    font-size: 14px;
    font-weight: bold;
    font-family: "Montserrat R";
    color: #000;
    /* Text color for the labels */
    white-space: nowrap;
    /* Prevent label from breaking into multiple lines */
}

.collection-header {
    position: relative;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    padding: 1rem;
    margin-bottom: 2rem;
}

@media (max-width: 768px) {
    .flexdirect {
        flex-direction: row;
    }
    .card-el {
		margin: 5px 5px;
		display: flex;
		width: 40%;
	}
    
}
</style>