import {defineStore} from 'pinia';
import {useAuctionStore} from './auction';

export const useEventStore = defineStore ('event', {
  state: () => ({
    auctionEnded: false,
  }),

  actions: {
    setAuctionEnded (value) {
      this.auctionEnded = value;
      setTimeout (() => {
        this.auctionEnded = false;
      }, 3000);
    },
    clearExpiredAuctions () {
      const auctionStore = useAuctionStore ();
      const expiredAuctions = auctionStore.auctionList.filter (auction => {
        return new Date (auction.end_date) < new Date ();
      });
      expiredAuctions.forEach (auction => {
        auctionStore.removeAuction (auction.id);
      });
    },
  },
});
