<template>
    <div class="profile-banner-container">
        <div class="banner-img">
            <img src="../../../assets/ball-dark.jpg" alt="banner" class="background-image" />
        </div>
        <h2 class="banner-title">{{ settingsStore.userProfileData.username }}</h2>
        <h5 class="club-title">{{ settingsStore.userProfileData.club_name }}</h5>
        <div class="nav-pills-ctn">
            <ul class="nav nav-pills">
                <router-link class="pill-router" to="/profile/overview" active-class="active" exact>
                    <li class="nav-item" role="presentation">
                        <button class="pill-btn">
                            {{ $t('Overview') }}
                        </button>
                    </li>
                </router-link>
                <router-link class="pill-router" to="/profile/cards" active-class="active" exact>
                    <li class="nav-item" role="presentation">
                        <button class="pill-btn">
                            {{ $t('My Cards') }}
                        </button>
                    </li>
                </router-link>
                <router-link class="pill-router" to="/profile/auctions" active-class="active" exact>
                    <li class="nav-item" role="presentation">
                        <button class="pill-btn">
                            {{ $t('My Auctions') }}
                        </button>
                    </li>
                </router-link>
                <router-link class="pill-router" to="/profile/collections" active-class="active" exact>
                    <li class="nav-item" role="presentation">
                        <button class="pill-btn">
                            {{ $t('Collections') }}
                        </button>
                    </li>
                </router-link>
            </ul>
        </div>
    </div>
    <router-view></router-view>
</template>


<script setup>
import { onMounted, computed } from 'vue';
import { useSettingsStore } from '@/stores/settings';

const settingsStore = useSettingsStore();

onMounted(() => {
    getUserProfile();
});
async function getUserProfile() {
    try {
        await settingsStore.pullUserProfile();
    } catch (error) {
        if (error.response.status === 401) {
            location.reload();
        } 
        console.log(error);
    }
}

const isCurrentRoute = (route) => {
    return computed(() => {
        return route === $route.path;
    });
};
</script>

<style scoped>
@import '../../../assets/css/settings-menu.css';

.pill-router {
    text-decoration: none;
    transition: all 0.4s ease-in-out;
}

.active {
    /* border-bottom: 1px solid #fff; */
    box-shadow: 0px 15px 10px -10px #40E0D0;
}
</style>