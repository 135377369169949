<template>
    <div class="profile-ctn">
        <div v-if="loginStreak" class="login-ctn">
            <p class="regular small text-white text-center">Current Login Streak: {{ loginStreak.login_streak }}</p>
            <div v-if="!hasCheckedIn">
                <button class="btn checkin-btn btn-block" @click="checkIn">Check-in</button>
                <p class="regular small text-white-50">
                    Today's Reward: {{ loginStreak.check_in_bonus }} <i class="fa fa-ils"></i>
                </p>
            </div>
        </div>
        <div class="container how-to-ctn" data-bs-toggle="offcanvas" data-bs-target="#howToPlayCanvas"
            aria-controls="howToPlayCanvas">
            <div class="text-ctn">
                <h5 class="how-to-title text-white">COMMENT JOUER?</h5>
                <h6 class="regular text-white-50">
                    Cliquez ici pour apprendre les bases du jeu et comment gagner des prix!
                </h6>
            </div>
        </div>
        <div class="container comp-ctn">
            <div class="text-ctn">
                <h5 class="compet-title text-white">NOS COMPETITIONS</h5>
                <h6 class="regular text-white-50">
                    Decouvrez nos competitions actuelles et comment y participer!
                </h6>
            </div>
        </div>
        <div class="container featured-ctn">
            <LatestWeeklyFeaturedTeam />
        </div>
        <div class="container">
            <UserDailyTasksVue />
        </div>

        <div class="offcanvas offcanvas-start howto-bg" tabindex="-1" id="howToPlayCanvas"
            aria-labelledby="howToPlayCanvasLabel">
            <div class="offcanvas-header">
                <h4 class="offcanvas-title text-white my-2" id="howToPlayCanvasLabel">How to Play</h4>
                <button type="button" class="close-btn" data-bs-dismiss="offcanvas" aria-label="Close">
                    <i class="fas fa-times"></i>
                </button>
            </div>
            <div class="offcanvas-body">
                <p class="regular text-white">
                    DjoloWin is a game of skill, strategy and luck. You can win amazing prizes by playing our games and
                    competing in our competitions. Here are the basics of how to play:
                </p>
                <p class="regular text-white">
                    <i class="fa-solid fa-star"></i> <b>Buy a Card</b> - You can buy a card from the marketplace. You
                    can buy a card with a fixed price or you can bid on a card and try to win it in an auction.
                </p>
                <p class="regular text-white">
                    <i class="fa-solid fa-star"></i> <b>Complete a team collection</b> - You win by completing collections
                    of teams.
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useAccountStore } from '@/stores/account';
import { useCardStore} from '@/stores/card';
import LatestWeeklyFeaturedTeam from '@/modules/card/components/LatestWeeklyFeaturedTeam.vue';
import UserDailyTasksVue from './UserDailyTasks.vue';

const accountStore = useAccountStore();
const cardStore = useCardStore();

const loginStreak = computed(() => accountStore.getLoginStreakData);

const hasCheckedIn = computed(() => {
    if (!loginStreak.value) {
        return false;
    }
    if (loginStreak.value.last_login_date === new Date().toISOString().slice(0, 10)) {
        return true;
    }
});

async function checkIn() {
    await accountStore.checkIn();
}

onMounted(async () => {
    await accountStore.pullLoginStreak();
});

</script>

<style scoped>
.container {
    background-color: rgba(26, 26, 26, 0.49);
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    align-items: center;
}

.featured-ctn {
    overflow-x: scroll;
    background: url(../../../assets/goals.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.howto-bg {
    background-color: #1d1d1d;
    color: #fff;
}

.login-ctn {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.how-to-ctn {
    background:
        url('../../../assets/dark-banner.jpg') no-repeat center center;
    box-shadow: 0 2px 5px 0 rgba(25, 187, 160, 0.346);
    transition: all 0.3s ease-in-out;
}

.how-to-ctn:hover {
    cursor: pointer;
    box-shadow: 0 0 15px 0 rgba(25, 187, 160, 0.346);
}

.comp-ctn {
    box-shadow: 0 2px 5px 0 rgba(105, 188, 174, 0.346)
}

.comp-ctn:hover {
    cursor: pointer;
    box-shadow: 0 0 15px 0 rgba(105, 188, 174, 0.346);
}

.how-to-title {
    font-size: 2.5rem;
    margin-bottom: 10px;
    font-family: 'Paytone One R', sans-serif;
    float: left;
}

.compet-title {
    font-size: 2rem;
    margin-bottom: 10px;
    font-family: 'Montserrat Subrayada Bold', sans-serif;
    float: left;
}

.checkin-btn {
    background-color: #2a2a2a;
    border: 0;
    padding: 10px;
    margin: 5px;
    border-radius: 40px;
    color: #fff;
}

.checkin-btn:hover {
    background-color: #26463c;
    border: 0;
    padding: 10px;
    margin: 5px;
    border-radius: 40px;
    color: #fff;
}

.text-ctn {
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.profile-ctn {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}


@media (max-width: 991px) {
    

    .container {
        padding: 10px;
    }

    .compet-title {
    font-size: 1.75rem;
}
    .how-to-title {
    font-size: 1.75rem;
}

    i {
        font-size: 1rem;
    }
}
</style>


