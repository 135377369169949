<template>
    <FlashMessage />
    <div class="d-flex justify-content-center align-items-center flex-column" style="height: 100vh;">
        <img class="nobg-logo" src="../../../assets/djolowin/djolo-win-high-resolution-logo-white-on-black-background-removebg.png" alt="djolowin-logo">
        <h1 class="text-white text-center">Your account was succesfully verified. You can now access the entire DjoloWin
            platform</h1>
        <router-link to="/home" class="text-white-50 fw-bold">
            <button class="save-btn">
                GO TO LOGIN
            </button>
        </router-link>
    </div>
</template>

<style scoped>
.save-btn {
    font-size: 1.75rem;
}
</style>

<script setup>
import { onMounted } from 'vue'; // Import ref and onMounted
import { useAccountStore } from '@/stores/account';
import { useRoute } from 'vue-router'

import FlashMessage from '../../../components/FlashMessage.vue';


const accountStore = useAccountStore();
const route = useRoute()


onMounted(async () => {
    try {
        await accountStore.verifyEmail(route.params.token);
    } catch (error) {
        console.log(error);
    }
});
</script>