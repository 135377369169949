<template>
    <div class="container">
        <div class="bg-wall">
            <div class="invite-title">
                <h1 class="mt-5 text-white text-center ">
                    Invite a friend to DjoloWin and You will get 
                    <strong class="text-warning">
                        Cards and DJOBA Coins!
                    </strong>
                </h1>
            </div>
            <div class="invite-description">
                <h5 class="mt-5 text-white-50 regular text-center">
                    When your friend signs up and collects a total of 5 cards, you will get 15,000 DJOBA coins.
                    You will also obtain additional cards when you reach the milestones below.
                </h5>
            </div>
            <div class="referral-code">
                <input id="copyInput" class="col-sm-8 col-lg-10 curved-input-dark" readonly :value="text" />
                <button  id="copyButton" class="copy-btn" @click="copy">Copy</button>
            </div>
        </div>
    </div>
</template>

<style scoped>
.container {
    margin-top: 20px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}

.bg-wall {
    background: linear-gradient(to bottom, #171717d5, #171717b5), url('../../../assets/dark-background.avif');
    height: 400px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.281);
}

.curved-input-dark {
    border-radius: 20px;
    border: 1px solid #fff;
    background-color: transparent;
    color: #fff;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    max-width: 50%;
    margin-bottom: 20px;
}

.copy-btn {
    border-radius: 15px;
    margin-left: 10px;
    border: 1px solid #000000;
    background-color: #40E0D0;
    font-weight: 900;
    color: #000000;
    padding: 10px;
    font-size: 18px;
    max-width: 50%;
    margin-bottom: 20px;
}

.referral-code {
    margin-top: 80px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.invite-title {
    align-items: center;
    font-size: 48px;
    font-weight: 700;
}

.invite-description {
    align-items: center;
    font-size: 24px;
}
</style>

<script setup>
import { ref, onMounted } from 'vue';

const text = ref('https://djolowin.com/register?referral_code=123456789');

onMounted(() => {
    const copyButton = document.getElementById("copyButton");
    const copyInput = document.getElementById("copyInput");
    
    if (copyButton) {
        copyButton.addEventListener("click", () => {
            copyInput.select(); // Select the text in the input field
            document.execCommand("copy"); // Copy the selected text
            window.getSelection().removeAllRanges(); // Deselect the text
        
            // Provide user feedback
            copyButton.textContent = "Copied!";
            setTimeout(() => {
                copyButton.textContent = "Copy";
            }, 2000); // Reset the button text after 2 seconds
        });
    }
});


</script>