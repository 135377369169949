<template>
    <div class="container inside-ctn mb-3">
        <div class="row">
            <div class="border-right">
                <div class="p-2">
                    <div class="d-flex align-items-center mb-2">
                        <h4 class="text-white bold">
                            Mon Profil
                        </h4>
                    </div>


                    <!-- <h2>Select Your Profile Picture</h2>
                    <div class="image-grid">
                        <div v-for="image in images" :key="image.id" @click="selectImage(image)"
                            :class="{ 'selected': image === selectedImage }">
                            <img :src="image.url" alt="Profile Image">
                        </div>
                    </div>
                    <button @click="setProfilePicture">Set as Profile Picture</button> -->


                    <div class="d-flex flex-column align-items-center text-center">
                        <div class="mb-1">
                            <img class="rounded-img" :src="'https://picsum.photos/200/200'" alt="">
                        </div>
                        <div class="mt-1 mb-3">
                        </div>
                    </div>
                    <div class="align-items-center">
                        <div class="row mb-2">
                            <div class="col-md-12 mb-4">
                                <span class="labels">
                                    Nom d'utilisateur
                                </span>
                                <div class="p-profile">{{ userProfileData.username }}</div>
                                <span class="p-span">
                                    <small>
                                        Les noms d'utilisateur ne peuvent pas être modifiés actuellement.
                                    </small>
                                    <input disabled v-model="userProfileData.username" type="text" class="custom-input-dark p-profile"
                                    name="username" placeholder="Ecris ton nom d'utilisateur..." />
                                </span>
                            </div>
                            <div class="col-md-12 mb-3">
                                <span class="labels">
                                    Nom de mon club
                                </span>
                                <p class="p-profile">{{ userProfileData.club_name }}</p>
                                <input v-model="userProfileData.club_name" type="text" class="custom-input-dark p-profile"
                                    name="club_name" placeholder="Ecris le nom de ton club..." />
                            </div>
                            <div class="col-md-12 mb-3">
                                <span class="labels">Ma bio</span>
                                <p class="p-profile">
                                    {{ userProfileData.bio }}</p>
                                <input v-model="userProfileData.bio" type="text" class="custom-input-dark p-profile"
                                    name="bio" placeholder="Ecris ta bio ici..." />
                            </div>
                            <div class="col-5">
                                <button class="small-save-btn" type="button" @click="editProfile">Confirmer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container inside-ctn">
        <div class="row mt-1 p-2">
            <div class="col-sm-12 col-lg-4 mb-2">
                <span class="labels">
                    Code de Parrainage
                    <i class="fas fa-question-circle" data-toggle="tooltip" data-placement="top"
                        title="Ecris un code de parrainage pour recevoir des récompenses."></i>
                </span>
                <div v-if="userProfileData.referred_by === null">
                    <input  v-model="referralCode" type="text"
                        class="custom-input-dark mt-2 border-0" name="referral_code"
                        placeholder="Ecris un code de parrainage ici..." />
                    <div class="mt-1 mb-3">
                        <button class="small-save-btn" type="button" @click="sendReferralCode">Valider</button>
                    </div>
                </div>
                <p v-if="userProfileData.referred_by !== null" class="p-profile">
                    <small>
                        Tu as déjà utilisé un code de parrainage.
                    </small>
                </p>
                <p class="text-white-50">
                    <small>
                        Mon code de parrainage: <span class="bold text-white">{{ userProfileData.referral_code }}</span>
                        <i class="fas fa-copy" role="button" @click="copyToClipboard(userProfileData.referral_code)"></i>
                    </small>
                </p>
            </div>
        </div>
        <hr class="text-white-50" />
        <div class="p-1">
            <h5 class="text-white mt-3 mb-4">
                Progression du parrainage
            </h5>
            <p class="p-profile">
                Tu as parrainé <span class="bold text-white">{{ referralCount }}</span> personnes. 
            </p>
            <div class="progress mt-2 col-lg-6 col-sm-12">
                <!-- Labels for markers -->
                <div class="progress-label" style="left: 1%;">1</div>
                <div class="progress-label" style="left: 16%;">2</div>
                <div class="progress-label" style="left: 36%;">4</div>
                <div class="progress-label" style="left: 55%;">6</div>
                <div class="progress-label" style="left: 74.5%;">8</div>
                <div class="progress-label" style="left: 94%;">10</div>
                <!-- Markers for 10, 20, 30, 40, and 50 referrals -->
                <div class="progress-marker" style="left: 20%;"></div>
                <div class="progress-marker" style="left: 40%;"></div>
                <div class="progress-marker" style="left: 60%;"></div>
                <div class="progress-marker" style="left: 80%;"></div>
                <div class="progress-marker" style="left: 99.2%;"></div>
                <div class="progress-bar-striped bg-success" role="progressbar" aria-valuemin="0"
                    aria-valuemax="{{ maxReferralCount }}" aria-valuenow="{{ progressPercentage }}"
                    :style="{ width: progressPercentage + '%' }">
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, defineComponent, computed, Transition } from 'vue';
import { useSettingsStore } from '@/stores/settings';
import { useAccountStore } from '@/stores/account';

const profileData = ref({
    username: '',
    club_name: '',
    bio: '',
});
const settingsStore = useSettingsStore();
const accountStore = useAccountStore();
const referralCode = ref('');
const images = ref([
    { id: 1, url: 'https://picsum.photos/200/200' },
    { id: 2, url: 'https://picsum.photos/200/200' },
    { id: 3, url: 'https://picsum.photos/200/200' },
])
const selectedImage = ref(null)

onMounted(() => {
    getUserProfile();
    getReferralCount()
});
// Computed properties (getters)
const userProfileData = computed(() => {
    return settingsStore.getUserProfileData;
});
const referralCount = computed(() => {
    return settingsStore.getReferralCount;
});
const maxReferralCount = computed(() => {
    return settingsStore.getMaxReferralCount;
});
const progressPercentage = computed(() => {
    return (referralCount.value / maxReferralCount.value) * 100;
});

// Methods

function selectImage(image) {
    selectedImage.value = image
}
function setProfilePicture() {
    // Set the profile picture to the selected image
    this.userProfileData.imageUrl = this.selectedImage.url
}

async function copyToClipboard(text) {
    try {
        await navigator.clipboard.writeText(text);
        accountStore.recordSuccessMessages('Referral code copied to clipboard.');
    } catch (err) {
        console.error('Failed to copy text: ', err);
    }
}

async function editProfile() {
    profileData.value = {
        user: accountStore.authData.userId,
        username: userProfileData.value.username,
        club_name: userProfileData.value.club_name,
        bio: userProfileData.value.bio,
    };
    try {
        console.log(profileData.value)
        const response = await settingsStore.editUserProfile(profileData.value);
        if (response.status === 200) {
            accountStore.successMessages = "Profile updated successfully."
        }
    } catch (error) {
        if (error.data.message.club_name) {
            accountStore.recordErrorMessages(error.data.message.club_name[0]);
        } else if (error.data.message.bio) {
            accountStore.recordErrorMessages(error.data.message.bio);
        } else {
            accountStore.recordErrorMessages(error.data.message);
        }
    }
}


async function getUserProfile() {
    try {
        await settingsStore.pullUserProfile();
    } catch (error) {
        console.log(error);
    }
}

async function getReferralCount() {
    try {
        await settingsStore.pullReferralCount();
    } catch (error) {
        console.log(error);
    }
}

async function sendReferralCode() {
    if (referralCode.value === '') {
        accountStore.recordErrorMessages('Please enter a referral code.');
        return;
    }
    if (referralCode.value.length !== 14) {
        accountStore.recordErrorMessages('Please enter a valid referral code.');
        return;
    }
    try {
        const response = await settingsStore.submitReferralCode({ ref_code: referralCode.value });
        if (response.status === 200) {
            referralCode.value = '';
            accountStore.recordSuccessMessages(response.data.message);
        }
    } catch (error) {
        accountStore.recordErrorMessages(error.data.message);
    }
}
function handleFileChange(event) {
    // Handle the file selection here
    const file = event.target.files[0];

    if (file) {
        // Perform any necessary actions with the selected file
        // For example, you can upload the file to a server and update userProfileData.imageUrl
        // For simplicity, we'll set the imageUrl to a local URL for demonstration purposes
        this.userProfileData.imageUrl = URL.createObjectURL(file);
    }
}
function openFileInput() {
    // Programmatically trigger a click event on the hidden file input
    this.$refs.fileInput.click();
}

</script>

<style scoped>
.progress {
    position: relative;
    width: 60%;
    height: 17px;
    background-color: rgba(255, 255, 255, 0.533)
}

.progress-bar {
    text-align: center;
    line-height: 20px;
    background-color: #81ffd9bc;
    /* Progress bar color */
    color: #000000;
    font-weight: bold;
    font-size: 16px;
    /* Text color */
}

.progress-marker {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4px;
    /* Marker width */
    background-color: #000000;
    /* Marker color */
    content: "";
    /* Required for pseudo-elements */
}

.progress-label {
    position: absolute;
    top: -2px;
    /* Adjust the distance from the top of the progress bar */
    left: auto;
    font-size: 14px;
    font-weight: bold;
    font-family: "Montserrat R";
    color: #000000;
    /* Text color for the labels */
    white-space: nowrap;
    /* Prevent label from breaking into multiple lines */
}
</style>