<template>
    <FullPageSpinnerVue v-if="loading" :loading="loading" />
    <div class="modal fade" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <section class="gradient-custom">
                    <div class="row d-flex">
                        <div class="login-card text-white" style="border-radius: 1rem">
                            <button type="button" class="close-btn m-3" data-bs-dismiss="modal" aria-label="Close">
                                <i class="fas fa-times"></i>
                            </button>
                            <FlashMessage />
                            <div class="card-body px-3 text-center">
                                <form @submit.prevent="login">
                                    <div class="mb-md-5 pb-2">
                                        <img src="../../../assets/djolowin/djolo-win-high-resolution-logo-white-on-black-background-removebg.png"
                                            alt="logo" class="img-fluid">
                                        <h2 class="fw my-2 mb-4">{{ $t('Djolowin Welcome') }}</h2>
                                        <div class="form-outline form-white mb-4">
                                            <input class="control form-control-lg" name="email" v-model="formData.email"
                                                placeholder="Enter your email" required />
                                        </div>
                                        <div
                                            :class="{ 'validator': true, 'valid': isPasswordValidLength, 'invalid': !isPasswordValidLength }">
                                            {{ $t('Password length validation') }}
                                        </div>
                                        <div class="form-outline form-white mb-4">
                                            <input class="control form-control-lg" name="password"
                                                v-model="formData.password" :type="showPassword ? 'text' : 'password'"
                                                placeholder="Enter your password" required />
                                            <span @click="togglePasswordVisibility" class="toggle-password-icon">
                                                <!-- You can replace 'eye' and 'eye-slash' with your preferred icons -->
                                                <i v-if="showPassword" class="fas fa-eye-slash"></i>
                                                <i v-else class="fas fa-eye"></i>
                                            </span>
                                        </div>
                                        <div class="mb-4">
                                            <!-- Use a regular button and attach a click event handler -->
                                            <button class="btn btn-go px-5" @click="login">{{ $t('Sign In')
                                            }}</button>
                                        </div>
                                        <p class="mb-0 mt-3 text-white-50">{{ $t('No Account?') }} <a role="button"
                                                class="text-white fw-bold" @click="switchToSignup">{{ $t('Sign Up')
                                                }}</a>
                                        </p>
                                        <hr class="mt-5">
                                        <div class="d-flex justify-content-center text- w-100">
                                            <GoogleLogin :callback="callback" class='justify-content-center text-center' />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<style scoped>
.img-fluid {
    width: 160px;
    height: auto;
}

.form-control-lg {
    height: 1.5rem;
    padding: 1.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 5rem;
    background-color: #040404;
    border: 2px solid #303030;
    color: #fff;
}

.form-control-lg:focus {
    color: #fff;
    background-color: #080808;
    border-color: #fff;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%);
}



.btn-go {
    background-color: #9afff0c4;
    border-radius: 5rem;
    height: 3rem;
}

.btn-go:focus {
    box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%);
}

@media (max-width: 767px) {

    .btn-go {
        scale: 0.9;
    }

    .text-uppercase {
        font-size: 1.25rem;
    }

    .form-control-lg {
        height: 1rem;
        padding: 0.75rem 0.5rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 5rem;
    }
}
</style>


<script setup>
import { ref, onMounted, computed } from 'vue'; // Import ref and onMounted
import { useAccountStore } from '@/stores/account';
import { useRouter, useRoute } from 'vue-router'
import FullPageSpinnerVue from '@/components/FullPageSpinner.vue';
import FlashMessage from '@/components/FlashMessage.vue';


const accountStore = useAccountStore();
const router = useRouter()
const route = useRoute()
const loading = ref(false);
const showPassword = ref(false);


const formData = ref({
    email: '',
    password: '',
});
onMounted(() => {
    accountStore.clearErrorMessages();

});

const isPasswordValidLength = computed(() => {
    return formData.value.password.length >= 8;
});

function togglePasswordVisibility() {
    showPassword.value = !showPassword.value;
}

function switchToSignup() {
    const myModal = document.getElementById('loginModal');
    myModal.setAttribute('aria-hidden', 'true');
    const closeButton = myModal.querySelector('.close-btn');
    if (closeButton) {
        closeButton.click();
    }
    const myModal2 = document.getElementById('signlog-btn');
    const closeButton2 = myModal2.querySelector('.signup-btn-md');
    if (closeButton2) {
        closeButton2.click();
    }
}

async function login() {
    const payload = {
        email: formData.value.email.toLowerCase(),
        password: formData.value.password,
    };
    loading.value = true;
    try {
        const response = await accountStore.login(payload).then(() => {
            loading.value = false;
            if (accountStore.isTokenActive === true) {
                if (route.query.redirect) {
                    router.push(this.$route.query.redirect);
                } else {
                    router.push({ name: 'ProfileOverview' });
                }
            } else {
                router.push({ name: 'ProfileOverview' });
                // window.location.reload();
            }
        });
    } catch (error) {
        loading.value = false;
        if (error.status === 401) {
            accountStore.errorMessages.value = error.response.data.detail;
        }
    }
}

async function callback(request) {
    loading.value = true;
    // This callback will be triggered when the user selects or login to
    // his Google account from the popup
    const payload = {
        token: request.credential,
    };
    const myModal = document.getElementById('loginModal');
    myModal.setAttribute('aria-hidden', 'true');
    const closeButton = myModal.querySelector('.close-btn');
    if (closeButton) {
        closeButton.click();
    }
    try {
        const response = await accountStore.googleLogin(payload);
        loading.value = false;
        if (response && response.status === 200) {
            // Access specific data from the response
            await router.push({ name: 'ProfileOverview' });
        } else if (response && response.status === 201) {
            await router.push({ name: 'VerifyEmailSent' });
        }
    } catch (error) {
        loading.value = false;
        console.error("eRor", error);
    }
};
</script>