import AllPackagesView from './views/AllPackagesView.vue';
import CurrencyPurchaseSuccessView from './views/CurrencyPurchaseSuccessView.vue';

const routes = [
    {
        path: '/currency-packages/all',
        component: AllPackagesView,
        name: 'AllPackages',
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/currency-packages/success',
        component: CurrencyPurchaseSuccessView,
        name: 'Success',
        meta: {
            requiresAuth: true,
        },
    }
];

export default routes;