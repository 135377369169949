<template>
    <footer class="footer text-center text-white">
        <!-- Grid container -->
        <div class="container p-4">
            <!-- Section: Social media -->
            <section class="mb-4">
                <!-- Facebook -->
                <a class="social-btn" href="#!" role="button">
                    <i class="fa-brands fa-facebook"></i>
                </a>

                <!-- Twitter -->
                <a class="social-btn" href="#!" role="button">
                    <i class="fa-brands fa-x-twitter"></i>
                </a>

                <!-- Google -->
                <a class="social-btn" href="#!" role="button"><i class="fa fa-google"></i></a>

                <!-- Instagram -->
                <a class="social-btn" href="#!" role="button"><i class="fa fa-instagram"></i></a>

            </section>
            <!-- Section: Form -->
            <section class="">
                <form action="">
                    <!--Grid row-->
                    <div class="row d-flex justify-content-center">
                        <!--Grid column-->
                        <div class="col-auto">
                            <p class="pt-2">
                                <strong>Sign up for our newsletter</strong>
                            </p>
                        </div>
                        <!--Grid column-->

                        <!--Grid column-->
                        <div class="col-md-5 col-12">
                            <!-- Email input -->
                            <div class="form-outline form-white mb-4">
                                <input type="email" id="form5Example21" class="form-control bg-dark" />
                                <label class="form-label" for="form5Example21">Email address</label>
                            </div>
                        </div>
                        <!--Grid column-->

                        <!--Grid column-->
                        <div class="col-auto">
                            <!-- Submit button -->
                            <button type="submit" class="btn search-btn mb-4">
                                Subscribe
                            </button>
                        </div>
                        <!--Grid column-->
                    </div>
                    <!--Grid row-->
                </form>
            </section>

            <!-- Section: Links -->
            <section class="">
                <!--Grid row-->
                <div class="row">
                    <!--Grid column-->
                    <div class="col-lg-4 col-md-6 mb-3 mb-md-0">
                        <ul class="list-unstyled mb-0">
                            <li>
                    <RouterLink to="/game-rules" class="text-decoration-none">
                                <a class="text-white">Game Rules</a><br />
                    </RouterLink>
                            </li>
                            <li>
                                <a href="#!" class="text-white">Terms & Conditions</a><br />
                            </li>
                        </ul>
                    </div>
                    <!--Grid column-->

                    <!--Grid column-->
                    <div class="col-lg-4 col-md-6 mb-3 mb-md-0">
                        <ul class="list-unstyled mb-0">
                            <li>
                                <a href="#!" class="text-white">Privacy Policy</a><br />
                            </li>
                            <li><a href="#!" class="text-white">Help</a><br /></li>
                        </ul>
                    </div>
                    <!--Grid column-->

                    <!--Grid column-->
                    <div class="col-lg-4 col-md-6 mb-3 mb-md-0">
                        <ul class="list-unstyled mb-0">
                            <li><a href="#!" class="text-white">Contact Us</a><br /></li>
                            <li><a href="#!" class="text-white">Invite Friends</a><br /></li>
                        </ul>
                    </div>
                    <!--Grid column-->
                    <!--Grid column-->
                </div>
                <!--Grid row-->
            </section>
            <!-- Section: Links -->
            <div class="text-center p-3">
                <p class="text-white">
                    © 2023-2024 Copyright:
                    <a class="text-white	" href="/"> www.Djolo.Win</a>
                </p>
            </div>
        </div>
        <!-- Grid container -->
        <!-- Copyright -->
        <!-- Copyright -->
    </footer>
</template>