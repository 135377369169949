import instance from "@/baseAPI";

const settingsApi = {
  changeUserProfileData: data => instance.put ('account/user-profile/', data),
  userProfile : () => instance.get ('account/user-profile'),
  notificationPreferences : () => instance.get ('notification/preferences'),
  sendReferralCode: data => instance.post ('account/referral-code/', data),
  referralCount: () => instance.get ('account/referral-count/'),
};

export default settingsApi;