<template>
    <!-- Notification Dropdown -->
    <div class="offcanvas offcanvas-start canvas-bg" tabindex="-1" id="notificationCanvas"
        aria-labelledby="notificationCanvasLabel" v-if="showNotificationsDropdown">
        <div class="offcanvas-header">
            <h4 class="offcanvas-title text-white my-2" id="notificationCanvasLabel">Notifications</h4>
            <button type="button" class="close-btn" data-bs-dismiss="offcanvas" aria-label="Close">
                <i class="fas fa-times"></i>
            </button>
        </div>
        <button v-if="containUnread" type="button" class="btn btn-danger" @click="markAllAsRead()">
            Marquer tout comme lu
        </button>
        <div class="notif-canvas-ctn" style="max-height: 100%; overflow-y: auto; cursor: pointer;">
            <div class="notif mb-1 text-decoration-none" v-for="notification in notifications" :key="notification.id"
                @click="selectNotification(notification)" data-bs-toggle="modal" data-bs-target="#notificationModal">
                <div class="notif-subject">
                    {{ notification.subject }}
                </div>
                <small class="text-secondary sm-date"> ({{ formatRelativeTime(notification.date_sent)
                }})</small>
                <div v-if="notification.date_read === null" class="unread-dot"></div>
                <br>
                <hr class="dropdown-divider">
            </div>
        </div>
        <a class="all-notif-btn py-4" href="/notification/inbox">
            Voir toutes les notifications
        </a>
    </div>
    <div class="modal fade" id="notificationModal" tabindex="-1" aria-labelledby="notificationModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content notif-detail-bg" v-if="selectedNotification">
                <div class="modal-header">
                    <h5 class="bold text-center" id="notificationModalLabel">{{ selectedNotification.subject }}</h5>
                    <button type="button" class="close-btn" data-bs-dismiss="modal" aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <p class="text-white regular">{{ selectedNotification.body }}</p>
                    <small class="text-white-50 regular">{{ formatRelativeTime(selectedNotification.date_sent) }}</small>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">Fermer</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
@import '../../../assets/css/notification.css';

.btn-cancel {
    background-color: #dc353583;
    border-color: #dc3545;
    border-radius: 25px;
    color: #ffffff;
    transition: all 0.3s ease-in-out;
}

.btn-cancel:hover {
    background-color: #dc3545;
    border-color: #dc3545;
    border-radius: 15px;
    color: #000000;
}
.canvas-bg {
    background-color: #040404;
    color: #fff;
    width: 400px;
}

.modal-content {
    background-color: #040404;
    color: #fff;
    box-shadow: 0 0 10px #ffffff;
}
.modal-header {
    border-bottom: 1px solid #203b33;
}

.modal-footer {
    border-top: 1px solid #203b33;
}
</style>

<script setup>
import { ref, onMounted, computed, onBeforeUnmount } from 'vue'; // Import ref and onMounted
import { useNotificationStore } from '@/stores/notification';
import { useAccountStore } from '@/stores/account';
import { formatRelativeTime } from '@/utils/dateFormatter';
import Pusher from 'pusher-js';


const notificationStore = useNotificationStore();
const accountStore = useAccountStore();

const pusher_key = process.env.VUE_APP_PUSHER_KEY;
const pusher = new Pusher(pusher_key, {
    cluster: 'us2',
});
Pusher.logToConsole = true;
const selectedNotification = ref(null);
const showNotificationsDropdown = ref(true);
const notifications = computed(() => {
    return notificationStore.getInboxData;
});

const userId = computed(() => {
    return accountStore.authData.userId;
});
const containUnread = computed(() => {
    return notificationStore.getInboxData.some((notification) => {
        return notification.date_read === null;
    });
});

const channel = pusher.subscribe(`user_channel_${userId.value}`);
channel.bind('new_notification_message', function (data) {
    notificationStore.addNotification(data.message);
});

onMounted(() => {
    getInboxNotifications();
});

onBeforeUnmount(() => {
});

function selectNotification(notification) {
    selectedNotification.value = notification;
    if (notification.date_read === null) {
        markAsRead(notification);
    }
}

async function getInboxNotifications() {
    try {
        await notificationStore.pullInboxData();
    } catch (error) {
        console.log(error);
    }
}
async function markAsRead(notification) {
    try {
        await notificationStore.markAsRead(notification.uuid);
    } catch (error) {
        console.log(error);
    }
}

async function markAllAsRead() {
    try {
        await notificationStore.markAllAsRead();
    } catch (error) {
        console.log(error);
    }
}

</script>