<template>
    <div v-if="UserDailyTasks.length > 0" class="container assistant-ctn">
        <h5 class="bold text-dark">Assistant</h5>
        <div class="manager-ctn">
            <ul class="list-group">
                <div class="list-group-item regular" v-for="task in UserDailyTasks" :key="task.task_name">
                    <RouterLink v-if="!task.is_completed" :to="getTaskLink(task.task_name)" class="task-item-link">
                        <!-- Content inside the RouterLink -->
                        {{ task.description }}
                        <span class="text-white bold float-right">{{ task.points }} <i class="fa fa-ils"></i></span>
                    </RouterLink>
                    <div v-else>
                        {{ task.description }}
                        <span class="text-success bold float-right"><i class="fas fa-check-square"></i></span>
                    </div>
                </div>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useProfileStore } from '@/stores/profile';
import { useAccountStore } from '@/stores/account';

const profileStore = useProfileStore();
const accountStore = useAccountStore();

const UserDailyTasks = computed(() => profileStore.getDailyTasks);

async function getDailyTasks() {
    try{
        const response = await profileStore.fetchUserDailyTasks();
    } catch (error) {
        console.log(error);
    }
}

function getTaskLink(taskName) {
    // Logic to generate the dynamic link based on task name
    if (taskName === 'buy_card_on_sale') {
        return '/card-sales';
    }
    else if (taskName === 'sell_card_by_direct_sale') {
        return '/profile/cards';
    } else if (taskName === 'bid_on_card') {
        return '/auctions';
    } else if (taskName === 'place_card_on_auction') {
        return '/profile/cards';
    } else if (taskName === 'win_auction') {
        return '/auctions';
    } else if (taskName === 'sell_card_by_auction') {
        return '/profile/cards';
        // Add more conditions or use a map/object to match task names to routes
    }
}

onMounted(async () => {
    await getDailyTasks();
});

</script>

<style scoped>
.container {
    background-color: rgba(26, 26, 26, 0.49);
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    align-items: center;
}

.manager-ctn {
    background-color: #1d1d1d;
    color: #fff;
    border-radius: 15px;
    padding: 10px;
    margin: 10px;
}

.assistant-ctn {
    background: linear-gradient(rgba(26, 26, 26, 0.276), rgba(26, 26, 26, 0.362)),
        url(../../../assets/light-banner.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.list-group-item {
    background-color: #1d1d1d;
    color: #fff;
    border: 0;
    padding: 12px;
}

.list-group-item:hover {
    background-color: #2a2a2a;
    color: #fff;
    border: 0;
    padding: 12px;
}

.list-group {
    padding: 5px;
}

.task-item-link {
    color: #fff;
    text-decoration: underline;
    text-decoration-skip-ink: none; /* Prevents underlines from being affected by ink spread */
    text-underline-position: under; /* Ensures the underline is under the text */
    text-decoration-thickness: 0.1em; /* Adjust the thickness of the underline */
    text-decoration-color: #fff; /* Change the color of the underline if needed */
}


.float-right {
    font-size: 19px;
    float: right;
}

@media (max-width: 768px) {

    .list-group-item {
        font-size: 14px;
    }

    .float-right {
        font-size: 14px;
    }

    .container {
        padding: 10px;
    }

    .assistant-ctn {
        padding: 10px;
    }
}
</style>