<template>
    <div class="game-rules">
        <div class="text-center">
            <img class="small-logo"
                src="../assets/djolowin/djolo-win-high-resolution-logo-white-on-black-background-removebg.png"
                alt="DjoloWin Logo" />
        </div>
        <h1>DjoloWin: Game Rules</h1>
        <p>
            <em>Last update: November 22, 2023</em>
        </p>
        <p>
            These Game Rules are effective as of the date stated above. We may update these Game Rules
            from time to time. If we do, we will notify you of any material changes to these Game Rules by posting a notice
            on the Site and/or by sending you an email. Your continued participation in the Game after we post any such
            changes or notify you of any material changes to these Game Rules shall constitute your acceptance of the
            updated Game Rules. If you do not wish to accept the updated Game Rules, you may terminate your participation
            in the Game by closing your Account.
        </p>
        <p>
            DjoloWin ("Collection Game") is organized by DjoloWin, LLC, a company incorporated under the laws of the State
            of Texas, USA
            (“DjoloWin”, “we”, “us” or “our”). Participation in the Collection Game is subject to these Game Rules, the
            Terms and
            Conditions, the Privacy Policy and any applicable Additional Terms. Capitalized terms not defined here will have
            the meaning
            set forth in the Terms and Conditions.
        </p>

        <h2>
            1. The Collection Game
        </h2>
        <p>
            The Collection Game gives Users the opportunity to build collections of digital cards (“Cards”) in albums
            ('Master Teams') for Users to
            win prizes and rewards subject to the conditions set forth in these Game Rules.
        </p>
        <h4>
            1.1 Collection Requirements
        </h4>
        <p>
            Where indicated on the Site, an authorized team/club (“Team”) will have one (1) Master Team per season and
            rarity level. For a Card to
            contribute to a Master Team, it must be of the same rarity level as the Master Team. For example, a Common Card
            can only contribute to a
            Common Master Team. A Card can only contribute to one (1) Master Team. A Card can only contribute to a Master
            Team if it is not already
            contributing to a Master Team. Only one(1) Card per individual player is valid per Master Team ('Master Team
            Requirements'). Additional
            Cards of the same player will not contribute to the Master Team. For example, if you have two (2) Common Cards
            of the same player, only
            one (1) of those Common Cards will contribute to the Common Master Team. The other Common Card will not
            contribute to the Common Master
            Team. The Master Team Requirements may vary at the discretion of DjoloWin and will be communicated in advance on
            the Site. Djolowin
            reserves the right, at its sole discretion, to determine whether a Card meets the Master Team Requirements,
            pursuant to the applicable
            Master Team Requirements.
        </p>
        <h4>
            1.2 Scoring Points
        </h4>
        <h5>
            1.2.1 Player Card Score
        </h5>
        <p>
            Each Card validly contributed to a Master Team will have a score (“Player Card Score”) attributed to it based on
            the scoring system available
            on the Website.
        </p>
        <h5>
            1.2.2 Master Team Score
        </h5>
        <p>
            In addition to the Player Card Score, each Master Team will have a score (“Master Team Score”) attributed to it
            based on the scoring system
            available on the Website. If you have two (2) or more Cards of the same player that would contribute to the same
            Master Team Requirements,
            the relevant Card with the smallest serial number will contribute to the Master Team Score (subject to change
            soon).
        </p>
        <h4>
            1.3 Play or Trade
        </h4>
        <p>
            A particular Card cannot simultaneously contribute to a Master Team Score and be offered for sale or auction on
            the Marketplace. If you
            have already listed a Card for sale or auction on the Marketplace, that same Card cannot contribute to a Master
            Team Score until it is
            removed from the Marketplace. if a particular Card already contributes to a Master Team Score, and you decide to
            list that Card on the Marketplace,
            that same Card will immediately lose its Player Card Score thus impacting the corresponding Master Team Score.
        </p>
        <h4>
            1.4 Eligibility
        </h4>
        <p>
            The Collection Game is open to all natural persons who are at least 18 years old (or the age of majority in
            their jurisdiction of residence), who
            have a valid DjoloWin account and have verified their email address. You are not eligible to participate in the
            Collection Game if you are a
            resident of a country where the Collection Game is prohibited by law, decree, regulation, treaty or
            administrative act. The Collection Game is
            void where prohibited by law.
        </p>
        <h2>
            2. Rewards
        </h2>
        <h4>
            2.1 Wallet Rewards
        </h4>
        <p>
            After completing a Master Team, you will receive a reward ("DJOBA Reward") in the form of a digital wallet
            credit ("Wallet Reward") in the amount
            of the reward indicated on the Website. The Wallet Reward will be credited to your Wallet within 24 hours of
            completing the Master Team. The
            Wallet Reward will be credited to your Wallet in the form of digital currency ("DJOBA") that can be used to
            purchase Cards or redeem other
            rewards on the Website. The DJOBA Reward can be used by the Users however they wish. The DJOBA Reward is not
            transferable and cannot be
            exchanged with other Users.
        </p>
    </div>
</template>

<style scoped>
.game-rules {
    margin: 0 auto;
    width: 70%;
    color: #fff;
}

.game-rules p {
    color: #fff;
}

.small-logo {
    width: 15rem;
}</style>