<template>
    <div class="pack-detail-ctn">
        <h1>{{ pack.name }}</h1>
        <h3>
            Congratulations, Below are the cards you won in this pack!
        </h3>
        <div class="pack-cards-ctn">
            <div class="card-el" v-for="card in pack.cards" :key="card.id">
                <div role="button" data-bs-toggle="modal" data-bs-target="#cardDetailsModal" @click="setPropsCard(card)">
                    <PlayerCardinRows :playercard="card" class="test" />
                </div>
                <CardDetail :playercard="PropsCard" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router';
import PlayerCardinRows from '../components/PlayerCardinRows.vue';
import CardDetail from '../components/CardDetail.vue';

import { useCardStore } from '@/stores/card';

const cardStore = useCardStore();
const route = useRoute();

const PropsCard = computed(() => {
    return cardStore.getSingleCardData;
});
const pack = computed(() => cardStore.getPackData);

async function fetchPackDetail(uuid) {
    await cardStore.pullPackDetails(uuid);
}
function setPropsCard(card) {
    cardStore.setSingleCardData(card);
}

onMounted(() => {
    fetchPackDetail(route.params.uuid);
});
</script>

<style scoped>
.pack-detail-ctn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.pack-cards-ctn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.card-el {
    margin: 10px;
}

.test {
    box-shadow: 0 0 20px 0 rgb(226, 143, 0);
}
</style>