import {defineStore} from 'pinia';
import cardApi from '@/modules/card/api';

export const useCardStore = defineStore ('card', {
  state: () => ({
    packCategories: [],
    packData: [],
    cardData: [],
    ownedCardData: [],
    singleCardData: [],
    singleSaleData: null,
    salesData: [],
    isOwnedbyUser: null,
    dataLoading: false,
    nextPage: null,
    totalCount: null,
    teamCollections: [],
    totalCollections: null,
    singleTeamCollection: [],
    ownedCardinCollection: [],
    collectionProgress: null,
    weeklyFeaturedTeamData: null,
    recentUserSubmission: null,
    featuredCards: [],
    userFeaturedCards: [],
    selectedUserFeaturedCard: null,
  }),

  actions: {
    addCardToUserFeaturedCards (card) {
      this.userFeaturedCards.push (card);
    },
    removeCardFromUserFeaturedCards (card) {
      this.userFeaturedCards = this.userFeaturedCards.filter (
        c => c.id !== card.id
      );
    },
    clearUserFeaturedCards () {
      this.userFeaturedCards = [];
    },
    setPackData (data) {
      this.packData = data;
    },
    clearPackData () {
      this.packData = [];
    },
    setPackCategories (data) {
      this.packCategories = data;
    },
    clearPackCategories () {
      this.packCategories = [];
    },
    setRecentUserSubmission (data) {
      this.recentUserSubmission = data;
    },
    clearRecentUserSubmission () {
      this.recentUserSubmission = null;
    },
    removeSaleFromList (id) {
      this.salesData = this.salesData.filter (sale => sale.id !== id);
    },
    setProgress (data) {
      this.collectionProgress = data;
    },
    setSingleSaleData (data) {
      this.singleSaleData = data;
    },
    clearSingleSaleData () {
      this.singleSaleData = null;
    },
    setTeamCollections (data) {
      this.teamCollections = data;
    },
    setSelectedUserFeaturedCard (data) {
      this.selectedUserFeaturedCard = data;
    },
    clearSelectedUserFeaturedCard () {
      this.selectedUserFeaturedCard = null;
    },
    setOwnedCardsinCollection (data) {
      this.ownedCardinCollection = data;
    },
    setSalesData (data) {
      this.salesData = data;
    },
    clearSalesData () {
      this.salesData = [];
    },
    setAllCardData (data) {
      this.cardData = data;
    },
    setOwnedCardData (data) {
      this.ownedCardData = data;
    },
    setSingleTeamCollectionData (data) {
      this.singleTeamCollection = data;
    },
    setSingleCardData (data) {
      this.singleCardData = data;
    },
    setLatestWeeklyFeaturedTeam (data) {
      this.weeklyFeaturedTeamData = data;
    },
    clearLatestWeeklyFeaturedTeam () {
      this.weeklyFeaturedTeamData = null;
    },
    setFeaturedCards (data) {
      this.featuredCards = data;
    },
    clearFeaturedCards () {
      this.featuredCards = [];
    },
    clearSingleCardData () {
      this.singleCardData = [];
    },
    setIsOwned (data) {
      this.isOwnedbyUser = data;
    },
    setNextPage (data) {
      this.nextPage = data;
    },
    clearNextPage () {
      this.nextPage = null;
    },
    setTotalCount (data) {
      this.totalCount = data;
    },
    setTotalCollections (data) {
      this.totalCollections = data;
    },
    changeCardDetails (data) {
      this.cardData = data;
    },
    addMoreCards (newCards) {
      this.cardData = [...this.cardData, ...newCards];
    },
    addMoreSales (newSales) {
      this.salesData = [...this.salesData, ...newSales];
    },

    async pullAllSales (filters) {
      try {
        const response = await cardApi.obtainAllSales (filters);
        if (response && response.data && response.status === 200) {
          this.setSalesData (response.data.results);
          this.setTotalCount (response.data.count);
          if (response.data.next !== null) {
            this.setNextPage (response.data.next);
          } else {
            this.clearNextPage ();
          }
        }
      } catch (error) {
        console.error ('Error in getAllSales action:', error);
        throw error;
      }
    },
    async pullpackCategories () {
      try {
        const response = await cardApi.obtainPackCategories ();
        if (response && response.data && response.status === 200) {
          this.setPackCategories (response.data);
        }
      } catch (error) {
        console.error ('Error in getPackCategories action:', error);
        throw error;
      }
    },
    async pullPackDetails (uuid) {
      try {
        const response = await cardApi.obtainPackDetails (uuid);
        if (response && response.data && response.status === 200) {
          this.setPackData (response.data);
        }
      } catch (error) {
        console.error ('Error in getPackDetails action:', error);
        throw error;
      }
    },
    async pullAllCards (filters) {
      try {
        const response = await cardApi.obtainAllCards (filters);
        console.log (response);
        if (response && response.data.results && response.status === 200) {
          this.setAllCardData (response.data.results);
          this.setTotalCount (response.data.count);
          if (response.data.next !== null) {
            this.setNextPage (response.data.next);
          } else {
            this.clearNextPage ();
          }
        }
      } catch (error) {
        console.error ('Error in getAllCards action:', error);
        throw error;
      }
    },
    async pullLatestWeeklyFeaturedTeam () {
      try {
        const response = await cardApi.obtainLatestFeaturedCards ();
        if (response && response.data && response.status === 200) {
          this.setLatestWeeklyFeaturedTeam (response.data.featured_team);
          this.setFeaturedCards (response.data.featured_team.cards);
          this.setRecentUserSubmission (response.data.recent_user_submission);
        }
        return response;
      } catch (error) {
        console.error ('Error in getLatestWeeklyFeaturedTeam action:', error);
      }
    },
    async pullAllTeamCollections () {
      try {
        const response = await cardApi.obtainTeamCollections ();
        if (response && response.data && response.status === 200) {
          this.setTeamCollections (response.data.results);
          this.setTotalCollections (response.data.count);
          if (response.data.next) {
            this.setNextPage (response.data.next);
          }
        }
        return response;
      } catch (error) {
        console.error ('Error in getTeamCollections action:', error);
        throw error;
      }
    },
    async pullOwnedCards (filters) {
      try {
        const response = await cardApi.obtainOwnedCards (filters);
        if (response && response.data && response.status === 200) {
          this.setOwnedCardData (response.data);
          this.setTotalCount (response.data.length);
        }
      } catch (error) {
        console.error ('Error in getOwnedCards action:', error);
      }
    },
    async pullSingleCard (slug) {
      try {
        const response = await cardApi.obtainSingleCard (slug);
        if (response && response.data && response.status === 200) {
          this.setSingleCardData (response.data.data);
          return response;
        }
      } catch (error) {
        console.error ('Error in getSingleCard action:', error);
        throw error;
      }
    },

    async submitLatestWeeklyFeaturedTeam (data) {
      try {
        const response = await cardApi.submitLatestFeaturedCards (data);
        if (response && response.data && response.status === 201) {
          // this.setLatestWeeklyFeaturedTeam (response.data);
          // this.setFeaturedCards (response.data.cards);
        }
        return response;
      } catch (error) {
        console.error (
          'Error in submitLatestWeeklyFeaturedTeam action:',
          error
        );
        throw error;
      }
    },

    async createSale (data) {
      try {
        const response = await cardApi.createSale (data);
        if (response && response.data && response.status === 201) {
          return response;
        }
      } catch (error) {
        console.error ('Error in createSale action:', error);
        throw error;
      }
    },

    async pullSingleTeamCollection (slug) {
      try {
        const response = await cardApi.obtainSingleTeamCollection (slug);
        if (response && response.data && response.status === 200) {
          this.setSingleTeamCollectionData (response.data.collection);
          this.setOwnedCardsinCollection (response.data.owned_cards);
        }
        return response;
      } catch (error) {
        console.error ('Error in getSingleTeamCollection action:', error);
        throw error;
      }
    },
    async editSingleCard (slug, data) {
      try {
        const response = await cardApi.editSingleCard (slug, data);
        if (response && response.data && response.status === 200) {
          this.setSingleCardData (response.data);
        }
        return response;
      } catch (error) {
        console.error ('Error in editSingleCard action:', error);
        throw error;
      }
    },
    async updateCardDetailsFromOwnedList (data) {
      const cardIndex = this.ownedCardData.findIndex (
        card => card.id === data.id
      );
      this.ownedCardData[cardIndex] = data;
    },

    async purchaseCard (id) {
      try {
        const response = await cardApi.purchaseSingleCard (id);
        if (response && response.data && response.status === 200) {
          this.removeSaleFromList (id);
        }
        return response;
      } catch (error) {
        console.error ('Error in purchaseCard action:', error);
        throw error;
      }
    },
    async purchasePack (slug) {
      try {
        const response = await cardApi.purchaseRandomPack (slug);
        if (response && response.data && response.status === 200) {
          return response;
        }
      } catch (error) {
        console.error ('Error in purchasePack action:', error);
        throw error;
      }
    },
    async checkAuction (card_id) {
      try {
        const response = await cardApi.checkCardinAuction (card_id);
        if (response && response.data && response.status === 200) {
          return response;
        }
      } catch (error) {
        throw error;
      }
    },
  },
  getters: {
    getAllCardsData () {
      return this.cardData;
    },
    getAllSalesData () {
      return this.salesData;
    },
    getAllTeamCollectionsData () {
      return this.teamCollections;
    },
    getFeaturedCards () {
      return this.featuredCards;
    },
    getLatestWeeklyFeaturedTeam () {
      return this.weeklyFeaturedTeamData;
    },
    getNextPage () {
      return this.nextPage;
    },
    getPackCategories () {
      return this.packCategories;
    },
    getPackData () {
      return this.packData;
    },
    getOwnedCardsData () {
      return this.ownedCardData;
    },
    getOwnedCardinCollection () {
      return this.ownedCardinCollection;
    },
    getProgress () {
      return this.collectionProgress;
    },
    getSelectedUserFeaturedCard () {
      return this.selectedUserFeaturedCard;
    },
    getSingleCardData () {
      return this.singleCardData;
    },
    getSingleSaleData () {
      return this.singleSaleData;
    },
    getSingleTeamCollectionData () {
      return this.singleTeamCollection;
    },
    getRecentUserSubmission () {
      return this.recentUserSubmission;
    },
    getTotalCount () {
      return this.totalCount;
    },
    getTotalCollections () {
      return this.totalCollections;
    },
    getUserFeaturedCards () {
      return this.userFeaturedCards;
    },
  },
});
