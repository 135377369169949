<template>
    <div class="card-footer">
        <!-- Price Section -->
        <CardDetailsMobile :card="playercard" />
        <div class="footer-text-ctn">
            <div class="text-white sale-price" v-if="playercard.for_sale || playercard.is_in_auction" role="button" data-bs-toggle="tooltip"
                data-bs-placement="bottom" title="Card on Sale">
                {{ formatNumber(playercard.price) }} <i class="fa fa-ils"></i>
                <!-- Auction Icon -->
                <div class="text-white ftr-icn" v-if="playercard.active_auction_id !== null || playercard.is_in_auction"
                    role="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Carte en vente aux enchères">
                    <span class="auction-icon">A</span>
                </div>

                <!-- Sale Icon -->
                <div class="text-white ftr-icn" v-if="playercard.for_sale" role="button" data-bs-toggle="tooltip"
                    data-bs-placement="bottom" title="Carte en vente directe">
                    <span class="sale-icon">S</span>
                </div>

                <!-- Locked Icon -->
                <div class="text-white ftr-icn" v-if="playercard.is_locked" role="button" data-bs-toggle="tooltip"
                    data-bs-placement="bottom" title="Carte verrouillée">
                    <span class="locked-icon"><i class="fa-solid fa-lock"></i></span>
                </div>
            </div>
            <div class="seller-name">
                Appartient à: {{ playercard.owner_name }}
            </div>
        </div>
        <!-- Icons Section -->
    </div>
</template>

<style scoped>

.seller-name {
    font-size: 14px;
    color: #b1b1b1ad;
}


.sale-price {
    font-size: 1.25rem;
    font-weight: bold;
    color: #fff;
    margin: 0;

}

.ftr-icn {
    padding-top: 1px;
    float: right;
    margin-right: 2px;
}

.locked-icon {
    width: 25px;
    height: 25px;
    padding-top: 1px;
    margin-right: 2px;
}

.sale-icon {
    background-color: #ff8000;
    border-radius: 50%;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    padding: 2px 6px;
    margin-right: 4px;
}

.auction-icon {
    background-color: #008095;
    border-radius: 50%;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    padding: 2px 6px;
    margin-right: 4px;
}

/* .footer-text-ctn {
    width: 100%;
    text-align: center;
    margin-top: 5px;
    font-size: 1rem;
    font-weight: bold;
} */
</style>

<script setup>
import { computed } from 'vue';
import formatNumber from '@/utils/numberFormatter';
import CardDetailsMobile from './CardDetailsMobile.vue';


const props = defineProps({
    playercard: {
        type: Object,
        required: true,
    },
});

const playercard = computed(() => {
    return props.playercard;
});
</script>

