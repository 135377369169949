<template>
    <div>
        <div v-if="this.leagueDetail">
            <h1>{{ leagueDetail.name }}</h1>
            <h5>{{ leagueDetail.country }}</h5>
            <div v-if="leagueDetail.teams">
                <h3>Teams</h3>
                <table class="table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="team in leagueDetail.teams" :key="team">
                            <td>{{ team.rank }}</td>
                            <router-link :to="'/football/team/' + team.slug">
                                <td><img :src="team.logo" alt="team logo" /> {{ team.name }}</td>
                            </router-link>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            leagueDetail: null,
        };
    },
    mounted() {
        this.getLeagueDetail();
    },
    computed: {
        ...mapGetters('football', {
            getterLeagueDetail: 'getLeagueDetail',
        }),
    },
    methods: {
        ...mapActions('football', {
            actionGetLeagueDetailApi: 'getSingleLeague',
        }),
        async getLeagueDetail() {
            try {
                const response = await this.actionGetLeagueDetailApi(this.$route.params.slug);
                this.leagueDetail = this.getterLeagueDetail;
                console.log(this.leagueDetail);
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>
```