import {defineStore} from 'pinia';
import settingsApi from '@/modules/settings/api';

export const useSettingsStore = defineStore ('settings', {
  state: () => ({
    userProfileData: '',
    notificationPreferencesData: '',
    referralCount: '',
  }),

  actions: {
    setUserProfileData (data) {
      this.userProfileData = data;
    },
    clearUserProfileData () {
      this.userProfileData = '';
    },
    setReferralCount (data) {
      this.referralCount = data;
    },
    clearReferralCount () {
      this.referralCount = '';
    },
    setNotificationPreferencesData (data) {
      this.notificationPreferencesData = data;
    },
    clearNotificationPreferencesData () {
      this.notificationPreferencesData = '';
    },

    async editUserProfile (payload) {
      try {
        const response = await settingsApi.changeUserProfileData (payload);
        // Handle the response and commit mutations as needed
        // For example:
        this.setUserProfileData (response.data);
        return response;
      } catch (error) {
        console.error ('Error in editUserProfile action:', error);
        // Handle errors if necessary
        throw error;
      }
    },

    async pullUserProfile () {
      try {
        const response = await settingsApi.userProfile ();
        // Handle the response and commit mutations as needed
        // For example:
        this.setUserProfileData (response.data);
        return response;
      } catch (error) {
        console.error ('Error in userProfile action:', error);
        // Handle errors if necessary
        throw error;
      }
    },

    async pullNotificationPreferences () {
      try {
        const response = await settingsApi.notificationPreferences ();
        // Handle the response and commit mutations as needed
        // For example:
        this.setNotificationPreferencesData (response.data);
        return response;
      } catch (error) {
        console.error ('Error in notificationPreferences action:', error);
        // Handle errors if necessary
        throw error;
      }
    },
    async submitReferralCode (payload) {
      try {
        const response = await settingsApi.sendReferralCode (payload);
        return response;
      } catch (error) {
        console.error ('Error in submitReferralCode action:', error);
        // Handle errors if necessary
        throw error;
      }
    },
    async pullReferralCount () {
      try {
        const response = await settingsApi.referralCount ();
        // Handle the response and commit mutations as needed
        // For example:
        this.setReferralCount (response.data.referred_users_count);
        return response;
      } catch (error) {
        console.error ('Error in referralCount action:', error);
        // Handle errors if necessary
        throw error;
      }
    }
  },

  getters: {
    getUserProfileData () {
      return this.userProfileData;
    },
    getMaxReferralCount () {
      return this.userProfileData.max_referral_count;
    },
    getNotificationPreferencesData () {
      return this.notificationPreferencesData;
    },
    getReferralCount () {
      return this.referralCount;
    }
  },
});
