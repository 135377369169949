<template>
	<div class="container mt-4">
		<FlashMessage />
		<GoBack />
		<LoadingSpinner v-if="loading" :loading="loading" />
		<div v-else>
			<div v-if="auction" class="d-flex flex-column flex-md-row">
				<!-- Left section for the image -->
				<div class="col-md-6 order-md-1">
					<!-- Place your image component here -->
					<!-- Example: <CardImage :imageSrc="auction.imageSrc" /> -->
					<AuctionImage class="shadw" :auction="auction" />
					<div class="text-white">
						<h3 v-if="auction.current_bid > auction.starting_price"> {{ formatNumber(auction.current_bid) }} <i class="fa fa-ils"></i></h3>
						<h3 v-else >{{ formatNumber(auction.starting_price) }} <i
								class="fa fa-ils"></i>
						</h3>
					</div>
					<div v-if="!auctionHasExpired">
						<div v-if="isUserAllowedToBid">
							<button class="pill-btn-bid" @click="toggleBidCollapse">Enchérir
							</button>
						</div>
						<div v-else-if="userID === auction.seller">
							<button class="pill-btn-nobid" disabled>
								C'est ta vente aux enchères
							</button>
						</div>
						<div v-else-if="userID === auction.highest_bidder">
							<button class="pill-btn-nobid" disabled>
								Tu es le plus offrant
							</button>
						</div>
						<!-- Collapsible bid input -->
						<div class="collapse" :class="{ 'show': isBidCollapseOpen }">
							<form @submit.prevent="bidOnAuction">
								<div class="form-group">
									<input type="number" class="form-control-bid" v-model="bid" id="bid" required :min="bid"
										step="100">
								</div>
								<button type="submit" class="btn btn-primary" :disabled="!isBidValid">Enchérir</button>
							</form>
						</div>
					</div>
					<div v-else>
						<h5>
							<span v-if="auction.sold === false" class="text-warning">
								Cette vente aux enchères est terminée
							</span>
							<span v-else class="text-success">
								Cette vente aux enchères est terminée et a été vendue
							</span>
						</h5>
					</div>
					<!-- <img src="https://via.placeholder.com/300x400" alt="Card Image" class="img-fluid" /> -->
					<div v-if="connected_users" class="bid-watchers-ctn mt-3">
						<h5 class="text-white">
							Personnes connectées:
						</h5>
						<p v-for="user in connected_users" :key="user.id" class="bid-watcher">- {{ user }}</p>
					</div>
				</div>

				<!-- Right section for auction details -->
				<div class="mt-5 col-md-6 text-white order-md-2 text-white">
					<div class="auction-title">
						{{ auction.card_season }} - <span class="r-badge" :class="badgeClass">
							{{ capitalizeText(auction.rarity_name) }}
						</span>
						<br>
						<router-link class="text-decoration-none" @click="closeAndSwitch" type="button"
									:to="'/football/player/' + auction.card.player_slug">
									<div class="card-name">
										{{ auction.player_name }}
									</div>
								</router-link>
						<hr class="bg-white">
						<p class="text-white">Vendeur: {{ auction.seller_name }}</p>
					</div>
					<AuctionAlerts />
					<ul class="list-group list-group-flush mb-2">
						<li class="list-group-item auction-details-bg bid-amount">
							<span v-if="auction.current_bid == 0">Prix de départ: {{ formatNumber(auction.starting_price)
							}}
								<i class="fa fa-ils"></i></span>
							<span v-else>Offre actuelle: 
								{{ formatNumber(auction.current_bid) }} <i
									class="fa fa-ils"></i></span>
						</li>
						<li v-if="auction.highest_bidder" class="list-group-item auction-details-bg">
							<div v-if="auction.highest_bidder == userID">
								<p class="text-success bold">Tu es le plus offrant</p>
							</div>
							<div v-else>
								<p class="text-secondary bold">Gagnant actuel: {{ auction.highest_bidder_name }}</p>
							</div>
						</li>
						<li class="list-group-item auction-details-bg">
							<p v-if="!auctionHasExpired" class="text-white"
								:class="{ 'text-danger': countdownInSeconds < 300 }">
								{{ formattedCountdown }} ({{ endTime }})
							</p>
							<span class="text-info">{{ auction.number_of_bids }} offres</span>
						</li>
						<li class="list-group-item auction-details-bg" v-if="auction.sold">
							<p class="text-success bold">Vendue</p>
						</li>
					</ul>
					<div v-if="bidList.length > 0" class="bid-list-ctn">
						<h3 class="text-white mt-5">Liste des offres</h3>
						<div v-for="bid in bidList" :key="bid.id">
							<p class="text-white bid-item">{{ bid.bidder_id }} - {{ bid.amount }} <i class="fa fa-ils"></i> ({{
								bid.timestamp }})</p>
						</div>
					</div>
					<div v-if="auctionHasExpired">
						<h3 class="text-white mt-5"></h3>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.bid-amount span {
	font-size: 22px;
	color: #7fffd4;
	font-weight: 900;
}

.shadw {
	box-shadow: 0px 0px 10px 10px rgba(174, 248, 226, 0.72);
}
.bid-item {
	font-size: 18px;
	font-weight: 500;
	color: #ffffff;
	font-family: "Montserrat SemiBold", sans-serif;
}
.bid-list-ctn {
	text-align: center;
	border-radius: 10px;
	padding: 10px;
	max-height: 300px;
	overflow-y: scroll;

}
.form-control-bid {
	border-radius: 30px;
	border: 0px solid #ffffffad;
	background-color: #1f1f1f53;
	text-align: center;
	color: #7fffd4;
	padding: 5px 2px;
	margin-top: 5px;
	margin-bottom: 5px;
	transition: all 0.2s ease-in-out;
	font-size: 2.5rem;
	width: 50%;
	font-weight: bold;
	margin-top: 5px;
}

/* Customize the appearance of the step arrows */
.form-control-bid::-webkit-inner-spin-button,
.form-control-bid::-webkit-outer-spin-button {
	appearance: none;
	margin: 0;
	/* Remove default margin */
}

/* Style the step arrows (you can adjust these styles as needed) */
.form-control-bid::-webkit-inner-spin-button {
	background-color: #7fffd4;
	color: #000;
	border: 1px solid #7fffd4;
}

.form-control-bid::-webkit-outer-spin-button {
	background-color: #7fffd4;
	color: #000;
	border: 1px solid #7fffd4;
}

.bid-watchers-ctn {
	border: 1px solid #ffffff;
	border-radius: 10px;
	padding: 10px;
	max-height: 200px;
	overflow-y: scroll;
}

.bid-watcher {
	font-size: 16px;
	font-weight: 500;
	color: #ffffff;
	font-family: "Montserrat SemiBold", sans-serif;
}

.auction-details-bg {
	background-color: #131313;
	color: #ffffff;
	border: none;
}



.list-group-item {
	font-size: 18px;
	font-family: 'Montserrat R', sans-serif;
}

.auction-title {
	font-size: 20px;
	margin-bottom: 10px;
}
</style>



<script setup>
import { onMounted, computed, onBeforeUnmount, ref } from 'vue'; // Import ref and onMounted
import { useAccountStore } from '@/stores/account';
import { useAuctionStore } from '@/stores/auction';
import { useWebSocket } from '@/services/singleAuctionWebSockets';
import { useRoute } from 'vue-router'

import { parseDatetimestamp } from '@/utils/dateFormatter';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import AuctionImage from '../components/AuctionImage.vue';
import formatNumber from '@/utils/numberFormatter';
import AuctionAlerts from '../components/AuctionAlerts.vue';
import FlashMessage from '../../../components/FlashMessage.vue';
import GoBack from '../../../components/GoBack.vue';


// Variables
const accountStore = useAccountStore();
const auctionStore = useAuctionStore();
const auctionWs = useWebSocket();

const route = useRoute()

const endTime = ref(null);
const countdownInSeconds = ref(0);
const currentBid = ref(0);
const highestBidder = ref(null);
const isBidCollapseOpen = ref(false); // Initially closed


const userID = accountStore.authData.userId;
const userName = accountStore.authData.userName;
const loading = ref(false);


//  Computed properties
const auction = computed(() => auctionStore.getSingleAuctionData);
const bid = ref(0);
const bidList = computed(() => auctionStore.getBidList);
const capitalizeText = (text) => text.charAt(0).toUpperCase() + text.slice(1);
const isBidValid = computed(() => {
	return bid.value > auction.value.current_bid && bid.value > auction.value.starting_price;
});
const isUserAllowedToBid = computed(() => {
	return auction.value.seller !== userID && auction.value.highest_bidder !== userID;
});
const auctionHasExpired = computed(() => {
	return auction.value.end_time < new Date().toISOString();
});
const connected_users = computed(() => auctionStore.getConnectedUsers);
const badgeClass = computed(() => {
	return {
		'normal-badge': auction.value.card.rarity_name === 'Normal',
		'rare-badge': auction.value.card.rarity_name === 'Rare',
		'limited-badge': auction.value.card.rarity_name === 'Limited',
		'super-rare-badge': auction.value.card.rarity_name === 'Super Rare',
		'unique-badge': auction.value.card.rarity_name === 'Unique',
	};
});
const updateCountdown = () => {
	const now = new Date().getTime();
	if (!auction.value) {
		return;
	}
	const distance = new Date(auction.value.end_time).getTime() - now;
	countdownInSeconds.value = Math.floor(distance / 1000);
	if (distance < 0) {
		countdownInSeconds.value = 0;
	}
};
const formattedCountdown = computed(() => {
	const days = Math.floor(countdownInSeconds.value / 86400);
	const hours = Math.floor((countdownInSeconds.value % 86400) / 3600);
	const minutes = Math.floor((countdownInSeconds.value % 3600) / 60);
	const seconds = countdownInSeconds.value % 60;

	if (days > 0) {
		return `${days}d ${hours}h restantes`;
	} else if (hours > 0) {
		return `${hours}h restantes`;
	} else {
		return `${minutes}m ${seconds}s restantes`;
	}
});


onMounted(() => {
	getAuctionDetails();
	getBidList();
	setInterval(updateCountdown, 1000);
});

onBeforeUnmount(() => {
	auctionWs.closeWebSocketConnection();
});

// Methods
function toggleBidCollapse() {
	isBidCollapseOpen.value = !isBidCollapseOpen.value;
}

async function getAuctionDetails() {
	try {
		loading.value = true;
		const response = await auctionStore.getSingleAuction(route.params.id);
		if (response.status === 200) {
			endTime.value = parseDatetimestamp(auction.value.end_time);
			if (auction.value.current_bid > 0) {
				bid.value = auction.value.current_bid + 100;
			} else {
				bid.value = auction.value.starting_price;
			}
		}
	} catch (error) {
		accountStore.recordErrorMessages(error.data.detail);
		console.log(error);
	} finally {
		loading.value = false;
	}
};
async function getBidList() {
	try {
		await auctionStore.getAllBids(route.params.id);
	} catch (error) {
		accountStore.recordErrorMessages(error.data.message);
		console.log(error);
	}
};

async function bidOnAuction() {
	try {
		const response = await auctionStore.placeBidonAuction({
			auction: auction.value.id,
			amount: bid.value,
		});
		console.log(bid.value)
		isBidCollapseOpen.value = false; // Close the collapse after placing the bid
		if (response.status === 201) {
			accountStore.recordSuccessMessages(response.data.message);
			auctionWs.sendWebSocketMessage({
				type: 'new_bid_message',
				message: {
					auction: auction.value.id,
					card: auction.value.card.id,
					highest_bidder_name: userName,
					highest_bidder: userID,
					amount: bid.value,
				}
			});
			console.log("Bid placed successfully");
			getBidList();
			auctionStore.updateAuctionListOnBid({
				auction: auction.value.id,
				card: auction.value.card.id,
				highest_bidder_name: userName,
				highest_bidder: userID,
				amount: bid.value,
			});
		}
	} catch (error) {
		if (error.data) {
			accountStore.recordErrorMessages(error.data.message);
		} else {
			accountStore.recordErrorMessages('Quelque chose s\'est mal passé. Veuillez réessayer plus tard');
		}
		console.log(error);
	}
}
</script>