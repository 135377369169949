<!-- UserCardModal.vue -->

<template>
    <div class="modal fade" id="userFeaturedModal" tabindex="-1" aria-labelledby="userFeaturedModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header" id="userFeaturedModalLabel">
                    <h5 class="text-center">Select a Card below to submit it</h5>
                    <button type="button" class="close-btn" data-bs-dismiss="modal" aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <ul v-if="matchingUserCards.length > 0" class="text-dark matching-card-ctn">
                        <li v-for="card in matchingUserCards" class="matching-card" :key="card.id"
                            @click="selectCard(card)">
                            <PlayerCardinRows :playercard="card" />
                            <div v-if="isSelected(card)" class="check-circle">
                                <i class="fas fa-check-circle"></i>
                            </div>
                        </li>
                    </ul>
                    <div v-else class="text-center">
                        <h5>You do not have any cards that match this card. You can go to the marketplace and find out if it is available</h5>
                        <router-link v-if="selectedCard !== null" :to="'/football/player/' + selectedCard.player_slug" @click="closeAndSwitch">
                            <button class="btn btn-light">
                                Go to MarketPlace
                            </button>
                        </router-link>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="close-btn"  data-bs-dismiss="modal" aria-label="Close">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import { ref, computed, onBeforeUnmount } from 'vue';
import { useCardStore } from '@/stores/card';
import PlayerCardinRows from './PlayerCardinRows.vue';


const cardStore = useCardStore();
const userFeaturedCards = computed(() => cardStore.getUserFeaturedCards);

const props = defineProps({
    matchingUserCards: Array,
});

const selectedCard = computed(() => cardStore.getSelectedUserFeaturedCard);



const isSelected = (card) => {
    return userFeaturedCards.value.some((userCard) => {
        return userCard.id === card.id;
    });
};

function selectCard(card) {
    // Check if the card is not already in the userFeaturedCards array
    if (!userFeaturedCards.value.some(userCard => userCard.player === card.player)) {
        // Add the card to the userFeaturedCards array
        cardStore.addCardToUserFeaturedCards(card);
    } else {
        // Remove the card from the userFeaturedCards array
        cardStore.removeCardFromUserFeaturedCards(card);
    }
}

function closeAndSwitch() {
    const modal = document.getElementById('userFeaturedModal');
    modal.setAttribute('aria-hidden', 'true');
    const closeButton = modal.querySelector('.close-btn');
    if (closeButton) {
        closeButton.click();
    }
}

onBeforeUnmount(() => {
    cardStore.clearSelectedUserFeaturedCard();
});
</script>
  
<style scoped>
/* Add your modal styles here */

.matching-card-ctn {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
}

.matching-card {
    flex: 0 0 auto;
    /* Maintain original size */
    /* Add margin between cards */
    margin: 5px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .matching-card {
        flex: 0 0 45%;
    }

    .matching-card-ctn {
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

.modal-content {
    background-color: #1c1c1c;
    color: #fff;
}

.modal-header {
    border-bottom: 2px solid #000000;
    background-color: #121212;
}

.modal-body {
    padding: 0;
    max-height: 45vh;
    overflow-y: scroll;
    border: 0;
}

.modal-footer {
    border-top: 2px solid #000000;
    background-color: #121212;
}

</style>
  