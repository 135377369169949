import InviteView from './views/InviteView.vue'
import ProfileView from './views/ProfileView.vue'
import ProfileOverview from './components/ProfileOverview.vue'
import OwnedCards from '../card/components/OwnedCards.vue'
import AllTeamCollections from '../card/components/AllTeamCollections.vue'
import OwnedAuctions from '../auction/components/OwnedAuctions.vue'

const routes = [
    {
        path: '/profile',
        component: ProfileView,
        name: 'Profile',
        children: [
            {path: '', redirect: {name: 'ProfileOverview'}},
            {path: 'overview', component: ProfileOverview, name: 'ProfileOverview'},
            {path: 'cards', component: OwnedCards, name: 'ProfileCards'},
            {path: 'collections', component: AllTeamCollections, name: 'ProfileCollections'},
            {path: 'auctions', component: OwnedAuctions, name: 'ProfileAuctions'},
        ],
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/invite',
        component: InviteView,
        name: 'Invite',
        meta: {
            requiresAuth: true,
        }
    }

];

export default routes;