<template>
    <div v-if="playercard" class="card-template" :class="playercard && playercard.rarity_name ? playercard.rarity_name.toLowerCase().replace(' ', '-') + '-bg' : ''">
        <div class="card-img-ctn" :class="playercard && playercard.rarity_name ? playercard.rarity_name.toLowerCase().replace(' ', '-') + '-imgbg' : ''">
            <img class="card-img-top" :src="getPlayerImageUrl(playercard)" alt="Card image cap">
        </div>
        <div class="card-body">
            <h5 class="p-name">{{ playercard.pname }}</h5>
            <div class="card-text text-center text-dark">
                <span class="p-index">
                    {{ playercard.index }} / {{ playercard.total_index }}
                </span>
                <span class="p-rarity">
                    {{ playercard.rarity_name }}
                </span>
                <span class="p-season">
                    {{ playercard.season }}
                </span>
                <span class="p-position">
                    {{ playercard.position_name }}
                </span>
                <span class="p-age">
                    AGE {{ playercard.player_age }}
                </span>
                <span class="p-jersey">
                    #{{ playercard.jersey_number }}
                </span>
                <span class="flag-img">
                    <img class="p-flag" :src="getFlagImageUrl(playercard)" alt="">
                </span>
            </div>
        </div>
    </div>
</template>

<style scoped>
@import '../../../assets/css/card.css';

.card-img-top {
    width: 100%;
    object-fit: contain;
}
</style>
<script setup>
import { computed } from 'vue';
import { getPlayerImageUrl, getFlagImageUrl } from '@/utils/imagesGetter';

const props = defineProps({
    playercard: {
        type: Object,
        required: true,
    },
});
const playercard = computed(() => {
    return props.playercard;
});

</script>
