import CryptoJS, { enc } from 'crypto-js';

export function jwtDecrypt (token) {
  const base64Url = token.split ('.')[1];
  if (!base64Url) {
    return null;
  }
  const base64 = base64Url.replace ('-', '+').replace ('_', '/');
  var jsonPayload = decodeURIComponent (
    atob (base64)
      .split ('')
      .map (function (c) {
        return '%' + ('00' + c.charCodeAt (0).toString (16)).slice (-2);
      })
      .join ('')
  );

  return JSON.parse (jsonPayload);
}

export function tokenAlive (exp) {
  if (Date.now () >= exp * 1000) {
    return false;
  }
  return true;
}



const encryptionKey = 'not-secure-key';

// Example encryption function using AES
export function encryptToken(token) {
  const cipher = CryptoJS.AES.encrypt(token, encryptionKey);
  return cipher.toString();
}

// Example decryption function using AES
export function decryptToken(encryptedToken) {
  const bytes = CryptoJS.AES.decrypt(encryptedToken, encryptionKey);
  return bytes.toString(CryptoJS.enc.Utf8);
}