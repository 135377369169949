import instance from '@/baseAPI';

const cardApi = {
  checkCardinAuction: card_id =>
    instance.get (`auction/webhook/check_auction_existence/${card_id}`),
  obtainAllCards: filters =>
    instance.get (
      'card/all',
      {params: filters},
      {paramsSerializer: {indexes: null}}
    ),
  obtainOwnedCards: filters =>
    instance.get (
      'card/owned',
      {params: filters},
      {paramsSerializer: {indexes: null}}
    ),
  obtainPackCategories: () => instance.get ('card/pack-category/all'),
  obtainPackDetails: uuid => instance.get (`card/pack/${uuid}`),
  obtainSingleCard: slug => instance.get (`card/${slug}`),
  obtainSingleTeamCollection: slug =>
    instance.get (`card/team-collection/${slug}`),
  obtainTeamCollections: () => instance.get (`card/team-collection/all`),
  createSale: data => instance.post ('card/sale/create/', data),
  purchaseSingleCard: id => instance.post (`card/purchase/${id}/`),
  purchaseRandomPack: slug => instance.post (`card/purchase-pack/${slug}/`),
  obtainAllSales: filters =>
    instance.get (
      'card/sale/all',
      {params: filters},
      {paramsSerializer: {indexes: null}}
    ),
  obtainLatestFeaturedCards: () => instance.get ('card/featured/latest'),
  submitLatestFeaturedCards: data =>
    instance.post ('card/featured/submit/', data),
};

export default cardApi;
