import {defineStore} from 'pinia';
import accountApi from '@/modules/account/api';
import {
  jwtDecrypt,
  tokenAlive,
  encryptToken,
  decryptToken,
} from '@/shared/jwtHelper';

export const useAccountStore = defineStore ('account', {
  state: () => ({
    authData: {
      acxToken: localStorage.getItem (decryptToken ('acxToken')) || '',
      tokenExp: '',
      userId: '',
      isAuthenticated: false,
      userName: '',
      userUuid: '',
    },
    loginStreak: null,
    errorMessages: null,
    successMessages: null,
    formErrors: null,
    APIData: '',
  }),

  actions: {
    async googleLogin (payload) {
      try {
        this.clearErrorMessages ();
        const response = await accountApi.googleLogin (payload);
        if (response.data && response.status === 200) {
          this.saveAuthData (response.data);
          console.log ('User logged in');
          return response;
        } else if (response && response.status === 201) {
          console.log ('User created');
          return response;
        }
      } catch (error) {
        console.error ('Error in googleLogin action:', error);
        this.recordErrorMessages (
          'Something went wrong. Please try again later.'
        );
        throw error;
      }
    },

    async pullLoginStreak () {
      try {
        const response = await accountApi.loginStreak ();
        this.setLoginStreakData (response.data);
      } catch (error) {
        console.error ('Error in pullLoginStreak action:', error);
      }
    },

    async checkIn (payload) {
      try {
        const response = await accountApi.checkin (payload);
        this.setLoginStreakData (response.data);
      } catch (error) {
        console.error ('Error in checkin action:', error);
      }
    },

    async home () {
      this.clearPageData ();
      try {
        const response = await accountApi.home ();
        // Handle the response and commit mutations as needed
        // For example:
        this.setPageData (response.data);
        return response;
      } catch (error) {
        console.error ('Error in home action:', error);
        // Handle errors if necessary
      }
    },

    onStoreInit () {
      const encryptedToken = localStorage.getItem ('acxToken');
      if (encryptedToken) {
        const decryptedToken = decryptToken (encryptedToken);
        const jwtDecodedValue = jwtDecrypt (decryptedToken);
        this.authData.acxToken = decryptedToken;
        this.authData.userId = jwtDecodedValue.user_id;
        this.authData.tokenExp = jwtDecodedValue.exp;
        this.authData.isAuthenticated = tokenAlive (jwtDecodedValue.exp);
        this.authData.userName = jwtDecodedValue.username;
      } else {
        this.authData.isAuthenticated = false;
      }
    },

    async login (payload) {
      try {
        this.clearErrorMessages ();
        const response = await accountApi.login (payload);
        if (response && response.data && response.status === 200) {
          this.saveAuthData (response.data);
          return response;
        } else {
          this.recordErrorMessages (response.data.detail);
        }
      } catch (error) {
        console.error ('Error in login action:', error);
        this.recordErrorMessages (
          'Something went wrong. Please try again later.'
        );
        this.recordErrorMessages (error.data.detail);
        throw error;
      }
    },

    async logout (payload) {
      try {
        const response = await accountApi.logout (payload);
        // Handle the response and commit mutations as needed
        // For example:
        this.deleteAuthData ();
      } catch (error) {
        console.error ('Error in logout action:', error);
        // Handle errors if necessary
        throw error;
      }
    },

    async passwordReset({token, payload}) {
      try {
        this.clearErrorMessages ();
        const response = await accountApi.passwordReset (token, payload);
      } catch (error) {
        this.recordErrorMessages (error.data.message);
      }
    },

    async refreshToken () {
      try {
        const encryptedRefreshToken = localStorage.getItem ('refxToken');
        const response = await accountApi.obtainToken ({
          refresh_token: decryptToken (encryptedRefreshToken),
        });
        this.updateAccessToken (response.data.access_token);
        return response;
      } catch (error) {
        // Handle errors if necessary
        throw error;
      }
    },

    async requestEmailVerification (payload) {
      try {
        this.clearErrorMessages ();
        return await accountApi.requestVerificationEmail (payload);
        // Handle the response and commit mutations as needed
        // For example:
      } catch (error) {
        this.recordErrorMessages (error.data.message);
      }
    },

    async requestPasswordReset (payload) {
      try {
        this.clearErrorMessages ();
        return await accountApi.passwordResetRequest (payload);
      } catch (error) {
        console.error ('Error in requestPasswordReset action:', error);
      }
    },

    async signup (payload) {
      try {
        this.clearErrorMessages ();
        await accountApi.signup (payload);
        // Handle the response and commit mutations as needed
        // For example:
      } catch (error) {
        console.error ('Error in login action:', error);
        this.recordErrorMessages (
          'Something went wrong. Please try again later.'
        );
        this.recordErrorMessages (error.data.detail);
        throw error;
      }
    },

    async verifyEmail (token) {
      try {
        this.clearErrorMessages ();
        const response = await accountApi.verifyEmail (token);
        console.log (response);
      } catch (error) {
        this.recordErrorMessages (error.data.message);
      }
    },

    recordErrorMessages (error) {
      this.errorMessages = error;
    },

    clearErrorMessages () {
      this.errorMessages = null;
    },

    clearSuccessMessages () {
      this.successMessages = null;
    },

    recordSuccessMessages (message) {
      this.successMessages = message;
    },

    deleteAuthData () {
      localStorage.removeItem ('acxToken');
      localStorage.removeItem ('refxToken');
      this.authData = {
        acxToken: '',
        refxToken: '',
        tokenExp: '',
        userId: '',
        userUuid: '',
      };
    },

    saveAuthData (data) {
      const encryptedAccess = encryptToken (data.tokens.access);
      const encryptedRefresh = encryptToken (data.tokens.refresh);
      localStorage.setItem ('acxToken', encryptedAccess);
      localStorage.setItem ('refxToken', encryptedRefresh);

      const jwtDecodedValue = jwtDecrypt (data.tokens.access);
      const newTokenData = {
        tokenExp: jwtDecodedValue.exp,
        userId: jwtDecodedValue.user_id,
      };
      this.authData = newTokenData;
    },

    updateAccessToken (access_token) {
      const jwtDecodedValue = jwtDecrypt (access_token);
      const updatedAuthData = {
        ...this.authData,
        acxToken: access_token,
        tokenExp: jwtDecodedValue.exp,
      };
      const encryptedAccess = encryptToken (access_token);
      localStorage.setItem ('acxToken', encryptedAccess);
      this.authData = updatedAuthData;
    },

    setPageData (data) {
      this.APIData = data;
    },

    clearPageData () {
      this.APIData = '';
    },
    setLoginStreakData (data) {
      this.loginStreak = data;
    },
  },

  getters: {
    isAuthenticated () {
      return this.authData.isAuthenticated;
    },
    getErrorMessages () {
      return this.errorMessages;
    },
    getSuccessMessages () {
      return this.successMessages;
    },
    getAuthData () {
      return this.authData;
    },
    getLoginStreakData () {
      return this.loginStreak;
    },
    isTokenActive () {
      if (!this.authData.acxToken) {
        return false;
      }
      return tokenAlive (this.authData.tokenExp);
    },
    getPageData () {
      return this.APIData;
    },
    getUserProfileData () {
      return this.userProfiledata;
    },
    getUserAddressData () {
      return this.userAddressData;
    },
  },
});
