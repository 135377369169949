<template>
    <div class="help-box">
        <div class="help-box-content">
            <i class="fa-solid fa-question-circle"></i>
        </div>
    </div>
</template>


<style scoped>
.help-box {
    display: flex;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 2px 5px;
}

.fa-question-circle {
    font-size: 20px;
    color: #fff;
}

.help-box-content {
    display: flex;
    float: right;
    padding: 8px;
    background-color: #2a2a2a;
    border-radius: 25px;
    border: 0;
    color: #fff;
}
</style>