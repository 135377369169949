<template>
    <div class="text-center team-collection">
        <div v-if="teamcollection" class="team-col-base"
            :class="['team-col-card-' + teamcollection.rarity_name.toLowerCase().replace(' ', '-')]">
            <router-link class="text-decoration-none text-white" type="button"
                :to="'/team-collection/' + teamcollection.slug">
                <div class="card-title">
                    <h6>{{ teamcollection.rarity_name }} - {{ teamcollection.year }}</h6>
                </div>
                <div class="card-body">
                    <h5 class="collection-name">{{ teamcollection.name }}</h5>
                    <img class="team-logo" :src="teamcollection.team_logo_url" alt="Team Logo" />
                </div>
            </router-link>
            <div class="card-footer flex-grow">
                <div class="row card-row">
                    <div class="col-2 small-text">
                        Cartes
                    </div>
                    <div class="col-5 small-text">
                        {{ teamcollection.owned_cards.length }} / {{ teamcollection.cards.length }}
                    </div>
                </div>
                <div class="row card-row">
                    <div class="col-2 small-text">
                        Rang
                    </div>
                    <div class="col-5 small-text">
                        -
                    </div>
                </div>
                <div class="row card-row">
                    <div class="col-2 small-text">
                        Score
                    </div>
                    <div class="col-5 small-text">
                        0 <i class="fa-solid fa-certificate"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.team-collection:hover {
    cursor: pointer;
    transition: 0.3s;
}

.small-text {
    font-size: 1rem;
    font-family: 'Montserrat R', sans-serif;

}

.collection-name {
    font-size: 1rem;
    font-family: 'Montserrat SemiBold', sans-serif;
    color: #fff;
    margin: 10px 0;
}

.team-collection {
    transition: 0.3s;
}

.team-col-card-rare {
    background: linear-gradient(to left, rgba(5, 5, 5, 0) 0%, rgba(54, 9, 7, 0.268) 35%),
        url("../../../assets/rare-card-banner.jpg");
    color: #ffffff;
    box-shadow: 0px 0px 4px 5px rgb(153, 6, 6), 0px 0px 4px 5px rgb(153, 6, 6);
}

.team-col-card-normal {
    background: linear-gradient(to left, rgba(24, 23, 23, 0.451) 0%, rgba(27, 26, 26, 0.457) 35%),
        url("../../../assets/common-card-banner.jpg");
    color: #ffffff;
    box-shadow: 0 1px 2px 0 rgb(255, 255, 255);
}

.team-col-card-limited {
    background: linear-gradient(to left, rgba(44, 35, 7, 0.531) 0%, rgba(44, 35, 7, 0.485) 35%),
        url("../../../assets/limited-card-banner.jpg");
    color: #ffffff;
    box-shadow: 0px 0px 2px 2px rgb(147, 107, 6), 0px 0px 2px 2px rgb(147, 107, 6);
}

.team-col-card-super-rare {
    background: linear-gradient(to left, rgba(5, 5, 5, 0.435) 0%, rgba(8, 16, 69, 0.508)35%),
        url("../../../assets/super-rare-card-banner.jpg");
    color: #ffffff;
    box-shadow: 0px 0px 4px 5px rgb(7, 14, 202), 0px 0px 4px 5px rgb(6, 9, 83);
}

.team-col-card-unique {
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.485) 35%),
        url("../../../assets/unique-card-banner.jpg");
    color: #ffffff;
    box-shadow: 0 6px 12px 0 rgb(8, 208, 161), 0 6px 12px 0 rgb(8, 208, 161);
}

.card-footer {
    margin-top: auto;
    /* Push the footer to the bottom */
    align-items: center;
    color: #ffffff;
    padding: 5px;
}


.card-title {
    padding: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}


.card-body {
    padding: 5px;
}

.card-row {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
}
</style>

<script>
import { defineComponent, computed, ref } from 'vue';

export default defineComponent({
    name: 'TeamCollectionCard',
    props: {
        teamcollection: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        // const inCollection = ref(props.class);
        const teamcollection = computed(() => {
            return props.teamcollection;
        });
        return {
            teamcollection,
        };
    },
});
</script>