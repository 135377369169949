import {defineStore} from 'pinia';
import rankingApi from '@/modules/ranking/api';

export const useRankingStore = defineStore ('ranking', {
    state: () => ({
        allCollectionsRanking: [],
        collectionRanking: [],
    }),

    actions: {
        setAllCollectionsRanking (data) {
            this.allCollectionsRanking = data;
        },
        setCollectionRanking (data) {
            this.collectionRanking = data;
        },
        pullAllCollectionsRanking() {
            rankingApi.AllCollectionsRanking().then(response => {
                if (response && response.data && response.status === 200) {
                    this.setAllCollectionsRanking(response.data);
                }
            }).catch(error => {
                console.error ('Error in pullAllCollectionsRanking action:', error);
            });
        },
        pullCollectionRanking(collectionSlug) {
            rankingApi.CollectionRanking(collectionSlug).then(response => {
                if (response && response.data && response.status === 200) {
                    this.setCollectionRanking(response.data);
                }
            }).catch(error => {
                console.error ('Error in pullCollectionRanking action:', error);
            });
        },
    },  
    getters: {
        getAllCollectionsRanking() {
            return this.allCollectionsRanking;
        },
        getCollectionRanking() {
            return this.collectionRanking;
        },
    },
});