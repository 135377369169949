<template>
    <HelpBox />
    <h2 class="dashboard-title">
        Mon Portefeuille
    </h2>
    <div class="main-dflex">
        <div class="container">
            <div class="container ctn">
                <!-- Wallet balance section -->
                <p class="balance-title">
                    Balance Actuelle
                </p>
                <h3 v-if="wallet.balance !== null" class="balance-number">
                    {{ formatNumber(wallet.balance) }} <i class="fa fa-ils"></i>
                </h3>
                <p class="balance-title-2">
                    Balance Réservée
                </p>
                <h3 v-if="wallet.reservedBalance !== null" class="balance-number-2">
                    {{ formatNumber(wallet.reservedBalance) }} <i class="fa fa-ils"></i>
                </h3>
                <h3 v-else class="balance-number">
                    0 <i class="fa fa-ils"></i>
                </h3>
            </div>
            <div class="container ctn">
                <!-- Wallet balance section -->
                <div class="buy-body">
                    <router-link to="/currency-packages/all">
                        <button class="djoba-button">
                            Recharger mon compte
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="container ctn">
            <!-- Charts section -->
            <div class="card-body">
                <h5 class="card-title mb-5 tra-title">
                    Historique des transactions
                </h5>
                <!-- Display charts here -->
                <div v-if="walletTransactions.length > 0">
                    <div class="row transaction-ctn" v-for="transaction in walletTransactions" :key="transaction.id">
                        <span class="col" :class="getTextColorClass(transaction.transaction_type)">
                            {{ transaction.activity }}
                        </span>
                        <span class="col" :class="getTextColorClass(transaction.transaction_type)">
                            {{ transaction.amount }} <i class="fa fa-ils"></i>
                        </span>
                        <span class="col" :class="getTextColorClass(transaction.transaction_type)">
                            {{ formatDate(transaction.transaction_date) }}
                        </span>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import { onMounted, ref, computed } from 'vue';
import { useWalletStore } from '@/stores/wallet';
import formatNumber from '@/utils/numberFormatter';
import HelpBox from '@/components/HelpBox.vue';

const walletStore = useWalletStore();


const wallet = computed(() => {
    return walletStore.getWalletData;
});
const walletTransactions = computed(() => {
    return walletStore.getWalletTransactions;
});
function formatDate(date) {
    // Format the date to a human readable format
    const options = {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    };
    return new Date(date).toLocaleDateString("fr-FR", options);
}

function getTextColorClass(transactionType) {
    // Return a CSS class based on the transaction_type
    return {
        'text-green': transactionType === 'deposit',
        'text-red': transactionType === 'withdraw'
    };
};

async function getWalletData() {
    try {
        await walletStore.pullWalletData();
    } catch (error) {
        console.log(error);
    }
}

function capitalize(value) {
    // Capitalize the transaction_type
    return value.charAt(0).toUpperCase() + value.slice(1);
}
onMounted(() => {
    getWalletData();
    walletStore.pullWalletTransactions();
});

</script>


<style scoped>
@import '../../../assets/css/wallet.css';

.djoba-button {
    background-color: #1d1d1d6e;
    border: 1px solid #b1ffe8da;
    border-radius: 25px;
    color: #b1ffe8da;
    padding: 15px;
    width: 300px;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
}

.djoba-button:hover {
    background-color: #b1ffe8da;
    color: #040404;
    transition: 0.3s;
}

.tra-title {
    color: #fff;
    font-size: 1.25rem;
    margin: 10px 10px 0px 10px;
    font-weight: 600;
    text-transform: uppercase;
}

.transaction-ctn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.balance-number {
    color: #fff;
    font-size: 2.75rem;
    margin: 10px 20px;
    font-weight: 600;
}

.balance-number-2 {
    color: #ffffffb1;
    font-size: 2.25rem;
    margin: 10px 20px;
    font-weight: 600;
}

.balance-title {
    color: #fff;
    font-size: 1.25rem;
    margin: 40px 20px 0px 20px;
    font-weight: 600;
    text-transform: uppercase;
}

.balance-title-2 {
    color: #ffffffb1;
    font-size: 1rem;
    margin: 40px 20px 0px 20px;
    font-weight: 600;
    text-transform: uppercase;
}

.buy-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.dashboard-title {
    color: #fff;
    font-size: 1.5rem;
    margin: 10px 20px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
}

.ctn {
    background-color: #1d1d1d6e;
    border-radius: 20px;
    padding: 12px;
    margin-bottom: 20px;
}

.main-dflex {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .main-dflex {
        flex-direction: column;
    }

}
</style>