import {defineStore} from 'pinia';
import profileApi from '@/modules/profile/api';

export const useProfileStore = defineStore ('profile', {
  state: () => ({
    userDailyTasks: [],
  }),
  actions: {
    async fetchUserDailyTasks () {
      try {
        const response = await profileApi.obtainUserDailyTasks ();
        if (response && response.data && response.status === 200) {
          this.setDailyTasks (response.data);
        }
      } catch (error) {
        console.log (error);
        throw error;
      }
    },
    setDailyTasks (dailyTasks) {
      this.userDailyTasks = dailyTasks;
    },
    clearDailyTasks () {
      this.userDailyTasks = [];
    },
  },
  getters: {
    getDailyTasks () {
      return this.userDailyTasks;
    },
  },
});
