<template>
    <div class="sidebar">
        <div class="mobile-pills" :class="{ 'hidden': hidePill }" @click="toggleMobileFilters">
            <i class="fa fa-filter"></i>
            <span v-if="!showFilters">Show Filters</span>
            <span v-else>See results</span>
        </div>
        <div v-if="showFilters" class="off-canvas" :class="{ 'show': showFilters }">
            <div class="canvas-header">
                <h3 class="canvas-title">Filter By: </h3>
                <button type="button" class="close-btn" @click="toggleMobileFilters">
                    <i class="fas fa-times"></i>
                </button>
            </div>
            <div class="filter-options">
                <!-- Add your filter options here -->
                <div class="filter-section">
                    <RarityFilter />
                </div>
                <hr class="filter-divider">
                <PositionFilter />
                <hr class="filter-divider">
            </div>
        </div>
        <div v-if="!showFilters" class="filter-section-desktop">
            <div class="filter-options">
                <!-- Add your filter options here -->
                <div class="filter-section">
                    <RarityFilter />
                </div>
                <hr class="filter-divider">
                <PositionFilter />
                <hr class="filter-divider">
            </div>
        </div>
    </div>
</template>



<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import PositionFilter from './PositionFilter.vue';
import RarityFilter from './RarityFilter.vue';



const showFilters = ref(false);
const hidePill = ref(false);
function toggleMobileFilters() {
    showFilters.value = !showFilters.value;
}

function handleScroll() {
    const container = document.documentElement; // Use document.documentElement to reference the entire page

    const scrollPosition = window.scrollY + window.innerHeight;
    const totalHeight = container.scrollHeight;

    // Check if the user has reached the bottom (adjust the threshold as needed)
    if (scrollPosition >= totalHeight * 0.95) {
        // Hide the mobile pills
        hidePill.value = true;
    } else {
        // Show the mobile pills
        hidePill.value = false;
    }
}


onMounted(() => {
    window.addEventListener('scroll', handleScroll);
});
onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll);
});
</script>



<style scoped>
@import '../../../assets/css/filters.css';
</style>