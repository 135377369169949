import instance from '@/baseAPI';

const footballApi = {
  getLeagues: () => instance.get ('sports/football/leagues'),
  getSingleLeague: slug => instance.get (`sports/football/league/${slug}`),
  getTeams: () => instance.get ('sports/football/teams'),
  getSingleTeam: slug => instance.get (`sports/football/team/${slug}`),
  getPlayers: () => instance.get ('sports/football/players'),
  getSinglePlayer: (slug, filters) =>
    instance.get (
      `sports/football/player/${slug}`,
      {params: filters},
      {paramsSerializer: {indexes: null}}
    ),
};

export default footballApi;
