const getFlagImageUrl = (card) => {
    if (card.team_name) {
        return `https://djolowindemo.sfo3.digitaloceanspaces.com/teams/${card.team_name.toLowerCase().replace(' ', '-')}.png`
    }
}

const getPlayerImageUrl = (card) => {
    if (card.team_name) {
        return `https://djolowindemo.sfo3.digitaloceanspaces.com/jerseys/${card.team_name.toLowerCase().replace(' ', '-')}-jersey.png`

    }
}

const getPlayerDetailImageUrl = (player) => {
    return `https://djolowindemo.sfo3.digitaloceanspaces.com/jerseys/${player.nationality.toLowerCase().replace(' ', '-')}-jersey.png`
}

export { getFlagImageUrl, getPlayerImageUrl, getPlayerDetailImageUrl }