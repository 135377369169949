<template>
    <section class=" gradient-custom">
        <div class="container">
            <div class="row d-flex justify-content-center align-items-center min-vh-100">
                <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                    <FlashMessage />
                    <div class="login-card text-white" style="border-radius: 1rem">
                        <div class="card-body p-5 text-center">
                            <div class="mb-md-5 mt-md-4 pb-5">
                                <h2 class="fw mb-5 ">Enter your email to receive an account verification link</h2>
                                <div class="form-outline form-white mb-4">
                                    <form @submit.prevent="requestEmailVerification">
                                        <div class="form-outline form-white mb-4">
                                            <input class="form-control-lg" v-model="formData.email"
                                                placeholder="Enter your email" required />
                                        </div>
                                        <button class="btn btn-light btn-lg px-5" type="submit">Send me a link</button>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { ref, defineComponent } from 'vue'; // Import ref and onMounted
import { isValidEmail } from '@/utils/emailValidator';
import { useAccountStore } from '@/stores/account';
import { useRouter } from 'vue-router'

import FlashMessage from '../../../components/FlashMessage.vue';


export default defineComponent ({
    components: {
        FlashMessage,
    },
    setup() {
        const accountStore = useAccountStore();
        const router = useRouter()

        const formData = ref({
            email: '',
        });

        async function requestEmailVerification() {
            if (!isValidEmail(formData.value.email)) {
                accountStore.errorMessages = 'Please enter a valid email address';
                return;
            }
            try {
                await accountStore.requestEmailVerification(formData.value);
                router.push({ name: 'VerifyEmailSent' });
            } catch (error) {
                if (error.status === 404) {
                    router.push({ name: 'VerifyEmailSent' });
                }
            }
        };
        return {
            formData,
            requestEmailVerification,
        }
    },
})

</script>