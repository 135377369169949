<template>
    <FullPageSpinner v-if="loading" :loading="loading" />
    <h1 class="text-white">Packs on sale</h1>
    <div class="packs-main-ctn">
        <div v-for="pack in packs" :key="pack.id">
            <div class="pack">
                <h2 class="text-white">{{ pack.name }}</h2>
                <span class="text-white">{{ formatNumber(pack.price) }} <i class="fa fa-ils"></i></span>
                <img src="../../../assets/deck-cards-nobg.png" :alt="pack.name" class="pack-img">
                <div class="btn btn-buy" role="button" data-bs-toggle="modal" data-bs-target="#confirmationModal"
                    @click="openConfirmationModal(pack)">
                    Buy
                </div>
                <p class="pack-desc text-white">{{ pack.description }}</p>
            </div>
        </div>
        <div  class="modal fade"  id="confirmationModal" tabindex="-1" role="dialog"
            aria-labelledby="confirmationModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content" v-if="selectedPack">
                    <div class="modal-header">
                        <h5 class="modal-title">Confirm Purchase - {{ selectedPack.name }}</h5>
                        <button type="button" class="close-btn" data-bs-dismiss="modal" aria-label="Close" @click="hideModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p class="text-white desc">Are you sure you want to purchase this pack?</p>
                        <p class="text-white price">Price: {{ formatNumber(selectedPack.price) }} <i class="fa fa-ils"></i></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-confirm" @click="buyPack(selectedPack.slug)">Confirm</button>
                        <button type="button" class="btn btn-cancel" data-bs-dismiss="modal" @click="hideModal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useCardStore } from '@/stores/card';
import FullPageSpinner from '@/components/FullPageSpinner.vue';
import { useAccountStore } from '@/stores/account';
import formatNumber from '@/utils/numberFormatter';
import { useRouter } from 'vue-router';

const accountStore = useAccountStore();
const cardStore = useCardStore();
const router = useRouter();

const loading = ref(false);
const selectedPack = ref(null);

const packs = computed(() => cardStore.getPackCategories);

async function fetchPacks() {
    try {
        loading.value = true;
        const response = await cardStore.pullpackCategories();
    } catch (error) {
        console.log(error);
    } finally {
        loading.value = false;
    }
}

async function buyPack(packCategorySlug) {
    hideModal(); // Close the modal after successful purchase
    try {
        loading.value = true;
        const response = await cardStore.purchasePack(packCategorySlug);
        if (response.status === 200 && response.data) {
            accountStore.recordSuccessMessages(response.data.message);
            router.push({ name: 'PackDetail', params: { uuid: response.data.pack.uuid } });
        }
    } catch (error) {
        if (error.data && error.data.message) {
            return accountStore.recordErrorMessages(error.data.message)
        }
    } finally {
        loading.value = false;
    }
}

function hideModal() {
    selectedPack.value = null;
    const modal = document.getElementById('confirmationModal');
    modal.setAttribute('aria-hidden', 'true');
    const closeButton = modal.querySelector('.close-btn');
    if (closeButton) {
        closeButton.click();
    }
}

function openConfirmationModal(pack) {
    selectedPack.value = pack;
}

onMounted(() => {
    fetchPacks();
});

</script>

<style scoped>
.pack-img {
    width: 300px;
    height: 300px;
    transition: all 0.4s ease-in-out;
    margin-bottom: 20px;
}

.modal-content {
    background-color: #040404;
    color: #ffffff;
    box-shadow: 0px 0px 10px 0px #ffffff;
}

.btn-buy {
    background-color: #ff800052;
    border-color: #ff8000bd;
    font-size: 1.2rem;
    border-radius: 25px;
    width: 100px;
    color: #ffffff;
    transition: all 0.5s ease-in-out;
}

.btn-buy:hover {
    background-color: #ff8000;
    border-color: #ff8000;
    width: 120px;
    color: #000000;
}

.price {
    font-size: 1.2rem;
}

.desc {
    font-size: 1.2rem;
}

.pack-img:hover {
    cursor: pointer;
    scale: 1.1;
}

.pack-desc {
    width: 300px;
    text-align: center;
    font-family: "Inconsolata R", monospace;
    font-size: 1.2rem;
}

.packs-main-ctn {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.btn-confirm {
    background-color: #ff800052;
    border-color: #ff8000bd;
    border-radius: 25px;
    color: #ffffff;
    transition: all 0.3s ease-in-out;
}

.btn-confirm:hover {
    background-color: #ff8000;
    border-color: #ff8000;
    border-radius: 15px;
    color: #000000;
}

.btn-cancel {
    background-color: #dc353583;
    border-color: #dc3545;
    border-radius: 25px;
    color: #ffffff;
    transition: all 0.3s ease-in-out;
}

.btn-cancel:hover {
    background-color: #dc3545;
    border-color: #dc3545;
    border-radius: 15px;
    color: #000000;
}

.pack {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto 50px;
}

@media (max-width: 1000px) {
    .packs-main-ctn {
        flex-direction: column;
    }

    .pack {
        margin-bottom: 50px;
    }

    .pack-img {
        width: 220px;
        height: 220px;
    }
}
</style>