<template>
    <div v-if="isMobileView">
        <!-- Additional fields you want to display on mobile -->
        <!-- For example -->
        <div v-if="card" class="additional-field">
            <div class="card-name">
                {{ card.pname }}
            </div>
            <div class="card-badge">
                <span class="card-season">
                    {{ card.season }}
                </span>
                <span :class="badgeClass" class="r-badge">
                    {{ card.rarity_name }}
                </span>
                <span class="card-number">
                    {{ card.index }} / {{ card.total_index }}
                </span>
            </div>
        </div>
        <!-- Add more fields as needed -->
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
const isMobileView = ref(false);

const props = defineProps({
    card: Object,
});

const card = ref(props.card);

const checkMobileView = () => {
    isMobileView.value = window.innerWidth <= 768; // Adjust breakpoint as needed
};

const badgeClass = computed(() => {
    return {
        'normal-badge': card.value.rarity_name === 'Normal',
        'rare-badge': card.value.rarity_name === 'Rare',
        'limited-badge': card.value.rarity_name === 'Limited',
        'super-rare-badge': card.value.rarity_name === 'Super Rare',
        'unique-badge': card.value.rarity_name === 'Unique',
    };
});

onMounted(() => {
    checkMobileView(); // Check on initial load
    window.addEventListener('resize', checkMobileView); // Listen for resize events
});
</script>