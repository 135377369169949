<template>
	<div class="wrapping" id="document">
		<FlashMessage />
		<router-view></router-view>
	</div>
</template>





<style scoped>
@import './assets/css/main.css';
</style>

<script setup>
// import UserBanner from './components/UserBanner.vue';
import { watch } from 'vue';
import algoliasearch from 'algoliasearch/lite';
import { useEventStore } from './stores/events';
import FlashMessage from './components/FlashMessage.vue';

import { defineComponent, ref } from 'vue';

const eventStore = useEventStore();

const searchClient = algoliasearch(
	'4VD4GCDPRG',
	'c03eeda53f062bd99e91772550ff7b42'
);

watch(
	() => eventStore.auctionEnded,
	() => {
		console.log('auction ended');
	});

</script>



