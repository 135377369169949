import CollectionDetailView from './views/CollectionDetailView.vue'
import AllCardSalesView from './views/AllCardSalesView.vue'
import AllPacksView from './views/AllPacksView.vue'
import PackDetailView from './views/PackDetailView.vue'
import SubmitWeeklyFeaturedCardsView from './views/SubmitWeeklyFeaturedCardsView.vue'

const routes = [
    {
        path: '/card-sales',
        component: AllCardSalesView,
        name: 'AllCardSales',
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/packs/:uuid',
        component: PackDetailView,
        name: 'PackDetail',
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/packs',
        component: AllPacksView,
        name: 'AllPacks',
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/team-collection/:slug',
        component: CollectionDetailView,
        name: 'CollectionDetail',
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/submit-weekly-featured-cards',
        component: SubmitWeeklyFeaturedCardsView,
        name: 'SubmitWeeklyFeaturedCards',
        meta: {
            requiresAuth: true,
        }
    },
];

export default routes;