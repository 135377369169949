<template>
    <div class='info-section'>
        <div class="btn-ctn mb-3 px-1">
            <button class="btn btn-outline-light btn-lg mb-1" data-bs-toggle="modal" data-bs-target="#infoRankModal">
                Comment fonctionne le système de points ?
            </button>
            <br>
            <span class="small text-warning">
                Les rangs sont mis à jour toutes les 15 minutes
            </span>
        </div>
        <div class="modal fade" id="infoRankModal" tabindex="-1" aria-labelledby="infoRankModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content text-white">
                    <div class="modal-header">
                        <h4 class="text-center" id="infoRankModalLabel">
                            Le jeu du Collectionneur
                        </h4>
                        <button type="button" class="close-btn" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fas fa-times"></i>

                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="popup-title mb-3">
                            <h5>
                                <i class="fa-solid fa-circle-info"></i>
                                Règles du jeu
                            </h5>
                            <span class="text-white-50 small">
                                Chaque carte appartenant à une collection respecte les règles suivantes:
                            </span>
                        </div>
                        <ul class="list-group d-flex justify-content-center">
                            <li class="list-group-item back-dark">
                                <p class="bold text-white m-0">Acquise</p>
                                <span class="regular small text-white-50 "> 
                                    Vous possédez la carte
                                </span>
                                <span class="accentext bold float-right">+10</span>
                            </li>
                            <li class="list-group-item back-dark">
                                <p class="bold text-white m-0">#1</p>
                                <span class="regular small text-white-50 "> 
                                    La carte a le numéro de série 1
                                </span>
                                <span class="accentext bold float-right">+30</span>
                            </li>
                            <li class="list-group-item back-dark">
                                <p class="bold text-white m-0">
                                    Maillot de série
                                </p>
                                <span class="regular small text-white-50 "> 
                                    Le numéro de série de la carte est le même que celui du maillot du joueur    
                                </span>
                                <span class="accentext bold float-right">+30</span>
                            </li>
                        </ul>
                        <div class="warning-section">
                            <p class="regular text-white">
                                <i class="fa-solid fa-circle-info"></i>
                                Des multiplicateurs sont appliqués à chaque carte en fonction de sa rareté.
                                Les multiplicateurs sont les suivants:
                                <br>
                                - <strong>Normal</strong> x1
                                <br>
                                - <strong>Limitee</strong> x2
                                <br>
                                - <strong>Rare</strong> x5
                                <br>
                                - <strong>Super Rare</strong> x10
                                <br>
                                - <strong>Unique</strong> x25
                                <br>
                                <br>
                                Les multiplicateurs <strong>sont uniquement appliqués aux 10 points de base.</strong> 
                            </p>
                            <p class="regular text-white">
                                <i class="fa-solid fa-circle-info"></i>
                                Si vous avez plusieurs exemplaires de la même carte, seul celui avec le
                                <strong>plus petit</strong> numéro de série est pris en compte.
                            </p>
                            <p class="regular text-white">
                                - Les cartes <strong>en vente ou en enchère</strong> ne sont pas prises en compte.
                            </p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="close-btn-lg" data-bs-dismiss="modal">FERMER</button>
                    </div>

                </div>

            </div>
        </div>
    </div>
    <table class="table table-dark table-ctn table-striped table-borderless" v-if="allCollectionsRanking">
        <thead>
            <tr>
                <th scope="col" class="rank-column">
                    <div class="cell rank-cell">Rang</div>
                </th>
                <th scope="col" class="cell">Nom du joueur</th>
                <th scope="col" class="points-column">
                    <div class="cell points-cell">Points</div>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="rank in allCollectionsRanking">
                <td class="rank-column">
                    <div class="cell rank-cell">{{ rank.rank }}</div>
                </td>
                <td v-if="rank.username === userName" class="text-success">{{ rank.username }} (Moi)</td>
                <td v-else>{{ rank.username }}</td>
                <td class="points-column">
                    <div class="cell points-cell">{{ rank.points }} <i class="fa-solid fa-certificate"></i></div>
                </td>
            </tr>
        </tbody>
    </table>
</template>


<style scoped>
.warning-section {
    background-color: #309d9863;
    padding: 10px;
    border-radius: 15px;
    margin-top: 20px;
    font-size: 0.9rem;
}

.back-dark {
    background-color: #2b3036;
    border: none;
}

.modal-content {
    background-color: #212529;
}

.float-right {
    float: right;
}

.rank-column {
    text-align: left;
    /* Align the "Rank" column to the right */
}

.cell {
    font-size: 1.2rem;
}

.rank-cell {
    padding-left: 15px;
    /* Adjust the padding as needed */
}

.points-column {
    text-align: right;
    /* Align the "Points" column to the right */
}

.points-cell {
    padding: 0 15px;
    /* Adjust the padding as needed */
}
</style>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRankingStore } from '@/stores/ranking';
import { useAccountStore } from '@/stores/account';
import { useRoute } from 'vue-router';

const accountStore = useAccountStore();
const rankingStore = useRankingStore();
const route = useRoute();

const userName = computed(() => {
    return accountStore.authData.userName;
});

const loading = ref(false);
const allCollectionsRanking = computed(() => {
    return rankingStore.getCollectionRanking;
});

onMounted(() => {
    rankingStore.pullCollectionRanking(route.params.slug);
});

</script>