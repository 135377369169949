<template>
    <div v-if="auction" class="row my-3 p-1">
        <div class="col-md-4">
            <router-link class="text-decoration-none" :to="'/auction/' + auction.id">
                <h5 class="auction-lt">{{ auction.card_season }}
                    - {{ auction.player_name }} - Ix# {{ auction.card_index }}
                </h5>
            </router-link>
        </div>
        <div class="col-md text-white-50">
            {{ (auction.rarity_name) }}
        </div>
        <div class="col-md">
            <p v-if="auction.sold" class="text-success">Sold</p>
            <p v-else class="text-warning">Not Sold</p>
        </div>
        <div class="col-md">
            <p v-if="auction.highest_bidder" class="text-info"> {{ auction.current_bid }} <i class="fa fa-ils"></i></p>
            <p v-else class="text-info">It did not sell</p>
        </div>
        <div class="col-md text-white-50 mb-2">
            {{ formattedDate }}
        </div>
        <hr class="text-white" />
    </div>
</template>

<style scoped>
@import '../../../assets/css/auction.css';
</style>

<script>
import { onMounted, ref, defineComponent, computed } from 'vue';
import { useAuctionStore } from '@/stores/auction';

export default defineComponent({
    props: {
        auction: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const auctionStore = useAuctionStore();
        const auction = computed(() => {
            return props.auction;
        });
        const formattedDate = computed(() => {
            return new Date(auction.value.end_time).toLocaleString();
        });

        return {
            auction,
            formattedDate,
        }
    }
})
</script>