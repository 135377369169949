<template>
    <nav class="navbar navbar-dark navbar-expand-lg" aria-label="djolowin navbar">
        <router-link to="/home" class="navbar-brand subrayada">
            <img class="d-inline-block align-text-center" src="../assets/djolowin/djolo-win-website-favicon-color.png"
                alt="Elephant Logo" width="40" height="40" />
            Djolo Win
        </router-link>
        <div class="notif-bell px-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#notificationCanvas"
            aria-controls="notificationCanvas">
            <i v-if="containReadNotifications.length > 0" class="fa-regular fa-bell">
                <span class="badge bg-danger rounded-pill">{{ containReadNotifications.length }}</span>
            </i>
            <i v-else class="fa-regular fa-bell"></i>
        </div>
        <router-link to="/leaderboard" class="leader-icon px-2">
            <i class="fa-sharp fa-solid fa-trophy"></i>
        </router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon">
                <i class="fas fa-bars" style="color:#fff; font-size:28px;"></i>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                <li class="nav-item dropdown">
                    <a href="#" v-if="userName" class="subrayada nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ userName }}
                    </a>
                    <a href="#" v-else class="subrayada nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fa fa-user mx-0"></i>
                        User
                    </a>
                    <div class="dropdown-menu bg-dark text-white" aria-labelledby="navbarDropdown">
                        <router-link to="/profile/overview" class="dropdown-item bold bg-dark">{{ $t('My Profile') }}</router-link>
                        <router-link to="/wallet/dashboard" class="dropdown-item bold bg-dark">{{ $t('My Wallet')
                        }}</router-link>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a href="#" class="subrayada nav-link dropdown-toggle text-wrap" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ $t('MarketPlace') }}
                    </a>
                    <div class="dropdown-menu bg-dark" aria-labelledby="navbarDropdown">
                        <router-link to="/auctions" class="dropdown-item bold bg-dark">{{ $t('Auctions') }}</router-link>
                        <router-link to="/card-sales" class="dropdown-item bold bg-dark">{{ $t('Direct Sales') }}</router-link>
                        <router-link to="/packs" class="dropdown-item bold bg-dark">{{ $t('Bundles') }}</router-link>
                        <!-- <router-link to="/special" class="dropdown-item bold bg-dark">{{ $t('Special Offers')
                        }}</router-link> -->
                    </div>
                </li>
                <li>
                    <router-link to="/djolowin-shop" class="subrayada nav-link disabled">
                        {{ $t('DjoloWin Shop') }}
                    </router-link>
                </li>
                <li class="nav-item dropdown bold">
                    <router-link to="/my-settings" class="subrayada nav-link">
                        {{ $t('Settings') }}
                    </router-link>
                </li>
                <li class="nav-item">
                </li>
            </ul>
            <div class="d-flex ">
                <a role="button" class="subrayada lightext text-decoration-none" @click="triggerLogout"><i
                        class="fa fa-fw fa-power-off"></i>{{ $t('Log Out') }}</a>
            </div>
        </div>
    </nav>
    <NotificationCanvas />
    <div class="toast-container position-fixed bottom-0 end-0">
        <div v-for="notification in pushNotifications" class="toast show" role="alert" aria-live="assertive"
            aria-atomic="true">
            <div class="toast-header">
                <strong class="me-auto">DjoloWin</strong>
                <small class="text-muted">{{ notification.date_sent }}</small>
                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div class="toast-body">
                {{ notification.message }}
            </div>
        </div>
    </div>
</template>


<style scoped>
.nav-item {
    padding: 0 10px;
}

.navbar {
    padding-left: 15px;
    padding-right: 15px;

}
</style>

<script setup>
import { computed, ref, defineComponent } from 'vue'; // Import ref and onMounted
import { useAccountStore } from '@/stores/account';
import { useNotificationStore } from '@/stores/notification';
import { useRouter } from 'vue-router'

import { decryptToken } from '@/shared/jwtHelper';
import { formatRelativeTime } from '@/utils/dateFormatter';
import { truncateNotificationBody } from '@/utils/wordTruncator';
import NotificationCanvas from '@/modules/notification/components/NotificationCanvas.vue';


const accountStore = useAccountStore();
const notificationStore = useNotificationStore();
const router = useRouter()
const pushNotifications = ref([])


const allNotifications = computed(() => {
    return notificationStore.getInboxData;
});

const containReadNotifications = computed(() => {
    return notificationStore.getInboxData.filter((notification) => {
        return notification.date_read === null;
    });
});

const Authenticated = computed(() => {
    return accountStore.isAuthenticated;
});

const userName = computed(() => {
    return accountStore.authData.userName;
});




async function triggerLogout() {
    const encryptedToken = localStorage.getItem('refxToken');
    const payload = {
        refresh: decryptToken(encryptedToken),
    };
    try {
        const response = await accountStore.logout(payload); // Call the logout action
        router.push({ name: 'Welcome' })
    } catch (error) {
        if (error.status === 400) {
            accountStore.recordErrorMessages('The token was not found or has expired. Please login again.');
            accountStore.deleteAuthData();
            router.push({ name: 'Welcome' })
        }
    }
};

</script>
