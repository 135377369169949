import AllAuctionsView from './views/AllAuctionsView.vue'
import AuctionDetailView from './views/AuctionDetailView.vue'

const routes = [
    {
        path: '/auctions',
        component: AllAuctionsView,
        name: 'AllAuctions',
        meta: {
            requiresAuth: true,
          },
    },
    {
        path: '/auction/:id',
        component: AuctionDetailView,
        name: 'AuctionDetail',
        meta: {
            requiresAuth: true,
        }
    },
];

export default routes;