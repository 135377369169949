<template>
    <MasterCardImage :mastercard="mastercard" :customClass="customClass" parentComponent="collection" />
    <div class="card-footer mb-2">
        <router-link @click="defineRarity" v-if="!customClass" class="text-decoration-none" type="button"
            :to="'/football/player/' + mastercard.player_slug">
            <button class="pill-btn-search mt-2">Chercher</button>
        </router-link>
        <h6 class="text-white regular" v-else-if="quantityOwned >= 1">{{ quantityOwned }}
            acquises</h6>
    </div>
</template>

<style scoped>
.card-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.pill-btn-search {
    background-color: #151515;
    color: #ffffff;
    border: 1px solid #ffffff;
    width: 100px;
	padding: 5px 0;
    border-radius: 20px;
    font-size: 0.8rem;
    font-family: 'Montserrat R', sans-serif;
    text-transform: uppercase;
    transition: 0.3s;
}
</style>
<script>
import { defineComponent, computed, ref } from 'vue';
import { useCardStore } from '@/stores/card';
import { useFilterStore } from '@/stores/filters';
import MasterCardImage from './MasterCardImage.vue';

export default defineComponent({
    name: 'CollectionCard',
    props: {
        mastercard: {
            type: Object,
            required: true,
        },
        customClass: {
            type: String,
            required: false,
        },
        teamId: {
            type: Number,
            required: false,
        },
    },
    components: {
        MasterCardImage,
    },
    setup(props) {
        const cardStore = useCardStore();
        const filterStore = useFilterStore();

        const ownedCards = computed(() => {
            return cardStore.getOwnedCardsData;
        });
        const inCollection = ref(props.class);
        const mastercard = computed(() => {
            return props.mastercard;
        });
        const teamId = computed(() => {
            return props.teamId;
        });
        const customClass = computed(() => {
            return props.customClass;
        });
        const playerId = computed(() => {
            return props.playerId;
        });
        const quantityOwned = computed(() => {
            return ownedCards.value.filter(
                (card) => card.player === mastercard.value.player
                    && card.rarity_name === mastercard.value.rarity_name
                    && card.season === mastercard.value.season
                    && card.for_sale === false
                    && card.active_auction_id === null
                    && card.team_id === teamId.value
            ).length;
        });
        function defineRarity() {
            filterStore.selectedRarities.push(mastercard.value.rarity_name);
            console.log(mastercard.value.rarity_name);
        }
        return {
            customClass,
            defineRarity,
            inCollection,
            playerId,
            mastercard,
            quantityOwned,
        };
    },
});
</script>