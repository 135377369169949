import inboxView from './views/inboxView.vue';

const routes = [
    {
        path: '/notification/inbox',
        component: inboxView,
        name: 'inbox',
        meta: {
            requiresAuth: true,
        },
    },
];

export default routes;