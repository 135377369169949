<template>
	<div class="card-template"
		:class="auction && auction.rarity_name ? auction.rarity_name.toLowerCase().replace(' ', '-') + '-bg' : ''">
		<router-link class="text-decoration-none" :to="'/auction/' + auction.id">
			<div class="card-img-ctn"
				:class="auction && auction.rarity_name ? auction.rarity_name.toLowerCase().replace(' ', '-') + '-imgbg' : ''">
				<img class="card-img-top" :src="getPlayerImageUrl(auction.card)" alt="Card image cap">
			</div>
			<div class="card-body">
				<h5 class="p-name">{{ auction.card.pname }}</h5>
				<span class="p-index">
					{{ auction.card.index }} / {{ auction.card.total_index }}
				</span>
				<span class="p-rarity">
					{{ auction.card.rarity_name }}
				</span>
				<span class="p-season">
					{{ auction.card.season }}
				</span>
				<span class="p-position">
					{{ auction.card.position_name }}
				</span>
				<span class="p-age">
					AGE {{ auction.card.player_age }}
				</span>
				<span class="p-jersey">
					#{{ auction.card.jersey_number }}
				</span>

				<span class="flag-img">
					<img class="p-flag" :src="getFlagImageUrl(auction.card)" alt="">
				</span>
			</div>
		</router-link>
	</div>
	<div class="card-template-footer">
		<AuctionFooter :auction="auction" />
	</div>
</template>

<style scoped>
@import '../../../assets/css/auction.css';
</style>

<script setup>

import { ref, onMounted, computed } from 'vue';
import { useAccountStore } from '@/stores/account';
import AuctionFooter from './AuctionFooter.vue';
import { getPlayerImageUrl, getFlagImageUrl } from '@/utils/imagesGetter';

const props = defineProps({
	auction: Object,
});

const accountStore = useAccountStore();

const emit = defineEmits(['auctionEnded']);

const auction = computed(() => {
	return props.auction;
});
const countdownInSeconds = ref(0);
const updateCountdown = () => {
	const now = new Date().getTime();
	const distance = new Date(auction.value.end_time).getTime() - now;
	countdownInSeconds.value = Math.floor(distance / 1000);
	if (distance < 0) {
		countdownInSeconds.value = 0;
		emit('auctionEnded')
	}
};
onMounted(() => {
	setInterval(updateCountdown, 1000);
});
</script>
