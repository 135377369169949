<template>
    <div class="filter-title subrayada" role="button" data-bs-toggle="collapse" data-bs-target="#position-section"
        aria-expanded="false" @click="toggleIconRotation(1)">
        Position
        <i class="fa-solid fa-caret-up arrow" :class="{ 'fa-rotate-180': rotationValues }"></i>
    </div>

    <div class="collapse position-collapse show" id="position-section" style="">
        <label class="p-label checkbox-label">
            {{ $t('Goalkeeper') }}
            <input class="form-check-input bg-dark" type="checkbox" value="GK" v-model="selectedPositions" @change="">
        </label>
        <label class="p-label checkbox-label">
            {{ $t('Defender') }}
            <input class="form-check-input bg-dark" type="checkbox" value="DEF" v-model="selectedPositions" @change="">
        </label>
        <label class="p-label checkbox-label">
            {{ $t('Midfielder') }}
            <input class="form-check-input bg-dark" type="checkbox" value="MID" v-model="selectedPositions" @change="">
        </label>
        <label class="p-label checkbox-label">
            {{ $t('Forward')  }}
            <input class="form-check-input bg-dark" type="checkbox" value="FW" v-model="selectedPositions" @change="">
        </label>
        <br>
    </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useFilterStore } from '@/stores/filters';

// Stores --------------------------------------------------------------------
const filterStore = useFilterStore();

const selectedPositions = ref([])
const rotationValues = ref(false);
const statePositions = computed(() => {
    return filterStore.getPositions;
});

// Watch for changes in selectedPositions
watch([selectedPositions], () => {
    filterStore.setPositions(selectedPositions.value);
});

watch([statePositions], () => {
    selectedPositions.value = statePositions.value;
});

function toggleIconRotation() {
    rotationValues.value = !rotationValues.value;
}

onMounted(() => {
    selectedPositions.value = statePositions.value;
});

</script>