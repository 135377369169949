<template>
  <button @click="scrollToTop" class="scroll-top-button"><i class="fa-solid fa-arrow-up"></i></button>
</template>

<script setup>

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}

</script>

<style scoped>
.scroll-top-button {
  position: fixed;
  bottom: 20px;
  /* Adjust the distance from the bottom */
  right: 20px;
  /* Adjust the distance from the right */
  z-index: 999;
  /* Ensure the button is on top of other elements */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #008095;
  color: white;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  /* Optional: Add a shadow for a floating effect */
}

@media (max-width: 991px) {
  .scroll-top-button {
    bottom: 25px;
    right: 20px;
    padding: 5px 10px;
    border-radius: 25px;
  }
}
</style>