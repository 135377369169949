import settingsView from './views/settingsView.vue';

const routes = [
    {
        path: '/my-settings/',
        component: settingsView,
        name: 'settings',
        meta: {
            requiresAuth: true,
        },
    },
];

export default routes;