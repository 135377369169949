import PasswordResetView from './views/PasswordResetView.vue';
import PasswordResetRequestView from './views/PasswordResetRequestView.vue';
import PasswordResetSentView from './views/PasswordResetSentView.vue';
import RequestEmailVerificationView
from './views/RequestEmailVerificationView.vue';
import VerifyEmailView from './views/VerifyEmailView.vue';
import VerifyEmailSentView from './views/VerifyEmailSentView.vue';

const routes = [
  {
    path: '/account/password-reset-request',
    component: PasswordResetRequestView,
    name: 'PasswordResetRequest',
  },
  {
    path: '/account/password-reset/:token',
    component: PasswordResetView,
    name: 'PasswordReset',
  },
  {
    path: '/account/password-reset-email-sent',
    component: PasswordResetSentView,
    name: 'PasswordResetSent',
  },
  {
    path: '/account/request-email-verification',
    component: RequestEmailVerificationView,
    name: 'RequestEmailVerification',
  },
  {
    path: '/account/verification-email-sent',
    component: VerifyEmailSentView,
    name: 'VerifyEmailSent',
  },
  {
    path: '/api/v1/account/verify-email/:token',
    component: VerifyEmailView,
    name: 'VerifyEmail',
  },
];

export default routes;
