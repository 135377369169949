<template>
    <div v-if="latestWeeklyFeaturedTeam" class="featured-card-container">
        <div class="featured-title">
            <h2 class="feat-txt bold">Cartes Featured de la Semaine {{ latestWeeklyFeaturedTeam.week }}</h2>
            <h3 v-if="formattedCountdown" class="feat-txt subrayada">{{ formattedCountdown }}</h3>
        </div>
        <div v-if="featuredCards" class="feat-ctn">
            <div class="card-el" v-for="card in featuredCards">
                <router-link @click="defineRarity(card)" class="text-decoration-none" type="button"
                    :to="'/football/player/' + card.player_slug">
                    <MasterCardImage class="feat-card" :key="card.id" :mastercard="card" parentComponent="featured" />
                </router-link>
            </div>
        </div>
        <RouterLink v-if="!recentUserSubmission.featured_team" to="/submit-weekly-featured-cards"
            class="text-decoration-none sbmt-ctn">
            <button class="submit-btn bold btn-block">Savoir plus</button>
        </RouterLink>
        <div v-else class="congrats-ctn">
            <h4 class="feat-txt success bold">Felicitations!</h4>
            <h5 class="feat-txt success bold">Tu as soumis les cartes featured de la semaine {{ recentUserSubmission.week }}</h5>
        </div>
    </div>
</template>

<style scoped>
.card-el {
    flex: 0 0 auto;
    /* Maintain original size */
    width: 200px;
    /* Add margin between cards */
    margin: 0;
}

.feat-card {
    box-shadow: 0 0 20px 0 #81ffd9;
}

.sbmt-ctn {
    display: block;
    width: 100%;
    text-align: center;
}

.submit-btn {
    background-color: #0000009a;
    border-radius: 25px;
    border: 1px solid #0000009a;
    width: 50%;
    color: #ffffff;
    padding: 10px 10px;
    margin-top: 20px;
    text-align: center;
    text-transform: capitalize;
    text-decoration: none;
    font-family: 'Montserrat SemiBold', sans-serif;
    display: inline-block;
    font-size: 16px;
    transition: all 0.3s ease 0s;
}

.submit-btn:hover {
    background-color: #040404cd;
    border: 1px solid #040404;
    color: #81ffd9;
}

@media (max-width: 1000px) {
    .submit-btn {
        width: 60%;
        padding: 7px 10px;
    }

    .card-el {
        margin: 0 5px;
        flex: auto;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .feat-txt {
        font-size: 20px;
    }
}
</style>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useCardStore } from '@/stores/card';
import { useFilterStore } from '@/stores/filters';
import MasterCardImage from './MasterCardImage.vue';

const cardStore = useCardStore();
const filterStore = useFilterStore();
const latestWeeklyFeaturedTeam = computed(() => cardStore.getLatestWeeklyFeaturedTeam);
const featuredCards = computed(() => cardStore.getFeaturedCards);
const countdownInSeconds = ref(0);
const recentUserSubmission = computed(() => cardStore.getRecentUserSubmission);


async function getLatestWeeklyFeaturedTeam() {
    try {
        await cardStore.pullLatestWeeklyFeaturedTeam();
    } catch (error) {
        console.log(error);
    }
}

const updateCountdown = () => {
    const now = new Date().getTime();
    if (!latestWeeklyFeaturedTeam.value) {
        return;
    }
    const distance = new Date(latestWeeklyFeaturedTeam.value.end_date).getTime() - now;
    countdownInSeconds.value = Math.floor(distance / 1000);
    if (distance < 0) {
        countdownInSeconds.value = 0;
    }
};
const formattedCountdown = computed(() => {
    const days = Math.floor(countdownInSeconds.value / 86400);
    const hours = Math.floor((countdownInSeconds.value % 86400) / 3600);
    const minutes = Math.floor((countdownInSeconds.value % 3600) / 60);
    const seconds = countdownInSeconds.value % 60;

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
});

function defineRarity(card) {
    if (card.value) {
        filterStore.selectedRarities.push(card.value.rarity_name);
        console.log(card.value.rarity_name);
    }
}

onMounted(() => {
    getLatestWeeklyFeaturedTeam();
    setInterval(updateCountdown, 1000);
});
</script>