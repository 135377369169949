<template>
  <div class="modal fade" id="cardDetailsModal" tabindex="-1" aria-labelledby="cardDetailsModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <FullPageSpinner v-if="loading" :loading="loading" />
        <div v-else>
          <div class="card-modal-bg" v-if="playercard">
            <div class="modal-header">
              <h5 class="modal-title" id="saleCardDetailModalLabel">
                <div class="card-badge">
                  <span class="card-season">
                    {{ playercard.season }}
                  </span>
                  <span :class="badgeClass" class="r-badge">
                    {{ playercard.rarity_name }}
                  </span>
                  <span class="card-number">
                    {{ playercard.index }} / {{ playercard.total_index }}
                  </span>
                </div>
                <router-link class="text-decoration-none" type="button"
                  :to="'/football/player/' + playercard.player_slug">
                  <div class="card-name">
                    {{ playercard.pname }}
                  </div>
                </router-link>
                <div class="position-name">
                  {{ playercard.position_name }}
                </div>
              </h5>
              <button @click="clearSingleData" type="button" class="close-btn" data-bs-dismiss="modal" aria-label="Close">
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body">
              <div class="card-details-container">
                <div class="card-detail-el mx-auto">
                  <PlayerCardinRows :playercard="playercard" />
                </div>
                <section class="detail-section">
                  <!-- Collapsible Buy Button -->
                  <div class="owner-actions" v-if="isOwner">
                    <div class="owner-section" v-if="!hasActiveAuction && !playercard.is_locked">
                      <!-- Edit Card Section -->
                      <div class="d-flex">
                        <span class="warning-msg text-center">
                          <i class="fa fa-exclamation-triangle"></i>
                          Une fois que vous avez créé une vente ou une enchère, elle ne peut pas être
                          annulée. Veuillez vous assurer d'entrer les bonnes informations.
                        </span>
                      </div>
                      <div class="edit-btn-ctn">
                        <div class="card-edit-ctn">
                          <button class="save-btn" @click="toggleCreateSaleSection">
                            Mettre en vente
                          </button>
                          <Transition name="fade">
                            <div v-if="showCreateSaleSection">
                              <form class="edit-form" @submit.prevent="createSale">
                                <label for="priceInput">Prix de vente:</label>
                                <input class="small-form" v-model="formData.price" type="number" min="0" max="25000000"
                                  step="100" id="priceInput" required inputmode="numeric">
                                <label for="durationInput">Durée (en heures):</label>
                                <input class="small-form" v-model="formData.duration" type="number" min="0" max="240"
                                  step="1" id="durationInput" required inputmode="numeric">
                                <div class="center-btn">
                                  <button class="confirm-btn" type="submit">Confirmer</button>
                                </div>
                              </form>
                            </div>
                          </Transition>
                        </div>
                        <div class="card-edit-ctn">
                          <button class="save-btn" @click="toggleCreateAuctionSection">
                            Vendre aux enchères
                          </button>
                          <Transition name="fade">
                            <div v-if="showCreateAuctionSection">
                              <form class="edit-form" @submit.prevent="createAuction">
                                <label for="priceInput">Mise de départ:</label>
                                <input class="small-form" v-model="auctionData.starting_price" type="number" min="0"
                                  max="10000000" step="100" required inputmode="numeric" />
                                <label for="durationInput">Durée (en heures):</label>
                                <input class="small-form" v-model="auctionData.duration" type="number" min="0" step="1"
                                  max="240" required inputmode="numeric" />
                                <div class="center-btn">
                                  <button class="confirm-btn" type="submit">Confirmer</button>
                                </div>
                              </form>
                            </div>
                          </Transition>
                        </div>
                      </div>
                    </div>
                    <div class="owner-section" v-else>
                      <div class="in-market-ctn" v-if="auctionID">
                        <p class="notice-text">Ta Carte est en vente aux enchères et ne peut pas être modifiée</p>
                        <button @click="goToAuction" class="go-to-btn">
                          Aller à l'enchère
                        </button>
                      </div>
                      <div class="in-market-ctn" v-else-if="playercard.for_sale">
                        <p class="notice-text">Ta Carte est en vente et ne peut pas être modifiée</p>
                        <RouterLink to="/card-sales">
                          <button class="go-to-btn">
                            Aller sur le marché
                          </button>
                        </RouterLink>
                      </div>
                    </div>
                  </div>
                  <ul class="list-group d-flex justify-content-center mb-4">
                    <li class="list-group-item card-detail-bg">
                      <p class="regular small text-white-50 m-0">Propriétaire
                        <span class="text-white small regular float-right">{{ playercard.owner_name
                          ?
                          playercard.owner_name
                          : playercard.owner
                        }}</span>
                      </p>
                    </li>
                    <li class="list-group-item card-detail-bg">
                      <p class="regular small text-white-50 m-0">Equipe
                        <span class="text-white small regular float-right">{{ playercard.team_name
                        }}</span>
                      </p>
                    </li>
                    <li class="list-group-item card-detail-bg">
                      <p class="regular small text-white-50 m-0">Position
                        <span class="text-white small regular float-right">{{
                          playercard.position_name
                        }}</span>
                      </p>
                    </li>
                    <li class="list-group-item card-detail-bg">
                      <p class="regular small text-white-50 m-0">Nationalité
                        <span class="text-white small regular float-right">{{ playercard.nationality
                        }}</span>
                      </p>
                    </li>
                    <li class="list-group-item card-detail-bg">
                      <p class="regular small text-white-50 m-0">Statut
                        <span v-if="playercard.for_sale" class="text-warning small regular float-right">
                          En Vente
                        </span>
                        <span v-else-if="playercard.active_auction_id || playercard.is_in_auction"
                          class="text-warning small regular float-right">
                          Aux Enchères
                        </span>
                        <span v-else-if="playercard.is_locked" class="text-danger small regular float-right">
                          Verrouillée
                        </span>
                        <span v-else class="text-white small regular float-right">Libre</span>
                      </p>
                    </li>
                  </ul>
                </section>
                <div v-if="playercard.for_sale" class="text-center text-white">
                  <button class="btn btn-outline-light mt-3" @click="togglePurchaseCollapse" v-if="!isCardOwnedByCurrentUser &&
                    playercard.for_sale === true">
                    {{ formatNumber(playercard.price) }} <i class="fa fa-ils"></i></button>
                </div>
                <div class="modal-footer">
                  <button type="button" class="close-btn-lg" data-bs-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>




<script setup>
import PlayerCardinRows from '../components/PlayerCardinRows.vue';
import FullPageSpinner from '@/components/FullPageSpinner.vue';

import formatNumber from '@/utils/numberFormatter';
import { useCardStore } from '@/stores/card';
import { useAuctionStore } from '@/stores/auction';
import { useAccountStore } from '@/stores/account';
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';



// Stores
const accountStore = useAccountStore();
const auctionStore = useAuctionStore();
const props = defineProps({
  playercard: {
    type: Object,
    required: true,
  },
});

const cardStore = useCardStore();
const router = useRouter();
const isPurchaseCollapseOpen = ref(false); // Initially closed
// Reactive variables
const loading = ref(false);
const showCreateSaleSection = ref(false); // Initially hidden
const showCreateAuctionSection = ref(false); // Initially hidden
const formData = ref({
  price: '',
  duration: '',
  card_id: '',
});
const auctionData = ref({
  card_id: '',
  starting_price: '',
  duration: '',
});

// Computed properties


const auctionID = computed(() => {
  return cardStore.getSingleCardData.active_auction_id
});

const playercard = computed(() => {
  return props.playercard;
});
const isOwner = computed(() => {
  return playercard.value.owner === accountStore.authData.userId;
});
const hasActiveAuction = computed(() => {
  return playercard.value.is_in_auction || playercard.value.active_auction_id;
});
const togglePurchaseCollapse = () => {
  isPurchaseCollapseOpen.value = !isPurchaseCollapseOpen.value;
};
const handleBodyClick = () => {
  if (isPurchaseCollapseOpen) {
    isPurchaseCollapseOpen.value = false;
  }
};
const isCardOwnedByCurrentUser = computed(() => {
  return props.playercard.owner === accountStore.authData.userId;
});
const badgeClass = computed(() => {
  return {
    'normal-badge': playercard.value.rarity_name === 'Normal',
    'rare-badge': playercard.value.rarity_name === 'Rare',
    'limited-badge': playercard.value.rarity_name === 'Limited',
    'super-rare-badge': playercard.value.rarity_name === 'Super Rare',
    'unique-badge': playercard.value.rarity_name === 'Unique',
  };
});

// Methods
function goToAuction() {
  const myModal = document.getElementById('cardDetailsModal');
  myModal.setAttribute('aria-hidden', 'true');
  const closeButton = myModal.querySelector('.close-btn');
  if (closeButton) {
    closeButton.click();
  }
  const auctionID = playercard.value.active_auction_id;
  router.push({ name: 'AuctionDetail', params: { id: auctionID } });
}
async function purchaseCard() {
  try {
    loading.value = true;
    const response = await cardStore.purchaseCard(playercard.value.id);
    isPurchaseCollapseOpen.value = false;
    await getSingleCard(playercard.value);
    if (response.status === 200) {
      console.log(response.data.message);
      accountStore.recordSuccessMessages(response.data.message);
    }
  }
  catch (error) {
    console.log(error);
    accountStore.recordErrorMessages(error.data.message);
  } finally {
    loading.value = false;
  }
}
async function getSingleCard(card) {
  loading.value = true;
  try {
    await cardStore.pullSingleCard(card.slug);
  }
  catch (error) {
    console.log(error);
  }
  finally {
    loading.value = false;
  }
};

async function createSale() {
  loading.value = true;
  try {
    const id = playercard.value.id;
    formData.value.card_id = id;
    formData.value.price = parseInt(formData.value.price);
    formData.value.duration = parseInt(formData.value.duration);
    const response = await cardStore.createSale(formData.value);
    if (response.status === 201) {
      accountStore.recordSuccessMessages(response.data.message);
      // Update the card details in the store
      playercard.value.for_sale = true;
      playercard.value.price = formData.value.price;
      playercard.value.is_locked = true;
      loading.value = false;
    }
  } catch (error) {
    console.log(error);
    if (error.data.message) {
      accountStore.recordErrorMessages(error.data.message);
    } else if (error.data.non_field_errors) {
      accountStore.recordErrorMessages(error.data.non_field_errors[0]);
    }
    loading.value = false;
  }
  toggleCreateSaleSection();
};
async function createAuction() {
  loading.value = true;
  try {
    const id = playercard.value.id;
    const slug = playercard.value.slug;
    auctionData.value.card_id = id;
    auctionData.value.starting_price = parseInt(auctionData.value.starting_price);
    auctionData.value.duration = parseInt(auctionData.value.duration);
    const response = await auctionStore.createAuction(auctionData.value);
    if (response.status === 201) {
      // Update the card details in the store
      playercard.value.is_locked = true;
      playercard.value.is_in_auction = true;
      const singleCard = await cardStore.pullSingleCard(slug);
      playercard.value.active_auction_id = singleCard.data.data.active_auction_id;
      accountStore.recordSuccessMessages(response.data.message);
      loading.value = false;
    }
  } catch (error) {
    console.log(error);
    if (error.data.message) {
      accountStore.recordErrorMessages(error.data.message);
    } else if (error.data.non_field_errors) {
      accountStore.recordErrorMessages(error.data.non_field_errors[0]);
    }
    loading.value = false;
  }
  toggleCreateAuctionSection();
};
function toggleCreateSaleSection() {
  showCreateSaleSection.value = !showCreateSaleSection.value;
  if (showCreateAuctionSection.value) {
    showCreateAuctionSection.value = false;
  }
};
function toggleCreateAuctionSection() {
  showCreateAuctionSection.value = !showCreateAuctionSection.value;
  if (showCreateAuctionSection.value) {
    showCreateSaleSection.value = false;
  }
};
function stopPropagation(event) {
  event.stopPropagation();
}
function clearSingleData() {
  cardStore.clearSingleCardData();
  showCreateSaleSection.value = false;
  showCreateAuctionSection.value = false;
}

</script>


<style scoped>
@import '../../../assets/css/card.css';

::placeholder {
  color: #000000;
  font-size: 0.8rem;
}

.modal-header {
  margin: 0;
  padding: 0 10px;
  border: none;
}

.modal-footer {
  margin: 0;
  padding: 0 10px;
  border: none;
}

.owner-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

label {
  color: #ffffffb3;
  font-size: 0.9rem;
  font-family: 'Montserrat SemiBold', sans-serif;
}

.notice-text {
  color: #ffffffcc;
  font-size: 0.9rem;
  font-family: 'Inconsolata R', sans-serif;
  text-align: center;
}

.center-btn {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.edit-btn-ctn {
  display: flex;
  justify-content: space-evenly;
  margin: 10px 0;
  padding: 10px 0;
}

.small-form {
  width: 100%;
  min-width: 160px;
  margin-bottom: 10px;
  height: 40px;
  font-size: 2rem;
  background-color: #1f1f1f;
  font-family: 'Paytone One R', sans-serif;
  border: none;
  border-radius: 10px;
  color: #ffffff;
  text-align: center;
}

.go-to-btn {
  border-radius: 10px;
  color: #76EEEB;
  border: 1px solid #76EEEB;
  background-color: #131313;
  padding: 5px 10px;
  transition: all 0.4s ease-in-out;
}

.go-to-btn:hover {
  color: #131313;
  background-color: #76EEEB;
}

.go-to-btn:active {
  color: #131313;
  background-color: #76EEEB;
}

.in-market-ctn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.edit-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.float-right {
  float: right;
}

.card-edit-ctn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 170px;
}

.card-detail-bg {
  background-color: #131313;
  border: none;
}

@media (max-width: 991px) {

  .card-details-container {
    display: flex;
    flex-direction: column;
  }

  .save-btn {
    width: 90%;
    border-radius: 20px;
  }

  .modal-body {
    padding: 0;
    margin: 0;
  }

  .detail-section {
    margin: 0;
    width: 100%;
  }

  .small-form {
    width: 100%;
    min-width: 160px;
    margin-bottom: 10px;
    height: 40px;
    font-size: 1.75rem;
    background-color: #1f1f1f;
    border: none;
    border-radius: 10px;
    color: #ffffff;
    text-align: center;
  }

  .list-group-item {
    padding: 0.25rem 0.25rem;
  }
}
</style>