<template>
    <HelpBox />
    <div class="small-title">
        <h4>Cartes en Vente Directe <span class="sm-span" v-if="totalCards">({{ totalCards }} au total)</span></h4>
    </div>
    <input class="curved-input-dark" v-model="nameFilter" placeholder="Filtrer par joueur" />
    <FiltersLine />
    <FlashMessage />
    <div class="d-flex flex-column flex-lg-row ">
        <div class="card-filters-container">
            <CardFilters />
        </div>
        <FullPageSpinner v-if="loading" :loading="loading" />
        <div v-if="cardSalesList.length > 0" class="flexdirect">
            <TransitionGroup name="cardlist">
                <div class="card-el" v-for="sale in cardSalesList" :key="sale.id">
                    <SaleCardImage :saleCard="sale" />
                    <div class="card-template-footer">
                        <div role="button" data-bs-toggle="modal" data-bs-target="#saleCardDetailModal"
                            @click="setPropsCard(sale)">
                            <SalesCardFooter :saleCard="sale" @saleEnded="handleSaleEnded(sale.id)" />
                        </div>
                    </div>
                    <CardSaleDetail :saleCard="PropsSaleCard" @loading-updated="handleLoadingUpdate" />
                </div>
            </TransitionGroup>
        </div>
        <div v-else>
            <h3 class="text-center text-white">
                Aucune carte trouvée
            </h3>
        </div>
    <GoToTop />
    </div>
</template>



<script setup>
import CardFilters from '@/modules/filters/components/CardFilters.vue';
import CardSaleDetail from '../components/CardSaleDetail.vue';
import FiltersLine from '@/modules/filters/components/FiltersLine.vue';
import SalesCardFooter from '../components/SalesCardFooter.vue';
import SaleCardImage from '../components/SaleCardImage.vue';

import FullPageSpinner from '../../../components/FullPageSpinner.vue';
import FlashMessage from '../../../components/FlashMessage.vue';
import HelpBox from '@/components/HelpBox.vue';
import GoToTop from '@/components/GoToTop.vue';

import { debounce } from 'lodash';

import { useCardStore } from '@/stores/card';
import { useFilterStore } from '@/stores/filters';
import axios from 'axios';

import {
    ref,
    onBeforeUnmount,
    onMounted,
    computed,
    watch
}

    from 'vue';

// Stores
const cardStore = useCardStore();
const filterStore = useFilterStore();

// Reactive variables
const PropsSaleCard = computed(() => {
    return cardStore.getSingleSaleData;
});
const loading = ref(false);
const hiddenLoading = ref(false);
const nameFilter = ref('');

// Computed properties
const cardSalesList = computed(() => {
    return cardStore.getAllSalesData;
});



const nextPageLink = computed(() => {
    return cardStore.getNextPage;
});

const totalCards = computed(() => {
    return cardStore.getTotalCount;
});

const selectedPlayerName = computed(() => {
    return filterStore.getSelectedName;
});
const selectedPositions = computed(() => {
    return filterStore.getPositions;
});
const rarities = computed(() => {
    return filterStore.getRarities;
});

const debouncedGetSales = debounce(getSales, 1200);

// Methods
function setPropsCard(card) {
    cardStore.setSingleSaleData(card);
}

function handleLoadingUpdate(value) {
    loading.value = value;
    setTimeout(() => {
        loading.value = false;
    }, 1000);
}

function handleScroll() {
    const container = document.documentElement; // Use document.documentElement to reference the entire page

    const scrollPosition = window.scrollY + window.innerHeight;
    const totalHeight = container.scrollHeight;

    // Check if the user has reached the bottom (adjust the threshold as needed)
    if (scrollPosition >= totalHeight * 0.8) {
        loadMoreCards();
    }
}

function handleSaleEnded(saleId) {
    cardStore.removeSaleFromList(saleId);
    debouncedGetSales();
}

async function getSales() {
    loading.value = true;
    console.log(rarities.value);
    try {
        let response;

        if (selectedPlayerName.value !== null && rarities.value.length > 0 && selectedPositions.value.length > 0) {
            response = await cardStore.pullAllSales({
                rarity: rarities.value, player_name: selectedPlayerName.value, position: selectedPositions.value
            });
        }
        else if (selectedPlayerName.value !== null && rarities.value.length > 0) {
            response = await cardStore.pullAllSales({
                rarity: rarities.value, player_name: selectedPlayerName.value
            });
        }
        else if (selectedPlayerName.value !== null && selectedPositions.value.length > 0) {
            response = await cardStore.pullAllSales({
                player_name: selectedPlayerName.value, position: selectedPositions.value
            });
        }
        else if (rarities.value.length > 0 && selectedPositions.value.length > 0) {
            response = await cardStore.pullAllSales({
                rarity: rarities.value, position: selectedPositions.value
            });
        }
        else if (selectedPlayerName.value !== null) {
            response = await cardStore.pullAllSales({ player_name: selectedPlayerName.value });
        }
        else if (rarities.value.length > 0) {
            response = await cardStore.pullAllSales({ rarity: rarities.value });
        }
        else if (selectedPositions.value.length > 0) {
            response = await cardStore.pullAllSales({ position: selectedPositions.value });
        }
        else {
            response = await cardStore.pullAllSales();
        }
    } catch (error) {
        console.log(error);
    } finally {
        loading.value = false;
    }
}

async function loadMoreCards() {
    if (!hiddenLoading.value && nextPageLink.value !== null) {
        hiddenLoading.value = true;
        try {
            const response = await axios.get(nextPageLink.value);
            cardStore.addMoreSales(response.data.results); // Assuming there's a mutation to add more cards in your store

            if (response.data.next === null) {
                cardStore.clearNextPage();
            }

            cardStore.setNextPage(response.data.next);
        }

        catch (error) {
            console.log(error);
        } finally {
            hiddenLoading.value = false;
        }
    }
}

watch([rarities, selectedPositions, selectedPlayerName], () => {
    debouncedGetSales();
});

watch(nameFilter, () => {
    filterStore.setSelectedName(nameFilter.value);
    if (nameFilter.value === '') {
        filterStore.setSelectedName(null);
    }
});
watch(selectedPlayerName, () => {
    if (selectedPlayerName.value === null) {
        nameFilter.value = '';
    }
});

onMounted(() => {
    filterStore.clearFilters();
    window.addEventListener('scroll', handleScroll);
});
onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll);
});
</script>


<style scoped>
.load-btn-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.load-btn {
    background-color: #1e1e1e;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
}

.load-btn:hover {
    background-color: #fff;
    color: #1e1e1e;
}
</style>