<template>
  <div class="alert-container">
    <transition name="fade">
      <div type="button" @click="closeMessage" aria-label="Close">
      <div v-if="accountStore.errorMessages" class="floating-alert error" role="alert">
        {{ accountStore.errorMessages }}
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div type="button" @click="closeMessage" aria-label="Close">
      <div v-if="accountStore.successMessages" class="floating-alert success" role="alert">
        {{ accountStore.successMessages }}
        </div>
      </div>
    </transition>
  </div>
</template>


<script setup>
import { ref, computed, onMounted } from 'vue';
import { useAccountStore } from '@/stores/account';


const accountStore = useAccountStore();
const errorMessages = computed(() => accountStore.getErrorMessages)
const successMessages = computed(() => accountStore.getSuccessMessages)

const closeMessage = () => {
  accountStore.clearErrorMessages();
  accountStore.clearSuccessMessages();
};

onMounted(() => {
  setTimeout(() => {
    accountStore.clearErrorMessages();
    accountStore.clearSuccessMessages();
  }, 2000);
});

</script>

<style scoped>
.alert-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;
}

.floating-alert {
  position: relative;
  padding: 10px 20px;
  margin-bottom: 15px;
  border-radius: 5px;
  animation: slide-up 5s ease-out forwards;
  /* Adjust the duration as needed */
}

.btn-close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

/* Custom classes for error and success alerts */
.error {
  background-color: #f56a60;
  /* Red color for error */
  color: rgb(0, 0, 0);
}

.success {
  background-color: #4caf50;
  /* Green color for success */
  color: white;
}

/* Keyframe animation for slide-up effect */
@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}</style>