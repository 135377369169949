import {useRoute} from 'vue-router';
import {useAuctionStore} from '@/stores/auction';
import { decryptToken } from '@/shared/jwtHelper';


export function useWebSocket () {
  // WebSocket connection URL (replace with your server's WebSocket URL)
  const route = useRoute ();
  const auctionStore = useAuctionStore ();
  // Initialize WebSocket connection
  const encryptedToken = localStorage.getItem ('acxToken');
  const jwtToken = decryptToken (encryptedToken);

  const socket = new WebSocket (
    `wss://api.djolowindemo.com/ws/auction/${route.params.id}/?token=${jwtToken}`
  );

  // Define callback functions for handling WebSocket events
  socket.onopen = () => {
    socket.send (
      JSON.stringify ({
        type: 'authenticate',
        message: {
          token: jwtToken,
        },
      })
    );
    console.log ('WebSocket connection established.');
  };

  socket.onmessage = event => {
    const data = JSON.parse (event.data);
    console.log ('Received WebSocket message:', data);
    if (data.type === 'new_bid_message') {
      console.log ('Received WebSocket Bid message:', data);
      auctionStore.updateAuctionBid (data.message.amount);
      auctionStore.updateAuctionOnBid (data.message);
      auctionStore.getAllBids(data.message.auction);
      auctionStore.setAuctionMessage (
        `${data.message.highest_bidder_name} placed a bid of ${data.message.amount} DJOBA`
      );
    } else if (data.type === 'connected_users_list') {
      console.log ('data.message', data.connected_users);
      auctionStore.setConnectedUsers (data.connected_users);
    }
  };

  socket.onclose = event => {
    if (event.wasClean) {
      console.log (
        `WebSocket connection closed cleanly, code=${event.code}, reason=${event.reason}`
      );
    } else {
      console.error ('WebSocket connection abruptly closed');
    }
  };

  socket.onerror = error => {
    console.error ('WebSocket error:', error.message);
  };

  // Function to send a message over the WebSocket
  const sendWebSocketMessage = message => {
    if (socket.readyState === WebSocket.OPEN) {
      // Ensure the WebSocket connection is open before sending the message
      socket.send (JSON.stringify (message));
    } else {
      console.error ('WebSocket connection is not open.');
    }
  };

  const closeWebSocketConnection = () => {
    console.log ('WebSocket connection closed.');
    socket.close ();
  };

  return {
    sendWebSocketMessage,
    closeWebSocketConnection,
  };
}
