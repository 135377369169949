<template>
    <div class="mastercard-template" :class="[
        mastercard.rarity_name.toLowerCase().replace(' ', '-') + '-bg',
        customClass, parentComponent]">
        <div class="card-img-ctn" :class="mastercard.rarity_name.toLowerCase().replace(' ', '-') + '-imgbg'">
            <img class="card-img-top" :src=getPlayerImageUrl(mastercard) alt="Card image cap">
        </div>
        <div class="card-body">
            <h5 class="p-name">{{ mastercard.pname }}</h5>
            <div class="card-text text-center text-dark">
                <span class="p-rarity">
                    {{ mastercard.rarity_name }}
                </span>
                <span class="p-season">
                    {{ mastercard.season }}
                </span>
                <span class="p-position">
                    {{ mastercard.position_name }}
                </span>
                <span class="p-age">
                    AGE {{ mastercard.player_age }}
                </span>
                <span class="p-jersey">
                    #{{ mastercard.jersey_number }}
                </span>
                <span class="flag-img">
                    <img class="p-flag" :src=getFlagImageUrl(mastercard) alt="">
                </span>
            </div>
        </div>
    </div>
</template>

<style scoped>
.owned {
    filter: brightness(1.1) contrast(1.1);
    scale: 1;
}
</style>

<script setup>
import { computed } from 'vue';
import { getPlayerImageUrl, getFlagImageUrl } from '@/utils/imagesGetter';

const props = defineProps({
    mastercard: Object,
    customClass: String,
    parentComponent: String,
});

const mastercard = computed(() => {
    return props.mastercard;
});

</script>