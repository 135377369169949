import accountRoutes from '@/modules/account/routes.js';
import appCurrencyRoutes from '@/modules/app_currency/routes.js';
import auctionRoutes from '@/modules/auction/routes.js';
import cardRoutes from '@/modules/card/routes.js';
import footballRoutes from '@/modules/sports/football/routes.js';
import notificationRoutes from '@/modules/notification/routes.js';
import profileRoutes from '@/modules/profile/routes.js';
import rankingRoutes from '@/modules/ranking/routes.js';
import settingsRoutes from '@/modules/settings/routes.js';
import walletRoutes from '@/modules/wallet/routes.js';

import {createRouter, createWebHistory} from 'vue-router';
import {useAccountStore} from '@/stores/account';
import LayoutWithNavbar from '@/components/LayoutWithNavbar.vue';
import WelcomeView from '@/views/WelcomeView.vue';
import GameRules from '@/views/GameRules.vue';

const routes = [
  ...accountRoutes, // Views in accountRoutes won't use the layout
  {
    path: '/',
    redirect: '/home', // Redirect root path to /home
    component: LayoutWithNavbar, // Use the layout for all other views
    children: [
      ...appCurrencyRoutes,
      ...auctionRoutes,
      ...cardRoutes,
      ...footballRoutes,
      ...notificationRoutes,
      ...profileRoutes,
      ...rankingRoutes,
      ...settingsRoutes,
      ...walletRoutes,
    ],
  },
  {
    path: '/game-rules',
    name: 'GameRules',
    component: GameRules,
  },
  {
    path: '/home',
    name: 'Welcome',
    components: {
      default: WelcomeView,
    },
    meta: {
      requiresLoggedOut: true,
    },
  },
];

const router = createRouter ({
  history: createWebHistory (process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // If there's a saved position (e.g., navigating back in history),
    // restore it; otherwise, scroll to the top of the page.
    if (savedPosition) {
      return savedPosition;
    } else {
      return {top: 0};
    }
  },
});

router.beforeEach (async (to, from, next) => {
  const accountStore = useAccountStore ();
  const isWelcomeRoute = to.name === 'Welcome';
  const isAccountRoute = to.matched.some(record => accountRoutes.includes(record.path));
  const existingBackdrops = document.getElementsByClassName ('modal-backdrop');

  // Remove any existing backdrops from the DOM
  if (existingBackdrops.length > 0) {
    document.body.removeChild (existingBackdrops[0]);
  }
  document.body.style.paddingRight = '0'; // Reset the padding on the body element
  document.body.classList.remove ('modal-open'); // Reset the class on the body element
  document.body.style.overflow = 'auto'; // Reset the overflow property on the body element
  // Exclude running onStoreInit on the Welcome route or account routes
  if (!isWelcomeRoute && !isAccountRoute) {
    accountStore.onStoreInit(); // Run onStoreInit on all routes except "Welcome"
  }
  // Check if the route requires authentication
  if (to.matched.some (record => record.meta.requiresAuth)) {
    // If the route requires authentication and the user is not logged in,
    if (!accountStore.isTokenActive) {
      // Check if the token is no longer active, send a refresh request
      try {
        console.log ('before', accountStore.authData);
        await accountStore.refreshToken (); // Ensure refreshToken returns a promise
        console.log ('after', accountStore.authData);
        if (!accountStore.isTokenActive) {
          // Token is still not active after refresh
          next ({name: 'Welcome'}); // Allow navigation to the "Welcome" route
        } else {
          next (); // Token is active; continue to the desired route
        }
      } catch (error) {
        console.error ('Error in refresh action:', error);
        // Handle errors if necessary
        next (); // Call next here to ensure the navigation continues even on error
      }
    } else {
      next (); // Token is already active; continue to the desired route
    }
  } else if (to.matched.some (record => record.meta.requiresLoggedOut)) {
    if (accountStore.isTokenActive) {
      next ({name: 'ProfileOverview'}); // Redirect authenticated users to the "Profile" route
    } else {
      next (); // Allow navigation for logged-out users
    }
  } else {
    next (); // For routes with no access control requirements
  }
});


export default router;
