<template>
    <HelpBox />
    <LoadingSpinner v-if="loading" :loading="loading" />
    <div v-else>
        <div class="container" v-if="auctionStore.ownedAuctionList.length > 0">
            <!-- Card List Section -->
            <div class="auction-list">
                <div v-if="activeAuctions" class="d-flex flex-wrap flex-row justify-content-center">
                    <div class="card-el mb-4" v-for="auction in activeAuctions" :key="auction.id">
                        <AuctionInRows :auction="auction" @AuctionEnded="clear(auction.id)"/>
                    </div>
                </div>
                <div v-else>
                    <p>Aucune enchère trouvée</p>
                </div>
            </div>
            <hr class="text-white mt-5" />
            <!-- Collapsible Menu for Ended Auctions -->
            <button @click="toggleEndedAuctions" class="btn btn-outline-light mb-3" v-if="endedAuctions.length > 0">
                {{ endedAuctionsVisible ? 'Cacher les Encheres passees' : 'Afficher les Encheres passees' }} ({{ endedAuctions.length }})
            </button>

            <transition name="fade">
                <div v-if="endedAuctionsVisible">
                    <div class="auction-list">
                        <div class="row" v-if="endedAuctions">
                            <h4 class="text-white-50 mb-4 bold">Ventes aux Enchères passées ({{ endedAuctions.length }} ventes au total)</h4>
                            <AuctionAsList v-for="auction in endedAuctions" :auction="auction" />
                        </div>
                        <div v-else>
                            <p>Aucune enchère trouvée</p>
                        </div>
                        <button @click="loadMoreAuctions" v-if="nextPageLink">Voir plus</button>
                    </div>
                </div>
            </transition>
        </div>
        <div v-else class="d-flex flex-column align-items-center justify-content-center h-100">
            <h1 class="text-white-50">
                Tu n'as pas encore de vente aux enchères
            </h1>
            <h4 class="text-white-50">
                Tu peux créer une vente aux enchères depuis la page de tes cartes
            </h4>
        </div>
    </div>
</template>




<script setup>
import { onMounted, ref, defineComponent, computed } from 'vue';
import { useAuctionStore } from '@/stores/auction';

import SearchBar from '@/components/SearchBar.vue';
import AuctionAsList from '../components/AuctionAsList.vue';
import AuctionInRows from '../components/AuctionInRows.vue';
import LoadingSpinner from '../../../components/LoadingSpinner.vue';
import { useAccountStore } from '@/stores/account';
import HelpBox from '@/components/HelpBox.vue';

import axios from 'axios';


const auctionStore = useAuctionStore();
const accountStore = useAccountStore();
const loading = ref(false);
const endedAuctionsVisible = ref(false);

const nextPageLink = computed(() => {
    return auctionStore.getNextPage;
});
const endedAuctions = computed(() => {
    if (auctionStore.ownedAuctionList) {
        return auctionStore.ownedAuctionList.filter(auction => {
            // Assuming you have an 'seller_id' field to check ownership
            const isOwnedByUser = auction.seller === accountStore.authData.userId;
            // Assuming you have an 'auction_processed' field to check if the auction has ended
            const hasEnded = auction.auction_processed;

            return isOwnedByUser && hasEnded;
        });
    }
});
const activeAuctions = computed(() => {
    if (!auctionStore.ownedAuctionList) {
        return [];
    }
    return auctionStore.ownedAuctionList.filter(auction => {
        // Assuming you have an 'seller_id' field to check ownership
        const isOwnedByUser = auction.seller === accountStore.authData.userId;
        // Assuming you have an 'auction_complete' field to check if the auction has ended
        const hasEnded = auction.end_time < new Date().toISOString();
        // Note the "!hasEnded" here to mark it as active
        return isOwnedByUser && !hasEnded;
    });
});


onMounted(() => {
    getOwnedAuctions();
});

function toggleEndedAuctions() {
    endedAuctionsVisible.value = !endedAuctionsVisible.value;
}

function clear(auctionId) {
    auctionStore.removeAuctionFromList(auctionId);
}
async function getOwnedAuctions() {
    loading.value = true;
    try {
        await auctionStore.getOwnedAuctions();
    } catch (error) {
        console.log(error);
    } finally {
        loading.value = false;
    }
}
async function loadMoreAuctions() {
    if (!loading.value && nextPageLink.value) {
        console.log(nextPageLink.value)
        loading.value = true;
        try {
            const response = await axios.get(nextPageLink.value);
            if (response) {
                auctionStore.ownedAuctionList = [...auctionStore.ownedAuctionList, ...response.data.results];
                auctionStore.nextPage = response.data.next;
                auctionStore.totalCount = response.data.count;
                loading.value = false;
            }
        } catch (error) {
            console.log(error);
        } finally {
            loading.value = false;
        }
    }
};

</script>