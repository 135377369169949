import instance from '@/baseAPI';

const auctionApi = {
  pullAllAuctions: filters =>
    instance.get (
      'auction/all',
      {params: filters},
      {paramsSerializer: {indexes: null}}
    ),
  getSingleAuction: id => instance.get (`auction/${id}`),
  createAuction: data => instance.post ('auction/create/', data),
  pullOwnedAuctions: () => instance.get ('auction/owned'),
  placeBid: data => instance.post ('auction/bid/', data),
  getAllBids: auction_id => instance.get (`auction/bids/${auction_id}`),
};

export default auctionApi;
