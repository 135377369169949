<template>
    <div v-if="auction">
        <div class="card-footer">
            <div v-if="isMobileView">
                <!-- Additional fields you want to display on mobile -->
                <!-- For example -->
                <div v-if="auction.card" class="additional-field">
                    <div class="card-name">
                        {{ auction.card.pname }}
                    </div>
                    <div class="card-badge">
                        <span class="card-season">
                            {{ auction.card.season }}
                        </span>
                        <span :class="badgeClass" class="r-badge">
                            {{ auction.card.rarity_name }}
                        </span>
                        <span class="card-number">
                            {{ auction.card.index }} / {{ auction.card.total_index }}
                        </span>
                    </div>
                </div>
                <!-- Add more fields as needed -->
            </div>
            <div v-if="auction.current_bid" class="bid-amt">
                {{ formatNumber(auction.current_bid) }} <i class="fa fa-ils"></i> &middot;
                <span v-if="auction.number_of_bids" class="bids-count">
                    {{ auction.number_of_bids }} offres
                </span>
            </div>
            <div v-else class="bid-amt">
                {{ formatNumber(auction.starting_price) }} <i class="fa fa-ils"></i> &middot;
                <span class="small bids-count">
                    0 offre
                </span>
            </div>
            <div class="countdown" v-if="countdownInSeconds">
                <span :class="{ 'text-danger': countdownInSeconds < 300 }">
                    {{ formattedCountdown }} <i class="fas fa-clock"></i>
                </span>
            </div>
            <div v-if="auction.seller_name" class="seller-name">
                Vendeur: {{ auction.seller_name }}
            </div>
            <div v-else class="seller-djolowin">
                Vendeur: DjoloWin
            </div>
            <div v-if="auction.highest_bidder_name" class="highest-bidder">
                <div v-if="auction.highest_bidder_name === userName">
                    Tu es le plus offrant
                </div>
                <div v-else>
                    Gagnant actuel:
                    {{ auction.highest_bidder_name }}
                </div>
            </div>
            <router-link class="text-decoration-none" :to="'/auction/' + auction.id">
                <div v-if="isUserAllowedToBid">
                    <button class="pill-btn-bid" @click="toggleBidCollapse">
                    Enchérir
                    </button>
                </div>
                <div v-else-if="userID === auction.seller">
                    <button class="pill-btn-nobid">
                        Ma vente aux enchères
                    </button>
                </div>
                <div v-else-if="userID === auction.highest_bidder">
                    <button class="pill-btn-nobid">
                        Voir les détails
                    </button>
                </div>
            </router-link>
        </div>
    </div>
</template>

<style scoped>
@import '../../../assets/css/auction.css';

.seller-name {
    color: #7e7e7ead;
    font-family: 'Montserrat R', sans-serif;
    font-size: 14px;
}

.pill-btn-bid {
    width: 100%;
}

.pill-btn-nobid {
    width: 100%;
}

.seller-djolowin {
    color: #7e7e7ead;
    font-family: 'Montserrat R', sans-serif;
    font-size: 14px;
}

.bid-amt {
    color: #fff;
    font-weight: bold;
    font-family: 'Montserrat SemiBold', sans-serif;
}

.highest-bidder {
    color: #40E0D0;
    font-family: 'Montserrat R', sans-serif;
    font-size: 14px;
}

.bids-count {
    color: #ffffffac;
    font-weight: 300;
    font-family: 'Montserrat R', sans-serif;
}
</style>

<script setup>
import { computed, ref, onMounted } from 'vue';
import formatNumber from '@/utils/numberFormatter';
import { useAuctionStore } from '@/stores/auction';
import { useAccountStore } from '@/stores/account';

const auctionStore = useAuctionStore();
const props = defineProps({
    auction: {
        type: Object,
        required: true,
    },
});
const accountStore = useAccountStore();
const userID = accountStore.authData.userId;

const userName = computed(() => {
    return accountStore.authData.userName;
});

const badgeClass = computed(() => {
    return {
        'normal-badge': auction.value.card.rarity_name === 'Normal',
        'rare-badge': auction.value.card.rarity_name === 'Rare',
        'limited-badge': auction.value.card.rarity_name === 'Limited',
        'super-rare-badge': auction.value.card.rarity_name === 'Super Rare',
        'unique-badge': auction.value.card.rarity_name === 'Unique',
    };
});

const isUserAllowedToBid = computed(() => {
    return auction.value.seller !== userID && auction.value.highest_bidder !== userID;
});

const auction = computed(() => {
    return props.auction;
});
const isMobileView = ref(false);

const checkMobileView = () => {
    isMobileView.value = window.innerWidth <= 768; // Adjust breakpoint as needed
};

const countdownInSeconds = ref(0);
const updateCountdown = () => {
    const now = new Date().getTime();
    const distance = new Date(auction.value.end_time).getTime() - now;
    countdownInSeconds.value = Math.floor(distance / 1000);
    if (distance < 0) {
        countdownInSeconds.value = 0;
    }
};
const formattedCountdown = computed(() => {
    const days = Math.floor(countdownInSeconds.value / 86400);
    const hours = Math.floor((countdownInSeconds.value % 86400) / 3600);
    const minutes = Math.floor((countdownInSeconds.value % 3600) / 60);
    const seconds = countdownInSeconds.value % 60;

    if (days > 0) {
        return `${days}d ${hours}h restantes`;
    } else if (hours > 0) {
        return `${hours}h restantes`;
    } else {
        return `${minutes}m ${seconds}s restantes`;
    }
});

onMounted(() => {
    setInterval(updateCountdown, 1000);
    checkMobileView(); // Check on initial load
    window.addEventListener('resize', checkMobileView); // Listen for resize events
});

</script>