import {useAccountStore} from './stores/account';
import {decryptToken} from './shared/jwtHelper';
import axios from 'axios';
import router from './router';
const APIUrl = process.env.VUE_APP_API_BASE_URL + '/api/v1';

let isRefreshing = false;
const instance = axios.create ({
  baseURL: APIUrl, // Replace with your backend URL
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Accept-Language': 'fr',
  },
  paramsSerializer: {indexes: null},
});

// Add authentication token to requests
instance.interceptors.request.use (
  config => {
    const encryptedToken = localStorage.getItem ('acxToken');
    if (encryptedToken) {
      const token = decryptToken (encryptedToken);
      config.headers.Authorization = `JWT ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject (error);
  }
);

// Add a response interceptor that refreshes the token if it expired
instance.interceptors.response.use (
  response => {
    return response;
  },
  async function (error) {
    const accountStore = useAccountStore ();
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        return new Promise (async (resolve, reject) => {
          isRefreshing = true;
          let access_token; // Declare access_token variable here
          try {
            const response = await accountStore.refreshToken ();
            if (response && response.status === 200) {
              access_token = response.data.access_token;
              accountStore.updateAccessToken (access_token);
              originalRequest.headers['Authorization'] = 'JWT ' + access_token;
              resolve (instance (originalRequest));
            } else {
              console.log ('Refresh token expired');
            }
          } catch (error) {
            localStorage.removeItem ('acxToken');
            localStorage.removeItem ('refxToken');
            accountStore.deleteAuthData ();
            router.push ({name: 'Welcome'});
          } finally {
            isRefreshing = false;
          }
        });
      }
    }
    return Promise.reject (error.response);
  }
);

export default instance;
