<template>
    <button class="goback regular" @click="goBack">
        <i class="fa-solid fa-arrow-left">
        </i>
        {{ $t('Back') }}
    </button>
</template>

<style scoped>
.goback {
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1.2rem;
    border: 0;
    color: #fff;
    background-color: #040404;
}

.goback:hover {
    background-color: #2d2d2d;
}

.goback:active {
    background-color: #1b1b1b;
}
</style>

<script setup>
import { useRouter } from 'vue-router';

const router = useRouter();
const goBack = () => {
    router.go(-1);
};

</script>