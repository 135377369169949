import instance from '@/baseAPI';

const accountApi = {
  login: data => instance.post ('account/login/', data),
  loginStreak: () => instance.get ('account/login-streak/'),
  checkin: () => instance.post ('account/login-streak/'),
  logout: data => instance.post ('account/logout/', data),
  signup: data => instance.post ('account/signup/', data),
  home: () => instance.get ('account/home'),
  obtainToken: data => instance.post ('account/obtain-token/', data),
  requestVerificationEmail: data =>
    instance.post ('account/request-email-verification/', data),
  passwordResetRequest: data =>
    instance.post ('account/password-reset-request/', data),
  passwordReset: (token, data) =>
    instance.post (`account/password-reset/${token}/`, data),
  verifyEmail: token => instance.get (`account/verify-email/${token}/`),
  googleLogin: data =>
    instance.post ('account/api/google-oauth/', data, {withCredentials: true}),
};

export default accountApi;
