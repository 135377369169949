<template>
    <div v-if="saleCard">
        <div class="card-footer">
            <CardDetailsMobile :card="saleCard.card" />
            <div class="sale-price">
                {{ formatNumber(saleCard.price) }} <i class="fa fa-ils"></i>
            </div>
            <div class="countdown" v-if="countdownInSeconds">
                <span :class="{ 'text-danger': countdownInSeconds < 300 }">
                    {{ formattedCountdown }} <i class="fas fa-clock"></i>
                </span>
            </div>
            <div v-if="saleCard.seller_name" class="seller-name-self">
                Vendeur: {{ saleCard.seller_name }} (Moi)
            </div>
            <div v-else class="seller-djolowin">
                Vendeur: DjoloWin
            </div>
            <button v-if="!belongsToUser" class="pill-btn-buy">
                Acheter
            </button>
            <div v-else>
                <button class="btn-details">
                    Voir les détails
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped>
@import '../../../assets/css/card.css';

.seller-name-self {
    font-size: 13px;
    color: #008095;
}

.seller-djolowin {
    font-size: 13px;
    margin-left: 5px;
    color: #1eceab;
}

.sale-price {
    font-size: 1.25rem;
    font-weight: bold;
    color: #fff;
    margin: 0;
}

.countdown {
    font-size: 12px;
    color: #b2b2b2;
    font-weight: 300;
    font-family: 'Montserrat R', sans-serif;
    margin-top: 0px;
}
</style>
<script setup>
import { onMounted, ref, computed } from 'vue';
import { useAccountStore } from '@/stores/account';
import formatNumber from '@/utils/numberFormatter';
import CardDetailsMobile from './CardDetailsMobile.vue';

const accountStore = useAccountStore();

const userId = computed(() => {
    return accountStore.authData.userId;
});

const belongsToUser = computed(() => {
    return userId.value === props.saleCard.seller;
});

const props = defineProps({
    saleCard: Object,
});
const emit = defineEmits();

const saleCard = computed(() => {
    return props.saleCard;
});

const countdownInSeconds = ref(0);
const updateCountdown = () => {
    const now = new Date().getTime();
    const distance = new Date(props.saleCard.end_time).getTime() - now;
    countdownInSeconds.value = Math.floor(distance / 1000);
    if (distance < 0) {
        countdownInSeconds.value = 0;
        emit('saleEnded')
    }
};
const formattedCountdown = computed(() => {
    const days = Math.floor(countdownInSeconds.value / 86400);
    const hours = Math.floor((countdownInSeconds.value % 86400) / 3600);
    const minutes = Math.floor((countdownInSeconds.value % 3600) / 60);
    const seconds = countdownInSeconds.value % 60;

    if (days > 0) {
        return `${days}d ${hours}h left`;
    } else if (hours > 0) {
        return `${hours}h left`;
    } else {
        return `${minutes}m ${seconds}s left`;
    }
});

const badgeClass = computed(() => {
    return {
        'normal-badge': saleCard.value.card.rarity_name === 'Normal',
        'rare-badge': saleCard.value.card.rarity_name === 'Rare',
        'limited-badge': saleCard.value.card.rarity_name === 'Limited',
        'super-rare-badge': saleCard.value.card.rarity_name === 'Super Rare',
        'unique-badge': saleCard.value.card.rarity_name === 'Unique',
    };
});

const isMobileView = ref(false);

const checkMobileView = () => {
    isMobileView.value = window.innerWidth <= 768; // Adjust breakpoint as needed
};

onMounted(() => {
    setInterval(updateCountdown, 1000);
    checkMobileView(); // Check on initial load
    window.addEventListener('resize', checkMobileView); // Listen for resize events
});

</script>
