import { defineStore } from "pinia";
import currencyApi from "@/modules/app_currency/api";

export const useAppCurrencyStore = defineStore("app_currency", {
    state: () => ({
        currencyPackagesList: [],
        currencyPackageData: '',
    }),

    actions: {
        setPackagesList(data) {
            this.currencyPackagesList = data;
        },

        async pullCurrencyList() {
            try {
                const response = await currencyApi.all();
                if (response && response.data && response.status === 200) {
                    this.setPackagesList(response.data);
                }
                return response;
            } catch (error) {
                console.error("Error in pullPackagesList action:", error);
            }
        },
        async purchaseCurrencyPackage(id) {
            try {
                const response = await currencyApi.purchase(id);
                if (response && response.data && response.status === 200) {
                    console.log("Success");
                }
                return response;
            } catch (error) {
                console.error("Error in purchaseCurrencyPackage action:", error);
            }
        }
    },
    getters: {
        getPackagesList() {
            return this.currencyPackagesList;
        }
    },
});
