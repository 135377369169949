<template>
    <div class="pagination-block">
        <button @click="goToFirstPage">&lt;&lt;</button>
        <button @click="goToPreviousPage">&lt;</button>
        <input class="bold"  v-model="this.currentPage" disabled min="1" :max="pages" />
        <span class="text-white bold" v-if="pages > 0">sur {{ pages }}</span>

        <button @click="goToNextPage">&gt;</button>
        <button @click="goToLastPage">&gt;&gt;</button>
    </div>
</template>

<style scoped>
.pagination-block {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.pagination-block button {
    margin: 0 0.5rem;
    width: 3rem;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.067);
    border: 1px solid rgba(255, 255, 255, 0.067);
    color: #fff;
}

.pagination-block button:hover {
    background-color: rgba(116, 173, 171, 0.067);
    border: 1px solid rgba(255, 255, 255, 0.067);
    color: #fff;
}

.pagination-block input {
    width: 3rem;
    margin-left: 1rem;
    margin-right: 0.5rem;
    border-radius: 10px;
    background-color: rgba(97, 248, 236, 0.272);
    padding: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.067);
    color: #fff;
    text-align: center;
}

.pagination-block span {
    margin-right: 1rem;
    color: #fff;
    padding: 0.5rem;
}
</style>

<script>
export default {
    props: {
        itemsPerPage: {
            type: Number,
            required: true,
        },
        currentPage: {
            type: Number,
            required: true,
        },
        pages: {
            type: Number,
            required: true,
        },
    },
    emits: ['accessPage'], // Declare the 'accessPage' event
    methods: {
        goToPage(page) {
            if (page > 0 && page <= this.pages) {
                this.$emit("accessPage", page);
            }
            this.pageInput = this.currentPage;
        },
        goToFirstPage() {
            this.goToPage(1);
        },
        goToLastPage() {
            this.goToPage(this.pages);
        },
        goToPreviousPage() {
            this.goToPage(this.currentPage - 1);
        },
        goToNextPage() {
            this.goToPage(this.currentPage + 1);
        },
        updatePageInput() {
            const integer = parseInt(this.pageInput);
            if (integer < 1) {
                this.pageInput = 1;
            } else if (integer > this.pages) {
                this.pageInput = this.pages;
            }
        },
    },
    setup(props, { emit }) {
        const accessPage = (page) => {
            emit('accessPage', page);
        }
        return {
            accessPage,
        }
    }
}
</script>

<!-- <template>
    <div class="pagination-block">
        <button @click="goToFirstPage">&lt;&lt;</button>
        <button @click="goToPreviousPage">&lt;</button>

        <input type="number" v-model="this.currentPage" disabled min="1" :max="pages" />

        <span v-if="pages > 0">of {{ pages }}</span>

        <button @click="goToNextPage">&gt;</button>
        <button @click="goToLastPage">&gt;&gt;</button>
    </div>
</template> -->
