import {createApp, h} from 'vue';
import { createPinia } from 'pinia';
import { createI18n } from 'vue-i18n'

import EN from './locales/en.json'
import FR from './locales/fr.json'
// import {ApolloClient, InMemoryCache} from '@apollo/client/core';
// import {createApolloProvider} from '@vue/apollo-option';
import App from './App.vue';
import router from './router';
import InstantSearch from 'vue-instantsearch/vue3/es';
import algoliasearch from 'algoliasearch/lite';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import vue3GoogleLogin from 'vue3-google-login'; // import the plugin here
import VueCountdown from '@chenfengyuan/vue-countdown';

import { useEventStore } from './stores/events';

const pinia = createPinia ();
const app = createApp ({
  render: () => h (App),
});


const i18n = createI18n({
  locale: 'FR',
  messages: {
    EN:EN,
    FR:FR,
  }
})

const CLIENT_ID = '895349954455-07j550leaklcui189sq2q1sid013cdtu.apps.googleusercontent.com';

const searchClient = algoliasearch(
  '4VD4GCDPRG',
  'c03eeda53f062bd99e91772550ff7b42'
);

app.component (VueCountdown.name, VueCountdown);
app.use (vue3GoogleLogin, {
  clientId: CLIENT_ID,
  scope: 'profile email',
});
app.use (router).use (pinia).use(i18n).use(InstantSearch).mount ('#app');

const eventStore = useEventStore (pinia);