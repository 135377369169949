<template>
    <div class="container inside-ctn mb-3">
        <div class="row">
            <div class="border-right">
                <div class="px-2 py-2">
                    <div class="d-flex ">
                        <h3 class="text-white text-wrap bold mb-4">Notification Preferences
                        </h3>
                    </div>
                    <p class="text-white-50 mb-1">
                        (Please note that some notifications are required for the proper functioning of the platform.)
                    </p>
                    <p class="text-warning mb-4 small">
                        Disabling notifications may affect your experience on the platform.
                    </p>
                    <div v-if="notificationPreferencesData">
                        <div class="row my-2" v-for="(value, key) in notificationPreferencesData" :key="key">
                            <div class="col">
                                <span class="text-wrap text-white">
                                    {{ fieldLabels[key] || key }}
                                </span>
                                <p class="text-white-50">
                                    {{ fieldDescriptions[key] }}
                                </p>
                            </div>
                            <div class="col-1 form-check form-switch">
                                <input class="form-check-input bg-info" :name="key" type="checkbox" :checked="value">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, defineComponent, computed, Transition } from 'vue';
import { useSettingsStore } from '@/stores/settings';

export default defineComponent({
    name: 'AccountSettings',
    components: {
        Transition
    },
    setup() {

        const settingsStore = useSettingsStore();
        const fieldLabels = ref({
            can_receive_auction_related_updates: 'Receive Auction Updates',
            can_receive_birthday_related_updates: 'Receive Birthday Updates',
            can_receive_card_related_updates: 'Receive Card Updates',
            can_receive_collection_related_updates: 'Receive Collection Updates',
            can_receive_community_related_updates: 'Receive Community Updates',
            can_receive_loyalty_related_updates: 'Receive Loyalty Updates',
            can_receive_marketing_related_updates: 'Receive Marketing Updates',
            can_receive_milestone_related_updates: 'Receive Milestone Updates',
            can_receive_news_related_updates: 'Receive News Updates',
            can_receive_payment_related_updates: 'Receive Payment Updates',
            can_receive_promotion_related_updates: 'Receive Promotion Updates',
            can_receive_referral_related_updates: 'Receive Referral Updates',
            can_receive_reward_related_updates: 'Receive Reward Updates',
            can_receive_user_engagement_related_updates: 'Receive User Engagement Updates',
        });
        const fieldDescriptions = ref({
            can_receive_auction_related_updates: 'This includes updates on auctions you are participating in, auctions you have won, and auctions you have lost.',
            can_receive_birthday_related_updates: 'This includes special birthday presents.',
            can_receive_card_related_updates: 'This includes updates on cards you have purchased, cards you have sold, and cards you have traded.',
            can_receive_collection_related_updates: 'This mainly includes updates on collections you have completed.',
            can_receive_community_related_updates: 'This includes updates on community events, community news, and community updates.',
            can_receive_loyalty_related_updates: 'This includes updates on your loyalty points.',
            can_receive_marketing_related_updates: 'This includes updates on marketing events, marketing news, and marketing updates.',
            can_receive_milestone_related_updates: 'This includes updates on milestones you have achieved.',
            can_receive_news_related_updates: 'This includes updates on events we are hosting or news about DjoloWin.',
            can_receive_payment_related_updates: 'This includes updates on payments you have made, payments you have received, and payments you have requested.',
            can_receive_promotion_related_updates: 'This includes updates on promotions we are running.',
            can_receive_referral_related_updates: 'This includes updates on referrals you have made and referrals you have received.',
            can_receive_reward_related_updates: 'This includes updates on rewards you have earned.',
            can_receive_user_engagement_related_updates: 'This includes updates on your engagement with the platform, such as login attempts, login successes, and login failures.',

        });

        onMounted(() => {
            pullNotificationPreferences();
        });

        const notificationPreferencesData = computed(() => {
            return settingsStore.getNotificationPreferencesData;
        });

        async function pullNotificationPreferences() {
            try {
                await settingsStore.pullNotificationPreferences();
            } catch (error) {
                console.log(error);
            }
        }
        return {
            notificationPreferencesData,
            fieldLabels,
            fieldDescriptions,
        }
    },
});
</script>
