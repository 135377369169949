<template>
	<div class="auction-template"
		:class="auction && auction.rarity_name ? auction.rarity_name.toLowerCase().replace(' ', '-') + '-bg' : ''">
		<div class="auction-img-ctn"
			:class="auction && auction.rarity_name ? auction.rarity_name.toLowerCase().replace(' ', '-') + '-imgbg' : ''">
			<img class="card-img-top" :src="getPlayerImageUrl(auction.card)" alt="Card image cap">
		</div>
		<!-- <div class="overlay"></div> -->
		<div class="card-body">
			<h5 class="a-name">{{ auction.card.pname }}</h5>
			<div class="card-text text-center text-dark">
				<span class="a-index">
					{{ auction.card.index }} / {{ auction.card.total_index }}
				</span>
				<span class="a-rarity">
					{{ auction.card.rarity_name }}
				</span>
				<span class="a-season">
					{{ auction.card.season }}
				</span>
				<span class="a-position">
					{{ auction.card.position_name }}
				</span>
				<span class="a-age">
					AGE {{ auction.card.player_age }}
				</span>
				<span class="a-jersey">
					#{{ auction.card.jersey_number }}
				</span>
				<span class="flag-img">
					<img class="a-flag" :src="getFlagImageUrl(auction.card)" alt="">
				</span>
			</div>
		</div>
	</div>
</template>

<style scoped>
@import '../../../assets/css/auction.css';

.a-name {
	display: flex;
	position: absolute;
	top: 0px;
	width: 100%;
	justify-content: center;
	text-align: center;
	padding: 0px 25px;
	font-size: 30px;
	font-family: "Bevan R", sans-serif;
	font-weight: 600;
	font-variant: small-caps;
	color: #ffffff;
}

.a-index {
	display: flex;
	position: absolute;
	justify-content: center;
	width: 100%;
	top: 105px;
	font-size: 20px;
	font-family: "Inconsolata R", sans-serif;
	font-weight: 900;
	color: #ffffff;
}

.a-rarity {
	display: flex;
	position: absolute;
	width: 100%;
	top: -290px;
	text-align: center;
	justify-content: center;
	font-size: 26px;
	font-family: "Inconsolata R", sans-serif;
	font-weight: 900;
	font-variant: small-caps;
	color: #ffffff;
}

.a-flag {
	position: absolute;
	top: 135px;
	width: 35px;
	right: 0px;
	height: 26px;
	border-radius: 10px;
}

.a-season {
	position: absolute;
	top: -290px;
	left: 0px;
	font-size: 26px;
	font-family: "Inconsolata R", sans-serif;
	font-weight: 900;
	color: #ffffff;
}

.a-favicon {
	position: absolute;
	top: 85px;
	left: 120px;
	width: 40px;
}

.a-position {
	display: flex;
	position: absolute;
	width: 100%;
	top: 130px;
	justify-content: center;
	font-size: 26px;
	font-family: "Inconsolata R", sans-serif;
	font-weight: 900;
	color: #ffffff;
}

.a-age {
	display: flex;
	position: absolute;
	width: 100%;
	top: 135px;
	font-size: 18px;
	font-family: "Inconsolata R", sans-serif;
	font-weight: 900;
	color: #ffffff;
}

.a-jersey {
	position: absolute;
	top: -290px;
	right: 6px;
	font-size: 26px;
	font-family: "Inconsolata R", sans-serif;
	font-weight: 900;
	color: #ffffff;
}

.auction-template {
	position: relative;
	width: 300px;
	height: 450px;
	border-radius: 15px;
	filter: brightness(1.1) contrast(1.1) saturate(1.1);
	overflow: hidden;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.auction-img-ctn {
	position: relative;
	width: 250px;
	height: 250px;
	overflow: hidden;
	margin: 0 auto;
	margin-top: 25px;
	/* Add a gradient background */
	border-radius: 25px;
	border: 3px solid #00000039;
}
</style>

<script setup>
import { computed } from 'vue';
import { getPlayerImageUrl, getFlagImageUrl } from '@/utils/imagesGetter';


const props = defineProps({
	auction: Object,
});
const auction = computed(() => {
	return props.auction;
});

</script>