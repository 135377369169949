<template>
    <HelpBox />
    <div class="d-flex flex-row align-items-center">
        <input class="curved-input-dark" v-model="nameFilter" placeholder="Filtrer par joueur" />
    </div>
    <div class="text-white regular px-2">
        <span class="sm-span" v-if="totalCards">({{ totalCards }} au total)</span>
        <span class="sm-span" v-else>(Aucun résultat)</span>
    </div>
    <FiltersLine />
    <FlashMessage />
    <div class="d-flex flex-column flex-lg-row ">
        <!-- Sidebar with filter parameters -->
        <CardFilters />
        <LoadingSpinner v-if="loading" :loading="loading" />
        <div v-else>
            <div v-if="displayedItems.length > 0" class="flexdirect">
                <TransitionGroup name="cardlist">
                    <div role="button" data-bs-toggle="modal" data-bs-target="#cardDetailsModal" @click="setPropsCard(card)"
                        class="card-el" v-for="card in displayedItems" :key="card.id">
                        <PlayerCardinRows :playercard="card" />
                        <div class="card-template-footer">
                            <PlayerCardFooter :playercard="card" />
                        </div>
                    </div>
                </TransitionGroup>
                <CardDetail :playercard="PropsCard" />
            </div>
            <div v-else>
                <h1 v-if="!loading" class="text-white">
                    Aucune carte trouvée
                </h1>
            </div>
        </div>
    </div>
    <Pagination v-if="displayedItems.length > 0" :currentPage="currentPage" :pages="pageCount" :itemsPerPage="itemsPerPage"
        @accessPage="handleGoToPage" />
</template>

<style scoped>
@import '../../../assets/css/card.css';
</style>


<script setup>
import CardFilters from '@/modules/filters/components/CardFilters.vue';
import FiltersLine from '@/modules/filters/components/FiltersLine.vue';
import PlayerCardinRows from './PlayerCardinRows.vue';
import PlayerCardFooter from './PlayerCardFooter.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import FlashMessage from '@/components/FlashMessage.vue';
import CardDetail from '../components/CardDetail.vue';
import Pagination from '@/components/Pagination.vue';
import HelpBox from '@/components/HelpBox.vue';


import { usePagination } from '@/utils/pagination';
import { useCardStore } from '@/stores/card';
import { useFilterStore } from '@/stores/filters';
import { ref, onMounted, computed, watch } from 'vue';



// Stores --------------------------------------------------------------------
const cardStore = useCardStore();
const filterStore = useFilterStore();

// Reactive variables --------------------------------------------------------
const origin = ref('ownedCards');
const loading = ref(false);
const nameFilter = ref('');
const itemsPerPage = ref(40);

// Computed properties -------------------------------------------------------
const PropsCard = computed(() => {
    return cardStore.getSingleCardData;
});

const selectedRarities = computed(() => {
    return filterStore.getRarities;
});
const selectedPlayerName = computed(() => {
    return filterStore.getSelectedName;
});
const selectedPositions = computed(() => {
    return filterStore.getPositions;
});
const selectedStatus = computed(() => {
    return filterStore.getStatus;
});

function statusMatch(card) {
    if (selectedStatus.value.length === 0) {
        return true;
    }
    if (selectedStatus.value.includes('auction') && card.active_auction_id || card.is_in_auction) {
        return true;
    }
    if (selectedStatus.value.includes('sale') && card.for_sale) {
        return true;
    }
    return false;
};

const cardList = computed(() => {
    return cardStore.getOwnedCardsData;
});
const totalCards = computed(() => {
    return filteredCards.value.length;
});
const filteredCards = computed(() => {
    currentPage.value = 1;
    const filter = nameFilter.value.toLowerCase();

    return cardList.value.filter(card => {
        const matchesName = card.pname.toLowerCase().includes(filter);
        const matchesRarity = selectedRarities.value.length === 0 || selectedRarities.value.includes(card.rarity_name);
        const matchesPosition = selectedPositions.value.length === 0 || selectedPositions.value.includes(card.position_name);
        const matchesStatus = statusMatch(card);

        return matchesName && matchesRarity && matchesPosition && matchesStatus;
    });
});

const {
    currentPage,
    pageCount,
    pages,
    displayedItems,
    goToPage,
} = usePagination(filteredCards, totalCards, itemsPerPage);




// Methods -------------------------------------------------------------------
function handleGoToPage(page) {
    currentPage.value = page;
}

function setPropsCard(card) {
    cardStore.setSingleCardData(card);
}

async function getUserCards() {
    loading.value = true;
    try {
        const response = await cardStore.pullOwnedCards();
    } catch (error) {
        console.log(error);
    } finally {
        loading.value = false;
    }
}

// Watchers ------------------------------------------------------------------
watch(nameFilter, () => {
    filterStore.setSelectedName(nameFilter.value);
    if (nameFilter.value === '') {
        filterStore.setSelectedName(null);
    }
});
watch(selectedPlayerName, () => {
    if (selectedPlayerName.value === null) {
        nameFilter.value = '';
    }
});


// Lifecycle hooks -----------------------------------------------------------
onMounted(() => {
    filterStore.clearRarities();
    filterStore.clearPositions();
    filterStore.clearSelectedName();
    getUserCards();
});

</script>
